import { useCallback, useMemo, useRef } from 'react'
import { Comlink } from '@blue-agency/comlink'
import { BffContainer } from '@/hooks/BffContainer'
import { useCachedPromise } from '@blue-agency/front-state-management'
import {
  CameraTestConnectionConfigProps,
  CameraTestConnectionProps,
  CameraTestOnConnectCallback,
  CameraTestOnFailureCallback,
} from '@blue-agency/react-environment-check'

export const useCameraTest = (
  type: 'interviewer' | 'interviewee',
  token: string
) => {
  const bff = BffContainer.useContainer()

  const createDemoInvitationRes = useCachedPromise(
    bff.cacheKey.createDemoInvitation,
    () => {
      return bff.createDemoInvitation()
    }
  )

  const soraProps = useMemo<CameraTestConnectionConfigProps>(
    () => ({
      sessionToken: createDemoInvitationRes.sessionToken,
      channelId: createDemoInvitationRes.channelId,
      webrtcHost: createDemoInvitationRes.webrtcHost,
    }),
    [createDemoInvitationRes]
  )

  const connectedRef = useRef(false)

  const onConnect = useCallback<CameraTestOnConnectCallback>(
    (result) => {
      Comlink.push({
        type: 'system_activity',
        group: 'snoke',
        action: 'succeeded_to_connect_to_sora_in_camera_test',
        targetName: `${type}Token`,
        targetIdStr: token,
        metadata: {
          channelId: soraProps.channelId,
          sessionToken: soraProps.sessionToken,
          protocol: result.protocol ?? '',
          sendonlyAttempt: result.sendonlyAttempt?.toString() ?? '',
          recvonlyAttempt: result.recvonlyAttempt?.toString() ?? '',
        },
      })
      connectedRef.current = true
    },
    [soraProps.channelId, soraProps.sessionToken, token, type]
  )

  const onFailure = useCallback<CameraTestOnFailureCallback>(
    (e) => {
      Comlink.push({
        type: 'system_activity',
        group: 'snoke',
        action: 'failed_to_connect_to_sora_in_camera_test',
        targetName: `${type}Token`,
        targetIdStr: token,
        metadata: {
          role: e.role ?? '',
          errorMessage: e.message ?? '',
          reason: e.reason ?? '',
          sessionToken: soraProps.sessionToken,
        },
      })
    },
    [soraProps.sessionToken, token, type]
  )

  const onLeave = useCallback(async () => {
    if (!connectedRef.current) return

    await bff.deleteDemoSessionToken(soraProps.sessionToken)
    Comlink.push({
      type: 'system_activity',
      group: 'snoke',
      action: 'delete_demo_invitation',
      targetName: `${type}Token`,
      targetIdStr: token,
      metadata: {
        sessionToken: soraProps.sessionToken,
      },
    })
  }, [bff, soraProps.sessionToken, token, type])

  const cameraTestConnectionProps: CameraTestConnectionProps = {
    sora: soraProps,
    onConnect,
    onFailure,
    onLeave,
  }

  return cameraTestConnectionProps
}
