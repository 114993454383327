import React from 'react'
import styled, { css } from 'styled-components'
import {
  theme,
  Icon as _Icon,
  TertiaryButton,
  ActiveTertiaryButton,
  Txt,
  Button,
  ClickOutsideListener,
} from '@blue-agency/rogue'
import { Dropdown as _Dropdown } from '../Dropdown'
import { InterviewControlsProps } from '..'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { RedCircle as _RedCircle } from '../RedCircle'
import { ReloadModal } from '@/components/ReloadModal'
import { UnstableConnectionModal } from '../UnstableConnectionModal'
import { ChangeDeviceModal } from '../ChangeDeviceModal'

type Props = InterviewControlsProps & {
  controlsHeight: number
}

export const Interviewee: React.FCX<Props> = ({
  className,
  windowSize,
  userType,
  muteButtonProps,
  cameraOffButtonProps,
  userButtonProps,
  chatButtonProps,
  menuProps,
  controlsHeight,
  changeDeviceProps,
}) => {
  const {
    showDropdown,
    handleAdditionalMenuIconClick,
    dropdownRef,
    handleOutsideClick,
    items,
    unstableConnectionModal,
    changeDeviceModal,
    reloadModal,
  } = menuProps

  return (
    <Wrapper
      className={className}
      width={windowSize.width}
      height={controlsHeight}
    >
      <ReloadButtonWrapper>
        <ReloadButton
          text="再読込"
          onClick={reloadModal.open}
          icon={<Icon name="reload" />}
          direction="column"
          disabled={userButtonProps.disabled}
          comlinkPushParams={{ action: 'click_reload_button_at_interview' }}
        />
      </ReloadButtonWrapper>
      <UserButtonWrapper>
        {userButtonProps.active ? (
          <ActiveUserButton
            text="参加者"
            onClick={userButtonProps.onClick}
            icon={<Icon name="presenter" />}
            direction="column"
            disabled={userButtonProps.disabled}
            comlinkPushParams={{ action: 'hide_interview_users_area' }}
          />
        ) : (
          <UserButton
            text="参加者"
            onClick={userButtonProps.onClick}
            icon={<Icon name="presenter" />}
            direction="column"
            disabled={userButtonProps.disabled}
            comlinkPushParams={{ action: 'show_interview_users_area' }}
          />
        )}
        {!!userButtonProps.count && (
          <UserButtonCount>
            <Txt size="xs" bold color={theme.color.white[1]}>
              {userButtonProps.count}
            </Txt>
          </UserButtonCount>
        )}
      </UserButtonWrapper>
      <ChatButtonWrapper>
        {chatButtonProps.active ? (
          <ActiveChatButton
            text="チャット"
            onClick={chatButtonProps.onClick}
            icon={<Icon name="chat" />}
            direction="column"
            disabled={chatButtonProps.disabled}
            comlinkPushParams={{ action: 'hide_interview_chat_area' }}
          />
        ) : (
          <ChatButton
            text="チャット"
            onClick={chatButtonProps.onClick}
            icon={<Icon name="chat" />}
            direction="column"
            disabled={chatButtonProps.disabled}
            comlinkPushParams={{ action: 'show_interview_chat_area' }}
          />
        )}
        {chatButtonProps.unread && <RedCircle />}
      </ChatButtonWrapper>
      {muteButtonProps.active ? (
        <ActiveMuteButton
          text="解除"
          onClick={muteButtonProps.onClick}
          icon={<Icon name="mute-mike" />}
          direction="column"
          disabled={muteButtonProps.disabled}
          comlinkPushParams={{ action: 'unmute_at_interview' }}
        />
      ) : (
        <MuteButton
          text="ミュート"
          onClick={muteButtonProps.onClick}
          icon={<Icon name="mike" />}
          direction="column"
          disabled={muteButtonProps.disabled}
          comlinkPushParams={{ action: 'mute_at_interview' }}
        />
      )}
      {cameraOffButtonProps.active ? (
        <ActiveMuteButton
          text="解除"
          onClick={cameraOffButtonProps.onClick}
          icon={<Icon name="movie-off" />}
          direction="column"
          disabled={cameraOffButtonProps.disabled}
          comlinkPushParams={{ action: 'camera_on_at_interview' }}
        />
      ) : (
        <MuteButton
          text="オフ"
          onClick={cameraOffButtonProps.onClick}
          icon={<Icon name="movie" />}
          direction="column"
          disabled={cameraOffButtonProps.disabled}
          comlinkPushParams={{ action: 'camera_off_at_interview' }}
        />
      )}
      <AdditionalMenuIconWrapper>
        <AdditionalMenuIconButton
          onClick={handleAdditionalMenuIconClick}
          comlinkPushParams={{ action: 'show_dropdown_on_interview_controls' }}
        >
          <Icon name="settings" />
        </AdditionalMenuIconButton>
        {showDropdown && (
          <ClickOutsideListener
            targetRef={dropdownRef}
            onClick={handleOutsideClick}
          >
            <Dropdown ref={dropdownRef} items={items} />
          </ClickOutsideListener>
        )}
        <UnstableConnectionModal
          active={unstableConnectionModal.active}
          onClose={unstableConnectionModal.close}
          userType={userType}
          isSp
        />
        <ReloadModal active={reloadModal.active} onCancel={reloadModal.close} />
        {changeDeviceProps && (
          <ChangeDeviceModal
            active={changeDeviceModal.active}
            onClose={changeDeviceModal.close}
            changeDeviceProps={changeDeviceProps}
          />
        )}
      </AdditionalMenuIconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background: ${theme.color.gray[5]};
  border-top: 1px solid ${theme.color.gray[3]};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
const iconSize = css`
  height: 22px;
  width: 21px;
`
const Icon = styled(_Icon)`
  ${iconSize}
`

const buttonBase = css`
  padding: 4.5px 0 0 !important;
  height: 44px;
  width: 48px;
  font-size: 9px !important;
`
const UserButtonWrapper = styled.div`
  position: relative;
`
const UserButtonCount = styled.div`
  ${UserButtonWrapper} & {
    position: absolute;
    top: -3px;
    right: -8px;
  }
  width: 20px;
  height: 20px;
  background: ${theme.color.navy[1]};
  border: 1px solid ${theme.color.white[1]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ActiveUserButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`
const UserButton = styled(TertiaryButton)`
  ${buttonBase}
`
const MuteButton = styled(TertiaryButton)`
  ${buttonBase}
`
const ActiveMuteButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`

const ChatButtonWrapper = styled.div`
  position: relative;
`
const ChatButton = styled(TertiaryButton)`
  ${buttonBase}
`
const ActiveChatButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`
const RedCircle = styled(_RedCircle)`
  ${ChatButtonWrapper} & {
    position: absolute;
    top: -3px;
    right: -4px;
  }
`
const AdditionalMenuIconWrapper = styled.div`
  position: relative;
`
const AdditionalMenuIconButton = styled(Button)`
  ${buttonResetStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
`
const Dropdown = styled(_Dropdown)`
  ${AdditionalMenuIconWrapper} & {
    position: absolute;
    bottom: 41px;
    right: -16px;
  }
`
const ReloadButtonWrapper = styled.div`
  position: relative;
`
const ReloadButton = styled(TertiaryButton)`
  ${buttonBase}
`
