import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { path } from '@/path'
import { useVerifyOrganization } from './useVerifyOrganization'
import { SettingsPage } from './SettingsPage'
import { List } from './TimeGuidePage/List'
import { Form } from './TimeGuidePage/Form'
import { NotFound } from '@/components/NotFound'

export const SettingRoutes = () => {
  const { isError } = useVerifyOrganization()
  return isError.value ? (
    <NotFound />
  ) : (
    <Switch>
      <Route
        exact
        path={path.organization.setting.index}
        component={SettingsPage}
      />
      <Route
        exact
        path={path.organization.setting.timeGuide.index}
        component={List}
      />
      <Route
        exact
        path={path.organization.setting.timeGuide.form}
        component={Form}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
