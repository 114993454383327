import { createContainer } from '@blue-agency/front-state-management'
import { useState, useCallback, useEffect, useRef } from 'react'

type Option = {
  duration?: number
  onClose?: OnCloseCallback
}

type OnCloseCallback = () => void

const defaultDuration = 4000

const useToast = () => {
  const [showToast, setShowToast] = useState(false)
  const [toastText, setToastText] = useState('')
  const [toastDuration, setToastDuration] = useState(defaultDuration)
  const onCloseRef = useRef<OnCloseCallback>()

  const toast = useCallback((text: string, option?: Option) => {
    setToastText(text)

    setToastDuration(option?.duration ?? defaultDuration)
    onCloseRef.current = option?.onClose

    setShowToast(true)
  }, [])

  const handleToastClose = useCallback(() => setShowToast(false), [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false)
    }, toastDuration)
    return () => clearTimeout(timer)
  }, [showToast, toastDuration])

  useEffect(() => {
    if (!showToast) {
      onCloseRef.current && onCloseRef.current()
    }
  }, [showToast])

  return {
    showToast,
    toastText,
    toastDuration,
    toast,
    handleToastClose,
  }
}

export const ToastContainer = createContainer(useToast)
