import { TimeGuideForm } from './RegisterContainer'

// TODO:テンプレートリストの詳細が決まったら修正
export const templateForm: {
  [key: string]: TimeGuideForm
} = {
  newGraduate: {
    name: '新卒採用',
    interviewTime: 30,
    parts: [
      {
        guide_time_minutes: 10,
        title: 'アイスブレイク',
        description: '面接とはあまり関係のないテーマで会話する',
      },
      {
        guide_time_minutes: 10,
        title: '自己紹介',
        description: '面接官自身の自己紹介',
      },
      {
        guide_time_minutes: 10,
        title: '会社の説明',
        description: '設立してからの沿革、主要な事業について、今後の展望など',
      },
    ],
  },
  midCareer: {
    name: '中途採用',
    interviewTime: 30,
    parts: [
      {
        guide_time_minutes: 10,
        title: 'アイスブレイク',
        description: '面接とはあまり関係のないテーマで会話する',
      },
      {
        guide_time_minutes: 10,
        title: '自己紹介',
        description: '面接官自身の自己紹介',
      },
      {
        guide_time_minutes: 10,
        title: '会社の説明',
        description: '設立してからの沿革、主要な事業について、今後の展望など',
      },
    ],
  },
}
