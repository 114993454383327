import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { AudioContextContainer } from '@/hooks/AudioContextContainer'
import { BrowserContainer } from '@/hooks/BrowserContainer'
import { BffContainer } from '@/hooks/BffContainer'
import {
  GetInterviewerInterviewResponse,
  GetInterviewerInterviewVideoQualityResponse,
} from '@blue-agency/proton/web/v2/snoke_bff/snoke_bff_service_pb'

const INTERVIEW_MAX_MINUTES = 180

type RoomStatus = 'finished' | 'environmentCheck' | 'waiting' | 'entered'

const useRoomPage = () => {
  const { interviewerToken } = useParams<{ interviewerToken: string }>()
  if (!interviewerToken) throw new Error('Not found interviewerToken')

  const { getAudioContext } = AudioContextContainer.useContainer()
  const { isSafari } = BrowserContainer.useContainer()
  useEffect(() => {
    // MEMO: audioContextインスタンス作成のタイミングはgetUserMediaを呼ぶ前が望ましい
    // という情報をみた気がするので、Roomに入ったタイミングでインスタンスを作成している

    // TODO: getUserMediaを呼んだ後でインスタンスを作成しても問題ないかを確認して、
    // 問題なければここでgetAudioContextを呼ぶのをやめる
    if (isSafari) getAudioContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bff = BffContainer.useContainer()
  const interview = useCachedPromise(bff.cacheKey.getInterviewerInterview, () =>
    bff.getInterviewerInterview(interviewerToken)
  )
  const quality = useCachedPromise(
    bff.cacheKey.getInterviewerInterviewVideoQuality,
    () => bff.getInterviewerInterviewVideoQuality(interviewerToken)
  )

  const sessionStorageKey = {
    environmentCheck: `environmentCheck-${interviewerToken}`,
  }

  const initialRoomStatus: RoomStatus = useMemo(() => {
    const { Status } = GetInterviewerInterviewResponse
    if (interview.status === Status.FINISHED) return 'finished'
    const isEnvironmentCheckFinished = !!sessionStorage.getItem(
      sessionStorageKey.environmentCheck
    )
    return isEnvironmentCheckFinished ? 'waiting' : 'environmentCheck'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [roomStatus, setRoomStatus] = useState<RoomStatus>(initialRoomStatus)

  // NOTE: 終了済の場合にquality == nullとなるが終了画面にいくので問題なしとする
  const isEconomyMode =
    quality &&
    quality.mode === GetInterviewerInterviewVideoQualityResponse.Mode.LOW

  const isStarted = useMemo(
    () => interview.startedAt !== undefined,
    [interview]
  )

  return {
    interview,
    interviewerToken,
    roomStatus,
    setRoomStatus,
    sessionStorageKey,
    quality,
    isEconomyMode,
    interviewMaxMinutes: INTERVIEW_MAX_MINUTES,
    isStarted,
  }
}

export const RoomPageContainer = createContainer(useRoomPage)
