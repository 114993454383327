import React, { useMemo, ComponentProps } from 'react'
import styled, { css } from 'styled-components'
import { Header as _Header, headerHeight } from '@/components/Header'
import { Footer, footerHeight } from '@/components/Footer'
import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'
import { theme } from '@blue-agency/rogue'

type Props = ComponentProps<typeof Header> & {
  showFooter?: boolean
  fixHeader?: boolean
  fixHeight?: boolean
  backgroundColor?: string
}

export const Layout: React.FCX<Props> = ({
  className,
  children,
  rightNode,
  fixedHeader = false,
  fixHeight = false,
  showFooter = false,
  backgroundColor,
}) => {
  const windowSize = WindowSizeContainer.useContainer()

  const height = useMemo(() => {
    if (showFooter) {
      return windowSize.height - headerHeight - footerHeight
    }
    return windowSize.height - headerHeight
  }, [showFooter, windowSize.height])

  return (
    <Wrapper className={className}>
      {fixedHeader ? (
        <FixedHeader rightNode={rightNode} />
      ) : (
        <Header rightNode={rightNode} />
      )}
      {fixHeight ? (
        <Content height={height} background={backgroundColor}>
          {children}
        </Content>
      ) : (
        <Content minHeight={height} background={backgroundColor}>
          {children}
        </Content>
      )}
      {showFooter && <Footer showCopyright />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
`
const Header = styled(_Header)`
  /* MEMO: HeaderがContentよりも上に来るようにスタックコンテキストを形成している */
  position: relative;
  z-index: 1;
`
const FixedHeader = styled(_Header)`
  position: fixed;
  top: 0;
  z-index: 1;
`
const Content = styled.div<{
  height?: number
  minHeight?: number
  background?: string
}>`
  position: relative;
  z-index: 0;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
  background: ${({ background }) => background || theme.color.gray[5]};
`
