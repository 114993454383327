import React from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { theme, PrimaryButton } from '@blue-agency/rogue'
import { path, fillParams } from '@/path'
import { PageHeader as _PageHeader } from '@/components/PageHeader'
import { SideNav } from '@/components/SideNav'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { Content as _Content } from './Content'
import { Footer } from '../Footer'
import { Layout } from '../Layout'

export const List: React.VFC = () => {
  const { organizationToken } = useParams<{ organizationToken?: string }>()
  if (!organizationToken) throw new Error('Not found organizationToken')
  return (
    <Layout>
      <Page>
        <PageHeader title="タイムガイド設定">
          <HeaderRight>
            <StyledLink
              to={fillParams({
                path: path.organization.setting.timeGuide.form,
                params: { organizationToken },
              })}
            >
              <PrimaryButton
                text="タイムガイド作成"
                size="l2"
                comlinkPushParams={{ action: 'open_timeguide_template_modal' }}
              />
            </StyledLink>
          </HeaderRight>
        </PageHeader>
        <PageSidebar>
          <SideNav />
        </PageSidebar>
        <PageContent>
          <Content />
        </PageContent>
        <Footer />
      </Page>
    </Layout>
  )
}

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const Content = styled(_Content)`
  width: 100%;
  height: 100%;
`
const Page = styled.div`
  display: grid;
  grid-template:
    'header  header ' 60px
    'sidebar content' 1fr
    'sidebar footer ' auto
    / auto 1fr;

  width: 100%;
  height: 100%;
`
const PageHeader = styled(_PageHeader)`
  grid-area: header;
`

const PageSidebar = styled.aside`
  grid-area: sidebar;
  overflow-y: hidden;
`
const PageContent = styled.main`
  grid-area: content;
  ${overflowYScrollStyle};
  background: ${theme.color.white[1]};
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
