import { useMemo } from 'react'
import { useUserAgent } from '@blue-agency/react-utils'
import { createContainer } from '@blue-agency/front-state-management'
import { WindowSize } from '@blue-agency/react-utils/dist/useWindowSize'

const useBrowser = () => {
  const ua = useUserAgent()

  const isInternetExplorer = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return (
      userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1
    )
  }, [])

  const isSafari = useMemo(
    () => ua.browser.name === 'Safari' || ua.browser.name === 'Mobile Safari',
    [ua]
  )

  const isIOS = useMemo(() => ua.os.name === 'iOS', [ua])

  const isPcByWindowWidth = (windowWidth: number) => 1024 < windowWidth

  const getDeviceTypeByWindowSize = ({ width, height }: WindowSize) => {
    if (isPcByWindowWidth(width)) return 'pc'
    if (width < height) return 'spPortrait'
    return 'spLandscape'
  }

  return {
    ua,
    isInternetExplorer,
    isSafari,
    isIOS,
    isPcByWindowWidth,
    getDeviceTypeByWindowSize,
  }
}

export const BrowserContainer = createContainer(useBrowser)
