import { Comlink } from '@blue-agency/comlink'
import { useCallback, useEffect, useState } from 'react'

// TODO(magurotuna): import だとだめだった このあたりよくわからず……
// https://stackoverflow.com/questions/59247861/how-to-import-a-sound-file-into-react-typescript-component
const testAudio = require('@/assets/speaker-test.mp3')
const audio = new Audio()
audio.src = testAudio

export function usePlayDemoSound(selectedSpeakerDeviceId: string | undefined): {
  playSound: () => void
  isPlaying: boolean
} {
  const [isPlaying, setIsPlaying] = useState(false)

  const playSound = useCallback(() => {
    setIsPlaying(true)
    audio.play()
  }, [])

  // デモ音声の再生が終わったときに isPlaying を false に戻す
  useEffect(() => {
    const setIsPlayingToFalse = () => setIsPlaying(false)

    audio.addEventListener('ended', setIsPlayingToFalse)
    audio.addEventListener('error', setIsPlayingToFalse)

    return () => {
      audio.removeEventListener('ended', setIsPlayingToFalse)
      audio.removeEventListener('error', setIsPlayingToFalse)
    }
  }, [])

  // 選択されているスピーカーが切り替わったときに、音声の実際の出力先を変更する
  useEffect(() => {
    const setSinkId = async () => {
      if (audio.setSinkId === undefined) return
      if (selectedSpeakerDeviceId === undefined) return
      try {
        await audio.setSinkId(selectedSpeakerDeviceId)
      } catch (e) {
        Comlink.push({
          type: 'system_activity',
          group: 'snoke',
          action: 'failed_to_switch_speaker_on_device_change_modal',
          metadata: {
            errorMessage: e.message ?? '',
          },
        })
        alert('スピーカーの切り替えに失敗しました。')
      }
    }

    setSinkId()
  }, [selectedSpeakerDeviceId])

  return {
    playSound,
    isPlaying,
  }
}
