import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Button, Icon } from '@blue-agency/rogue'
import { useInterviewVideo } from './hooks/useInterviewVideo'
import {
  User as InterviewUser,
  UnstableLevel,
} from '@blue-agency/react-im-interview'
import { InterviewUserType } from '.'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { InterviewEntryNumber as _InterviewEntryNumber } from '../InterviewEntryNumber'
import CameraOffImg from '@/assets/camera_off.png'
import { Wifi as _Wifi } from '@/components/Wifi'

type Props = {
  width: number
  userType: InterviewUserType
  index: number
  user: InterviewUser
  showMute: boolean
  unstableLevel?: UnstableLevel
  showCameraOffImg?: boolean
  onVideoClick: (index: number) => void
  speakerDeviceId?: string
}

export const InterviewVideo: React.FCX<Props> = ({ className, ...props }) => {
  const { videoRef, handleClick } = useInterviewVideo(props)
  const height = (props.width * 9) / 16
  return (
    <Wrapper
      className={className}
      onClick={handleClick}
      width={props.width}
      height={height}
      comlinkPushParams={{ action: 'select_main_video_on_interview' }}
    >
      {props.user.role === 'applicant_screen' ||
      props.user.role === 'recruiter_screen' ? (
        <>
          <ScreenShareIconWrapper user={props.user}>
            <ScreenShareIcon name="screen-share" />
          </ScreenShareIconWrapper>
        </>
      ) : (
        <>
          {props.showCameraOffImg ? (
            <CameraOff src={CameraOffImg} />
          ) : (
            <Video
              autoPlay
              playsInline
              width={props.width}
              user={props.user}
              userType={props.userType}
              ref={videoRef}
              showMute={props.showMute}
              mirrorEffect={props.user.isMine}
            />
          )}
        </>
      )}
      {props.user.isMain && (
        <Overlay>
          <PinIcon name="pin" />
        </Overlay>
      )}
      {props.user.isMine && props.unstableLevel !== undefined && (
        <Wifi unstableLevel={props.unstableLevel} />
      )}
      {props.showMute && <MuteMikeIcon name="mute-mike" />}
      {props.user.entryNumber && (
        <InterviewEntryNumber
          backgroundColor={
            props.userType === 'interviewer'
              ? theme.color.green[4]
              : theme.color.red.iM
          }
          entryNumber={props.user.entryNumber}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Button)<{ width: number; height: number }>`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
  ${buttonResetStyle}
  position: relative;
`
const ScreenShareIconWrapper = styled.div<{ user: InterviewUser }>`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  background: ${theme.color.white[1]};
  border: 2px solid ${theme.color.white[1]};
  display: flex;
  align-items: center;
  justify-content: center;
`
const ScreenShareIcon = styled(Icon)`
  width: 40px;
  height: 33px;
`
type VideoProps = Omit<Props, 'onVideoClick' | 'index' | 'unstableLevel'> & {
  mirrorEffect?: boolean
}
const Video = styled.video<VideoProps>`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  background: ${theme.color.black[1]};
  border: 2px solid ${theme.color.white[1]};
  ${({ mirrorEffect }) => mirrorEffect && 'transform: scaleX(-1);'}
`
const CameraOff = styled.img`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  object-fit: cover;
`
const Wifi = styled(_Wifi)`
  width: 16px;
  height: 16px;
  ${Wrapper} & {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`
const MuteMikeIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  padding: 4px;
  border-radius: 50%;
  color: ${theme.color.white[1]};
  background-color: ${theme.color.red[2]};
  opacity: 0.9;

  ${Wrapper} & {
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
`
const Overlay = styled.div`
  ${Wrapper} & {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  background-color: ${theme.color.black[1]}80;
`
const InterviewEntryNumber = styled(_InterviewEntryNumber)`
  ${Wrapper} & {
    position: absolute;
    top: 2px;
    left: 2px;
  }
`
const PinIcon = styled(Icon)`
  ${Overlay} & {
    position: absolute;
    top: 22px;
    left: 1px;
  }
  width: 18px;
  height: 20px;
  color: ${theme.color.white[1]};
`
