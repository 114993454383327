import { CustomGrpcError } from './CustomGrpcError'
import { Comlink } from '@blue-agency/comlink'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logRpcErr = (rpcName: string, err: any) => {
  const customErr = new CustomGrpcError(err)
  Comlink.push({
    type: 'system_activity',
    group: 'snoke',
    action: 'rpc_error',
    metadata: {
      name: rpcName,
      message: customErr.message ?? '',
      metadata: JSON.stringify(customErr.metadata) ?? '',
      code: customErr.code.toString() ?? '',
    },
  })
}
