import React from 'react'
import styled from 'styled-components'
import { theme, Txt, PrimaryButton } from '@blue-agency/rogue'

type Props = {
  onClick: () => void
}
export const SpStartModal: React.FCX<Props> = ({ className, onClick }) => {
  return (
    <Wrapper className={className}>
      <MainTxt>現在は面接官のみ入室できます</MainTxt>
      <SubTxts>
        <SubTxt>
          面接官全員の準備ができたことを確認し入室制限を解除してください。
        </SubTxt>
        <SubTxt>制限解除の取り消しはできません。</SubTxt>
      </SubTxts>
      <StartButton
        size="l1"
        text="入室制限を解除"
        onClick={onClick}
        comlinkPushParams={{ action: 'start_interview' }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 360px;
  padding: 20px;
  background: ${theme.color.white[1]}D9;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MainTxt = styled(Txt).attrs({ size: 'l' })``
const SubTxts = styled.ul`
  margin-top: 20px;
  margin-left: 20px;
  font-size: ${theme.fontSize.m};
`
const SubTxt = styled.li``
const StartButton = styled(PrimaryButton)`
  margin-top: 20px;
`
