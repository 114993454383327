export const href = {
  contact: 'https://form.run/@im-contact',
  interviewer: {
    survey:
      'https://docs.google.com/forms/d/e/1FAIpQLScLUzV_5Wq6_Y7_0JoZiXARKuWBeNyJyw3OMto-NjVLC0dQGQ/viewform?usp=pp_url&entry.848323147=',
    recommendedEnvironment:
      'https://im-lite-cp.zendesk.com/hc/ja/articles/900005674526',
    help: {
      interview: {
        top: 'https://blag.cc/5EYtWp',
        screenSharing: 'https://blag.cc/GgUW2E',
      },
      environmentCheck: {
        mike: 'https://blag.cc/QAXAA3',
        speaker: 'https://blag.cc/Nh7WLS',
        camera: 'https://blag.cc/nsULpc',
        speed: 'https://blag.cc/yt26RX',
      },
    },
  },
  interviewee: {
    survey:
      'https://docs.google.com/forms/d/e/1FAIpQLSeM8nVpHPu_DctG1XrWABmdYMEchTsAKsPWCrpVRwZ5bXqMWA/viewform?usp=pp_url&entry.848323147=',
    recommendedEnvironment: 'https://blag.cc/h8sUeu',
    help: {
      interview: 'https://blag.cc/CpcMp4',
      environmentCheck: {
        mike: 'https://blag.cc/MVpfK4',
        speaker: 'https://blag.cc/ss2ZeJ',
        camera: 'https://blag.cc/ZtzhpP',
        speed: 'https://blag.cc/KmFCze',
      },
    },
    agentRegistration:
      'https://docs.google.com/forms/d/e/1FAIpQLScy6cdQdl-MAf7oz-Kz8qDuuO1MnERgNWvZEDrheUyhd1oicw/viewform?usp=pp_url&entry.1820718185=',
  },
  organization: {
    help: 'https://im-lite-cp.zendesk.com/hc/ja/sections/900001663843-Web%E9%9D%A2%E6%8E%A5',
    supportPolicy:
      'https://stadium.kibe.la/shared/entries/8b72629d-e7da-4c78-b12a-75acdecb1351',
  },
  terms: 'https://interview-maker.jp/terms/terms-of-service.html',
  privacy: 'https://interview-maker.jp/terms/privacy-policy.html',
  participantTerms: 'https://interview-maker.jp/terms/participant-terms',
  fastCom: 'https://fast.com',
}
