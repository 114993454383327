import React from 'react'
import styled from 'styled-components'
import { Txt } from '@blue-agency/rogue'

export const EconomyModeBox: React.VFC<{ className?: string }> = ({
  className,
}) => {
  return (
    <Wrapper className={className}>
      <Txt size="m">軽量モード利用中</Txt>
      <Txt size="s">管理者により、画質が通常よりも低く設定されています</Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`
