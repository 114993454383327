import React from 'react'
import { Layout as BaseLayout } from '@/components/Layout'
import { OrganizerHeaderRight } from '@/components/OrganizerHeaderRight'
import { useTitle } from '@/hooks/useTitle'
import { useOrganization } from '../useOrganization'

type Props = {
  children: React.ReactNode
}

export const Layout: React.VFC<Props> = ({ children }) => {
  useTitle('タイムガイド設定')
  const { organization } = useOrganization()
  return (
    <BaseLayout
      rightNode={
        <OrganizerHeaderRight
          orgName={organization.name}
          customerCode={organization.guid}
        />
      }
      fixHeight
    >
      {children}
    </BaseLayout>
  )
}
