import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { captureException } from '@sentry/react'
import App from './App'
import { initSentry } from './initSentry'
import { CaptureExceptionProvider } from './hooks/useCaptureException'

const isReactAppSentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true'

if (isReactAppSentryEnabled) {
  initSentry()
}

let elm: JSX.Element

if (isReactAppSentryEnabled) {
  initSentry()
  elm = (
    <CaptureExceptionProvider value={captureException}>
      <App />
    </CaptureExceptionProvider>
  )
} else {
  elm = <App />
}

ReactDOM.render(elm, document.getElementById('root'))
