import { useMemo } from 'react'
import { BffContainer } from '@/hooks/BffContainer'
import { useParams } from 'react-router-dom'
import { useCachedPromise } from '@blue-agency/front-state-management'

export const useOrganization = () => {
  const { organizationToken } = useParams<{ organizationToken?: string }>()
  if (!organizationToken) throw new Error('organizationToken not found')
  const metadata = useMemo(
    () => ({ authorization: organizationToken || '' }),
    [organizationToken]
  )
  const bff = BffContainer.useContainer()
  const organization = useCachedPromise(bff.cacheKey.getOrganization, () =>
    bff.getOrganization(metadata)
  )

  return { organization }
}
