import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { Logo as _Logo } from '@/components/Logo'

type Props = {
  rightNode?: ReactNode
}

export const headerHeight = 60

export const Header: React.FCX<Props> = ({ rightNode, className }) => {
  return (
    <Wrapper className={className}>
      <Left>
        <Logo />
      </Left>
      {rightNode && <Right>{rightNode}</Right>}
    </Wrapper>
  )
}

const flex = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Wrapper = styled.header`
  ${flex};
  justify-content: space-between;
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.s};
  width: 100%;
  height: ${headerHeight}px;
  box-sizing: border-box;
  background: ${theme.color.white[1]};
  border-bottom: 1px solid ${theme.color.gray[3]};
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
`
const Left = styled.div`
  ${flex};
  ${Wrapper} & {
    height: 100%;
  }
`
const Logo = styled(_Logo)`
  ${Wrapper} & {
    margin-left: 25px;
  }
  width: 96px;
`
const Right = styled.div`
  ${Wrapper} & {
    margin-left: auto;
    margin-right: 25px;
  }
`
