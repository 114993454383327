import React from 'react'
import { theme } from '@blue-agency/rogue'
import { InterviewMessage } from './InterviewMessage'
import { UserType } from '@blue-agency/react-im-interview'

export type Message = {
  userType: UserType
  name: string
  text: string
}
type Props = {
  messages: Message[]
  chatFontSize: keyof typeof theme.fontSize
  isPortrait: boolean
}

export const Messages: React.FC<Props> = ({
  messages,
  chatFontSize,
  isPortrait,
}) => {
  return (
    <>
      {messages.map((message, i) => (
        <InterviewMessage
          key={i}
          message={message}
          isPortrait={isPortrait}
          chatFontSize={chatFontSize}
        />
      ))}
    </>
  )
}
