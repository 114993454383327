import React from 'react'
import { Entered } from './Entered'
import { provide } from '@blue-agency/front-state-management'
import { RoomPageContainer } from './RoomPageContainer'
import { Redirect } from 'react-router-dom'
import { path, fillParams } from '@/path'
import { EnvironmentCheck } from './EnvironmentCheck'
import { Waiting } from './Waiting'
import { Tutorial } from './Tutorial'
import { Initial } from './Initial'
import { useTitle } from '@/hooks/useTitle'

export const RoomPage = provide(RoomPageContainer)(() => {
  const { roomStatus, intervieweeToken } = RoomPageContainer.useContainer()

  useTitle('Web面接')

  switch (roomStatus) {
    case 'initial':
      return <Initial />
    case 'environmentCheck':
      return <EnvironmentCheck />
    case 'tutorial':
      return <Tutorial />
    case 'waiting':
      return <Waiting />
    case 'entered':
      return <Entered />
    case 'finished':
      return (
        <Redirect
          to={fillParams({
            path: path.interviewee.interview.finish,
            params: { intervieweeToken },
          })}
        />
      )
    default:
      return <Redirect to="not-found" />
  }
})
