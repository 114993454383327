import React from 'react'
import styled from 'styled-components'
import { Message as _Message } from './Message'
import { theme, Txt } from '@blue-agency/rogue'
import { EnteredContainer } from '../hooks/EnteredContainer'
import { InterviewMainAreaLayout } from '@/components/InterviewMainAreaLayout'
import { DeviceType } from '@/components/InterviewControls'
import { VideoArea } from './VideoArea'
import { UsersArea } from './UsersArea'
import { ChatArea } from './ChatArea'
import { FixedControls } from '@/components/InterviewControls/FixedControls'
import { useInterviewLayout } from '../hooks/useInterviewLayout'
import { StartScreenShareModal } from '@/components/StartScreenShareModal'
import { FinishScreenShareModal } from '@/components/FinishScreenShareModal'
import { ScreenSharingLimitModal } from '@/components/ScreenSharingLimitModal'
import { EconomyModeTxt } from '@/components/EconomyModeTxt'

type Props = {
  height: number
  width: number
  deviceType: DeviceType
}

export const MainArea: React.FCX<Props> = ({
  className,
  width,
  height,
  deviceType,
}) => {
  const {
    errorMessage,
    showUsers,
    showChat,
    chatParams,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharingLimitModal,
    handleScreenShareStart,
    handleScreenShareFinish,
    showBeforeStartMessage,
    isEconomyMode,
  } = EnteredContainer.useContainer()
  const { interviewControlsProps } = useInterviewLayout()
  const {
    userType,
    status,
    muteButtonProps,
    cameraOffButtonProps,
    backButtonProps,
    finishButtonProps,
  } = interviewControlsProps
  return (
    <Wrapper className={className}>
      <InterviewMainAreaLayout
        showChatArea={showChat}
        showUsersArea={showUsers}
        deviceType={deviceType}
        width={width}
        height={height}
        videoArea={<VideoArea />}
        usersArea={<UsersArea deviceType={deviceType} />}
        chatArea={<ChatArea chatParams={chatParams} />}
        controlsArea={
          <FixedControls
            userType={userType}
            status={status}
            muteButtonProps={muteButtonProps}
            cameraOffButtonProps={cameraOffButtonProps}
            backButtonProps={backButtonProps}
            finishButtonProps={finishButtonProps}
          />
        }
      />
      {showBeforeStartMessage && <Message />}
      <StartScreenShareModal
        active={startScreenShareModal.active}
        onClose={startScreenShareModal.close}
        onStart={handleScreenShareStart}
      />
      <FinishScreenShareModal
        active={finishScreenShareModal.active}
        onClose={finishScreenShareModal.close}
        onFinish={handleScreenShareFinish}
      />
      <ScreenSharingLimitModal
        active={screenSharingLimitModal.active}
        onClose={screenSharingLimitModal.close}
      />
      {errorMessage && (
        <ErrorMessageWrapper maxWidth={width - 10}>
          <Txt color={theme.color.red[2]}>{errorMessage}</Txt>
        </ErrorMessageWrapper>
      )}
      {isEconomyMode && <EconomyModeTxtWrapper />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Message = styled(_Message)`
  ${Wrapper} & {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 20px;
  }
`
const ErrorMessageWrapper = styled.div<{ maxWidth: number }>`
  ${Wrapper} & {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 20px;
  }
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 360px;
  padding: 10px;
  background: ${theme.color.white[1]};
  text-align: center;
`

const EconomyModeTxtWrapper = styled(EconomyModeTxt)`
  position: absolute;
  bottom: 0;
`
