import React from 'react'
import { theme, Txt, Icon as _Icon } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  onClick: () => void
}

export const AddButton: React.VFC<Props> = ({ onClick }) => (
  <AddButtonWrapper onClick={onClick}>
    <AddIcon name="add" />
    <Txt size="s">追加</Txt>
  </AddButtonWrapper>
)

const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`

const AddIcon = styled(_Icon).attrs({ size: 's' })`
  color: ${theme.color.navy[1]};
  margin-right: 6px;
`
