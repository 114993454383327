import React from 'react'
import styled, { css } from 'styled-components'
import { href } from '@/href'
import { theme, Icon, Txt, NewTabLink } from '@blue-agency/rogue'

type Props = {
  orgName: string
  customerCode: string
}

export const OrganizerHeaderRight: React.FCX<Props> = ({
  className,
  orgName,
  customerCode,
}) => {
  return (
    <Wrapper className={className}>
      <Link
        href={href.organization.supportPolicy}
        hideIcon
        action="open_support_policy_page"
      >
        <InformationIcon name="information" />
        <LinkTxt size="s">お問い合わせ</LinkTxt>
      </Link>
      <Link
        href={href.organization.help}
        hideIcon
        action="open_organization_help_page"
      >
        <QuestionIcon name="question" />
        <LinkTxt size="s">ヘルプ</LinkTxt>
      </Link>
      <Separator />
      <TxtWrapper>
        <OrgNameTxt size="s">{orgName}</OrgNameTxt>
        <CustomerCodeTxt size="s" color={theme.color.gray[2]}>
          OrganizationID: {customerCode}
        </CustomerCodeTxt>
      </TxtWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`
const Link = styled(NewTabLink)`
  margin-left: 20px;
`
const LinkTxt = styled(Txt)`
  margin-left: 4px;
`
const iconStyle = css`
  width: 22px;
  height: 22px;
  color: ${theme.color.navy[1]};
`
const QuestionIcon = styled(Icon)`
  ${iconStyle}
`
const InformationIcon = styled(Icon)`
  ${iconStyle}
`
const Separator = styled.div`
  margin-left: 27px;
  width: 24px;
  height: 1px;
  background: ${theme.color.navy[2]};
  transform: rotate(90deg);
`
const TxtWrapper = styled.div`
  margin-left: 16px;
  max-width: 285px;
  text-align: left;
`
const OrgNameTxt = styled(Txt)`
  overflow: hidden;
  text-overflow: ellipsis;
  /* MEMO: emotionにより自動付加されるclass名の長さ？によってTxtで設定したstyleに
  優先順位で勝ったり(ステージング)負けたり(本番)している疑惑があるので
  一時的にimportantで回避している */
  white-space: nowrap !important;
`
const CustomerCodeTxt = styled(Txt)``
