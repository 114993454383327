import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { path } from '@/path'
import { InterviewRoutes } from './InterviewRoutes'
import { NotFound } from '@/components/NotFound'

export const InterviewerRoutes = () => {
  return (
    <Switch>
      <Route
        path={path.interviewer.interview.index}
        component={InterviewRoutes}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
