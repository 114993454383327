import React from 'react'
import styled from 'styled-components'
import { theme, Txt, A } from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import { InterviewEntryNumber as _InterviewEntryNumber } from '@/components/InterviewEntryNumber'
import { UserType } from '@blue-agency/react-im-interview'

type Message = {
  userType: UserType
  name: string
  text: string
}
type Props = {
  message: Message
  chatFontSize: keyof typeof theme.fontSize
  isPortrait: boolean
}

const regExp = /(https?:\/\/\S+)/g

export const InterviewMessage: React.FCX<Props> = ({
  className,
  message,
  chatFontSize,
  isPortrait,
}) => {
  return (
    <Message
      className={className}
      userType={message.userType}
      isPortrait={isPortrait}
    >
      <InterviewEntryNumber
        backgroundColor={
          message.userType === 'interviewer'
            ? theme.color.green[4]
            : theme.color.red.iM
        }
        entryNumber={message.name}
      />
      <MainTxt size={chatFontSize}>
        <Span>
          {reactStringReplace(message.text, regExp, (match, i) => (
            <A
              key={i}
              href={match}
              target="_blank"
              rel="noopener noreferrer"
              comlinkPushParams={{ action: 'open_posted_url' }}
            >
              {match}
            </A>
          ))}
        </Span>
      </MainTxt>
    </Message>
  )
}

type MessageProps = {
  userType: UserType
  isPortrait: boolean
}
const Message = styled.div<MessageProps>`
  background-color: ${({ userType }) =>
    userType === 'interviewer' ? theme.color.green[8] : theme.color.red[4]};
  padding: 8px 8px 8px 24px;
  border-bottom: 1px solid ${theme.color.gray[5]};
  display: flex;
  position: relative;
`
const InterviewEntryNumber = styled(_InterviewEntryNumber)`
  ${Message} & {
    position: absolute;
    top: 9px;
    left: 0;
  }
`
const MainTxt = styled(Txt)``

const Span = styled.span`
  word-break: break-all;
`
