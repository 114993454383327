import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  TertiaryButton,
  SecondaryDangerButton,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { EnteredContainer } from '../hooks/EnteredContainer'
import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'

type Props = {
  active: boolean
}

const text = `面接を終了しようとしています。
・終了した場合、この面接URLは再利用できません。
・面接官が複数の場合は、一人が終了させた時点で面接が終了となります。`

export const FinishModal: React.FCX<Props> = ({ className, active }) => {
  const { finishModal, handleFinish } = EnteredContainer.useContainer()
  const { isPortrait } = WindowSizeContainer.useContainer()

  return (
    <Modal
      className={className}
      size={isPortrait ? 's' : 'm'}
      active={active}
      title="面接終了確認"
      onClose={finishModal.close}
    >
      <Wrapper>
        <Upper>
          <Txt>{text}</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <ButtonGroup>
            <CancelButton
              text="キャンセル"
              onClick={finishModal.close}
              comlinkPushParams={{ action: 'close_finish_interview_modal' }}
            />
            <FinishButton
              text="面接を終了"
              onClick={handleFinish}
              comlinkPushParams={{ action: 'finish_interview' }}
            />
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  ${Wrapper} & {
    margin-top: 40px;
  }
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
const FinishButton = styled(SecondaryDangerButton)`
  ${ButtonGroup} & {
    width: 48%;
  }
`
