import React from 'react'
import styled, { css } from 'styled-components'
import { InterviewVideos } from '@/components/InterviewVideos'
import { EnteredContainer } from '../hooks/EnteredContainer'
import { DeviceType } from '@/components/InterviewControls'
import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'
import ResizeDetector from 'react-resize-detector'

type Props = {
  deviceType: DeviceType
}

export const UsersArea: React.FCX<Props> = ({ className, deviceType }) => {
  const {
    imInterview,
    mutedStreamIdMap,
    handleInterviewerVideoClick,
    handleIntervieweeVideoClick,
  } = EnteredContainer.useContainer()
  const { height } = WindowSizeContainer.useContainer()
  return (
    <ResizeDetector>
      {({
        width,
        targetRef,
      }: {
        width: number
        targetRef: React.RefObject<HTMLDivElement>
      }) => {
        const interviewVideosWidth =
          deviceType === 'spPortrait' ? width / 2 : width
        return (
          <Wrapper
            className={className}
            deviceType={deviceType}
            height={height}
            ref={targetRef}
          >
            <InterviewerVideos
              userType="interviewer"
              width={interviewVideosWidth}
              deviceType={deviceType}
              users={imInterview.interviewers}
              mutedStreamIdMap={mutedStreamIdMap}
              unstableLevel={imInterview.unstableLevel}
              isCameraOff={imInterview.isCameraOff}
              onVideoClick={handleInterviewerVideoClick}
              speakerDeviceId={imInterview.selectedSpeakerDeviceId}
            />
            <IntervieweeVideos
              userType="interviewee"
              width={interviewVideosWidth}
              deviceType={deviceType}
              users={imInterview.interviewees}
              mutedStreamIdMap={mutedStreamIdMap}
              isCameraOff={imInterview.isCameraOff}
              onVideoClick={handleIntervieweeVideoClick}
              speakerDeviceId={imInterview.selectedSpeakerDeviceId}
            />
          </Wrapper>
        )
      }}
    </ResizeDetector>
  )
}

const Wrapper = styled.div<{ deviceType: DeviceType; height: number }>`
  width: 100%;
  ${({ deviceType, height }) => {
    switch (deviceType) {
      case 'spPortrait':
        return css`
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
        `
      case 'spLandscape':
        return css`
          height: ${height}px;
        `
    }
  }}
`
const InterviewerVideos = styled(InterviewVideos)``
const IntervieweeVideos = styled(InterviewVideos)<{ deviceType: DeviceType }>`
  ${({ deviceType }) =>
    deviceType !== 'spPortrait' &&
    css`
      ${Wrapper} && {
        margin-top: 30px;
      }
    `}
`
