import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'

export const BaseCard = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  opacity: 0.85;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${theme.color.white[1]};
  background-color: ${theme.color.navy[1]};
  box-sizing: border-box;
`
