import { createContainer } from '@blue-agency/front-state-management'
import { useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ToastContainer } from '@/hooks/ToastContainer'
import { path, fillParams } from '@/path'
import * as yup from 'yup'
import { templateForm } from './template'

type Part = {
  guide_time_minutes: number
  title: string
  description: string
}

export type TimeGuideForm = {
  name: string
  interviewTime?: number
  parts: Part[]
}

const partsScheme = yup
  .object()
  .defined()
  .required()
  .shape({
    guide_time_minutes: yup
      .number()
      .required()
      .integer()
      .typeError('1分以上の値を半角で入力してください')
      .min(1, '1分以上の値を半角で入力してください')
      .max(180, '180分以内の値で入力してください'),
    title: yup
      .string()
      .required('入力してください')
      .max(30, '30文字以内で入力してください'),
  })

const validationScheme = yup.object().shape({
  name: yup
    .string()
    .required('入力してください')
    .max(30, '30文字以内で入力してください'),
  interviewTime: yup
    .number()
    .required()
    .integer()
    .typeError('1分以上の値を半角で入力してください')
    .min(1, '1分以上の値を半角で入力してください')
    .max(180, '180分以内の値で入力してください'),
  parts: yup.array(partsScheme).defined(),
})

const defaultTimeGuide: TimeGuideForm = {
  name: '',
  interviewTime: 0,
  parts: [
    {
      guide_time_minutes: 0,
      title: '',
      description: '',
    },
  ],
}

const useRegister = () => {
  const { organizationToken } = useParams<{ organizationToken?: string }>()
  if (!organizationToken) throw new Error('Not found organizationToken')
  const [isSubmitError, setIsSubmitError] = useState(false)
  const [interviewTimeErrorTxt, setInterviewTimeErrorTxt] = useState('')
  const [selectTemplate, setSelectTemplate] = useState('')
  const history = useHistory()
  const { toast } = ToastContainer.useContainer()
  const { register, control, errors, handleSubmit, clearErrors, reset } =
    useForm<TimeGuideForm>({
      resolver: yupResolver(validationScheme),
      mode: 'onBlur',
      defaultValues: defaultTimeGuide,
    })
  useEffect(() => {
    if (selectTemplate) {
      reset({
        name: templateForm[selectTemplate].name,
        interviewTime: templateForm[selectTemplate].interviewTime,
        parts: templateForm[selectTemplate].parts,
      })
    }
  }, [selectTemplate, reset])

  const partsFieldArray = useFieldArray<Part>({
    control,
    name: 'parts',
  })
  const onAddPart = useCallback(() => {
    partsFieldArray.append({
      guide_time_minutes: 0,
      title: '',
      description: '',
    } as Part)
  }, [partsFieldArray])

  const onRemovePart = useCallback(
    (index: number) => {
      partsFieldArray.remove(index)
      clearErrors()
    },
    [partsFieldArray, clearErrors]
  )
  const onMovePart = useCallback(
    (from: number, to: number) => {
      partsFieldArray.move(from, to)
    },
    [partsFieldArray]
  )
  const onSelectTemplate = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setSelectTemplate(event.currentTarget.id)
    },
    []
  )
  const isMatchTotalTime = (data: TimeGuideForm) => {
    const fixedInterviewTime = data.interviewTime ?? 0
    const totalRequiredTime = data.parts.reduce(
      (sum, num) => sum + num.guide_time_minutes,
      0
    )
    if (totalRequiredTime === fixedInterviewTime) return true
    const errorTxt =
      totalRequiredTime > fixedInterviewTime
        ? '所要時間の合計が面接時間を超えています。シーンを削除するか、面接時間を変更してください。'
        : '所要時間の合計が面接時間に達していません。シーンを追加するか、面接時間を変更してください。'
    setInterviewTimeErrorTxt(errorTxt)
    return false
  }
  const onSubmit = useCallback(
    async (data: TimeGuideForm) => {
      if (!isMatchTotalTime(data)) return
      try {
        // TODO:createInterviewGuideでリクエストする
      } catch (e) {
        setIsSubmitError(true)
        throw e
      }
      history.push(
        fillParams({
          path: path.organization.setting.timeGuide.index,
          params: { organizationToken },
        })
      )
      toast('タイムガイドを保存しました')
    },
    [history, toast, organizationToken]
  )

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    control,
    isSubmitError,
    partsFieldArray: partsFieldArray.fields,
    onAddPart,
    onRemovePart,
    onMovePart,
    onSelectTemplate,
    interviewTimeErrorTxt,
  }
}

export const RegisterContainer = createContainer(useRegister)
