import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { path } from '@/path'
import { RoomPage } from './RoomPage'
import { FinishPage } from './FinishPage'
import { NotFound } from '@/components/NotFound'
import { useTitle } from '@/hooks/useTitle'

export const InterviewRoutes = () => {
  useTitle('Web面接')

  return (
    <Switch>
      <Route
        exact
        path={path.interviewee.interview.room}
        component={RoomPage}
      />
      <Route
        exact
        path={path.interviewee.interview.finish}
        component={FinishPage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
