import React from 'react'
import styled from 'styled-components'
import {
  Icon as _Icon,
  Txt,
  WeakColorSeparator,
  PrimaryButton,
  TertiaryButton,
  theme,
} from '@blue-agency/rogue'
import { StepContainer } from './hooks/useStep'
import { overflowYScrollStyle } from '@/styles/overflowYScrollStyle'
import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'
import { BrowserContainer } from '@/hooks/BrowserContainer'
import { ManualModalContainer } from '../hooks/ManualModalContainer'

export const Second: React.FCX = ({ className }) => {
  const manualModal = ManualModalContainer.useContainer()
  const { handleBack } = StepContainer.useContainer()
  const { width } = WindowSizeContainer.useContainer()
  const { isPcByWindowWidth } = BrowserContainer.useContainer()
  const isSp = !isPcByWindowWidth(width)

  return (
    <Wrapper className={className}>
      <Upper isSpLayout={isSp}>
        <Row>
          <IconWrapper isSpLayout={isSp}>
            <ChatIcon name="chat" />
          </IconWrapper>
          <div style={{ flex: 1 }}>
            <Txt>チャット機能</Txt>
            <Txt size="s">
              面接に参加している全員でのチャットが可能です。
              <br />
              「音声が聞こえない」「映像/画面が見えない」
              <br />
              などのトラブルが起きた場合にもご利用いただけます。
            </Txt>
          </div>
        </Row>
        <Row>
          <IconWrapper isSpLayout={isSp}>
            <ReloadIcon name="reload" />
          </IconWrapper>
          <div style={{ flex: 1 }}>
            <Txt>再読込 / リロード</Txt>
            <Txt size="s">
              音声/映像が不安定な場合にお試しください。
              <br />
              接続状況が改善する場合があります。
            </Txt>
          </div>
        </Row>
        <Row>
          <IconWrapper isSpLayout={isSp}>
            <EarphoneIcon name="earphone" />
          </IconWrapper>
          <div style={{ flex: 1 }}>
            <Txt>イヤホンの再接続</Txt>
            <Txt size="s">
              「音声が聞こえない」ときは、イヤホンを再接続してみましょう。
              <br />
              接続状況が改善され、聞こえるようになる場合があります。
            </Txt>
          </div>
        </Row>
      </Upper>
      <WeakColorSeparator />
      <Bottom>
        <BackButton
          comlinkPushParams={{
            action: 'click_back_button_on_waiting_room_manual',
          }}
          text="戻る"
          onClick={handleBack}
        />
        <CloseButton
          comlinkPushParams={{
            action: 'click_close_button_on_waiting_room_manual',
          }}
          text="閉じる"
          onClick={manualModal.close}
        />
      </Bottom>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Upper = styled.div<{ isSpLayout: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;

  padding: ${({ isSpLayout }) => (isSpLayout ? '20px 16px' : '0 70px')};

  ${overflowYScrollStyle};
`

const Row = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 37px;
  }
`

const IconWrapper = styled.div<{ isSpLayout: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  margin-right: ${({ isSpLayout }) => (isSpLayout ? '12px' : '40px')};
`

const Icon = styled(_Icon)`
  color: ${theme.color.white[1]};
`
const ChatIcon = styled(Icon)`
  width: 50px;
  height: 50px;
`
const ReloadIcon = styled(Icon)`
  width: 40px;
  height: 40px;
`
const EarphoneIcon = styled(Icon)`
  width: 50px;
  height: 50px;
`
const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`
const BackButton = styled(TertiaryButton)`
  width: 166px;
  height: 44px;

  margin-right: 8px;
`
const CloseButton = styled(PrimaryButton)`
  width: 166px;
  height: 44px;
`
