import React from 'react'
import { InterviewControlsProps } from '..'
import { Interviewer } from './Interviewer'
import { Interviewee } from './Interviewee'

export const controlsHeight = 60
export const SpPortrait: React.FCX<InterviewControlsProps> = (props) => {
  return props.userType === 'interviewer' ? (
    <Interviewer {...props} controlsHeight={controlsHeight} />
  ) : (
    <Interviewee {...props} controlsHeight={controlsHeight} />
  )
}
