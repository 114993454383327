import React from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import {
  theme,
  Icon,
  NewTabLink,
  Txt,
  RadioGroup,
  RadioOption,
  Tooltip,
} from '@blue-agency/rogue'
import { format } from 'date-fns'
import { TableBase } from '@/components/TableBase'
import { useModal } from '@/hooks/useModal'
import { SetTimeguideModal } from './SetTimeguideModal'
import { StopTimeguideModal } from './StopTimeguideModal'
import { href } from '@/href'

type Property = 'using' | 'name' | 'time' | 'note' | 'createdAt' | 'padding'

type TimeguideItem = {
  using: string
  name: string
  time: string
  note?: string
  createdAt?: Date
}

type TableCellProps = {
  property: Property
  hover?: boolean
}

type TableRowProps = {
  active?: boolean
}

const width = {
  using: 100,
  name: 300,
  time: 150,
  note: 420,
  createdAt: 94,
  padding: 30,
}

export const Content: React.FC = () => {
  const notSetTimeguide = {
    using: 'notSet',
    name: '設定しない',
    time: '60分',
    note: 'タイムガイド機能を利用しない場合は、これを選択してください。',
  }
  const setTimeguide = [
    {
      using: 'newGraduate',
      name: '新卒用タイムガイド',
      time: '60分',
      createdAt: new Date(2021, 1, 10, 11, 10),
    },
  ]
  const fixedTimeguideList = [notSetTimeguide, ...setTimeguide]

  return (
    <Table hover>
      <TableHead>
        <TableRow>
          <Th property="using">利用中</Th>
          <Th property="name">
            <span>タイムガイド名</span>
            <QuestionIconWrapper data-tip data-for="timeguideName">
              <QuestionIcon />
            </QuestionIconWrapper>
            <Tooltip id="timeguideName" arrowPosition="topLeft">
              <Txt size="s">
                タイムガイド名とは？
                <FAQLinkWrapper>
                  <NewTabLink
                    href={href.organization.help}
                    action="click_detail_link_in_timeguide_name_tooltip"
                  >
                    詳細はこちら
                  </NewTabLink>
                </FAQLinkWrapper>
              </Txt>
            </Tooltip>
          </Th>
          <Th property="time">
            <span>面接時間</span>
          </Th>
          <Th property="note" />
          <Th property="createdAt">作成日時</Th>
          <Th property="padding" />
        </TableRow>
      </TableHead>
      <TableBody>
        {fixedTimeguideList.map((item, index) => (
          <TableStructure key={index} timeguide={item} />
        ))}
      </TableBody>
    </Table>
  )
}

const TableStructure: React.FCX<{ timeguide: TimeguideItem }> = ({
  timeguide,
}) => {
  const setTimeguideModal = useModal()
  const stopTimeguideModal = useModal()

  const { using, name, time, note, createdAt } = timeguide
  const useOptions: RadioOption[] = [{ value: using }]

  return (
    <>
      <TableRow active>
        <Td property="using">
          <RadioGroup
            name="using"
            options={useOptions}
            direction="column"
            defaultValue="notSet"
            onChange={
              using === 'notSet'
                ? stopTimeguideModal.open
                : setTimeguideModal.open
            }
          />
        </Td>
        <Td property="name">{name}</Td>
        <Td property="time">{time}</Td>
        <Td property="note">{note ?? ''}</Td>
        <Td property="createdAt">
          {createdAt ? format(createdAt, 'yyyy/MM/dd HH:mm') : '-'}
        </Td>
        <Td property="padding" />
      </TableRow>
      <SetTimeguideModal
        active={setTimeguideModal.active}
        onClose={setTimeguideModal.close}
        onOpen={setTimeguideModal.open}
      />
      <StopTimeguideModal
        active={stopTimeguideModal.active}
        onClose={stopTimeguideModal.close}
        onOpen={stopTimeguideModal.open}
      />
    </>
  )
}

const Table = styled(TableBase)`
  width: 100%;
  td {
    padding: 0;
  }
`

const TableHead = styled.thead``

const TableBody = styled.tbody``

const TableRow = styled.tr<TableRowProps>`
  display: flex;
  align-items: center;
  ${({ active }) =>
    !active &&
    css`
      background: ${theme.color.gray[4]};
    `}
`

const Th = styled.th<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => CSS[property]}
`
const Td = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ property }) => CSS[property]}
  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: ${theme.color.gray[4]};
      }
    `}
`

const CSS: Record<Property, FlattenSimpleInterpolation> = {
  using: css`
    display: flex;
    justify-content: center;
    flex: 0 0 ${width.using}px;
  `,
  name: css`
    > *:not(:last-child) {
      margin-right: 8px;
    }
    flex: 0 0 ${width.name}px;
  `,
  time: css`
    flex: 0 0 ${width.time}px;
    justify-content: center;
  `,
  note: css`
    flex: 0 0 ${width.note}px;
  `,
  createdAt: css`
    flex: 0 0 ${width.createdAt}px;
    text-align: center;
    justify-content: center;
  `,
  padding: css`
    flex: 1 0 auto;
  `,
}
const QuestionIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`
const QuestionIcon = styled(Icon).attrs({
  name: 'question-bg-white',
  size: 'l',
})`
  color: ${theme.color.navy[1]};
`
const FAQLinkWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`
