import { createContainer } from '@blue-agency/front-state-management'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { Comlink } from '@blue-agency/comlink'
import { CommunicationErrorModalContainer } from '@/hooks/CommunicationErrorModalContainer'
import {
  InterviewChatWebSocketParams,
  parseUserGuid,
} from '@/hooks/useInterviewChatWebSocket'
import { Message } from '@/components/Chat/Messages'
import { useModal } from '@/hooks/useModal'
import { RoomPageContainer } from '../../../RoomPageContainer'
import { BffContainer } from '@/hooks/BffContainer'
import { GetInterviewerInterviewResponse } from '@blue-agency/proton/web/v2/snoke_bff/snoke_bff_service_pb'
import { useIMInterview } from './useIMInterview'
import { ToastContainer } from '@/hooks/ToastContainer'
import {
  useSoraCaptureException,
  useInterviewerSoraErrorMessage,
  REASON_PARTICIPANTS_NUM_LIMIT,
} from '@/hooks/SoraError'
import { BeepContainer } from '@/hooks/BeepContainer'
import { useStartInterviewBeep } from '@/hooks/useStartInterviewBeep'
import { startScreenSharingBeepSrc } from './startScreenSharingBeepSrc'

const vaderWebHost = process.env.REACT_APP_VADER_WEB_HOST
if (!vaderWebHost) throw new Error('vaderWebHost not found')

const useEntered = () => {
  const { interview, interviewerToken, setRoomStatus, isEconomyMode } =
    RoomPageContainer.useContainer()
  const bff = BffContainer.useContainer()
  const { handleCommunicationErrorModalOpen } =
    CommunicationErrorModalContainer.useContainer()
  const { toast } = ToastContainer.useContainer()
  const { beep } = BeepContainer.useContainer()

  const imInterview = useIMInterview()

  useStartInterviewBeep(imInterview.status)

  useEffect(() => {
    Comlink.push({
      type: 'system_activity',
      group: 'snoke',
      action: 'change_unstable_level',
      targetName: 'interviewerToken',
      targetIdStr: interviewerToken,
      metadata: {
        userType: 'interviewer',
        unstableLevel: imInterview.unstableLevel + '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imInterview.unstableLevel])

  useSoraCaptureException(imInterview.mainSignalingError)
  useSoraCaptureException(imInterview.screenSharingRecvSignalingError)
  const errorMessageMain = useInterviewerSoraErrorMessage(
    imInterview.mainSignalingError
  )
  const errorMessageScreenSharing = useInterviewerSoraErrorMessage(
    imInterview.screenSharingRecvSignalingError
  )
  const errorMessage = useMemo(
    () => errorMessageMain || errorMessageScreenSharing,
    [errorMessageMain, errorMessageScreenSharing]
  )

  const handleInterviewerVideoClick = useCallback(
    (index: number) => {
      if (imInterview.status !== 'started') return
      imInterview.selectMainUser('interviewer', index)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imInterview.selectMainUser, imInterview.status]
  )
  const handleIntervieweeVideoClick = useCallback(
    (index: number) => {
      if (imInterview.status !== 'started') return
      imInterview.selectMainUser('interviewee', index)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imInterview.selectMainUser, imInterview.status]
  )

  const [startTime, setStartTime] = useState(interview.startedAt)

  const [showUsers, setShowUsers] = useState(true)
  const [showChat, setShowChat] = useState(false)

  const toggleShowUsers = useCallback(() => setShowUsers((prev) => !prev), [])
  const toggleShowChat = useCallback(() => setShowChat((prev) => !prev), [])

  const finishModal = useModal()
  const startScreenShareModal = useModal()
  const finishScreenShareModal = useModal()
  const screenSharingLimitModal = useModal()

  useEffect(() => {
    if (imInterview.isOtherScreenShared) {
      startScreenShareModal.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imInterview.isOtherScreenShared])

  const handleScreenShareStart = useCallback(async () => {
    const { started, error } = await imInterview.startScreenSharing()
    if (error) {
      // 画面共有数の上限の場合も以下エラーが返る
      if (error.reason === REASON_PARTICIPANTS_NUM_LIMIT) {
        screenSharingLimitModal.open()
        return
      } else {
        alert('画面共有に失敗しました。もう一度お試しください。')
        throw error
      }
    }
    if (!started) return
    beep(startScreenSharingBeepSrc)
    startScreenShareModal.close()
  }, [imInterview, screenSharingLimitModal, startScreenShareModal, beep])

  const handleScreenShareFinish = useCallback(() => {
    imInterview.finishScreenSharing()
    finishScreenShareModal.close()
  }, [imInterview, finishScreenShareModal])

  const videoRef = useCallback(
    (element: HTMLVideoElement | null) => {
      if (!imInterview.mainUser || !element) return
      element.srcObject = imInterview.mainUser.stream
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imInterview.mainUser?.stream.id]
  )

  const mutedMainVideoAudio = useMemo(() => {
    if (!imInterview.mainUser) return false
    return imInterview.mutedStreamIdMap[imInterview.mainUser.id]
  }, [imInterview.mainUser, imInterview.mutedStreamIdMap])

  const handleStart = useCallback(async () => {
    const res = await bff
      .startInterview(interview.videoInterviewGuid)
      .catch((err) => {
        handleCommunicationErrorModalOpen()
        throw err
      })
    setStartTime(res.startedAt)
    imInterview.start()
    toast('面接が開始されました')
  }, [
    bff,
    interview.videoInterviewGuid,
    imInterview,
    toast,
    handleCommunicationErrorModalOpen,
  ])

  const handleFinish = useCallback(async () => {
    await bff.finishInterview(interview.videoInterviewGuid).catch((err) => {
      finishModal.close()
      const callback = () => finishModal.open()
      handleCommunicationErrorModalOpen({ callback })
      throw err
    })
    imInterview.finish()
  }, [
    imInterview,
    handleCommunicationErrorModalOpen,
    finishModal,
    bff,
    interview.videoInterviewGuid,
  ])

  useEffect(() => {
    if (imInterview.status === 'started') {
      ;(async () => {
        const res = await bff.getInterviewerInterview(interviewerToken)
        setStartTime(res.startedAt)
      })()
    } else if (imInterview.status === 'finished') {
      setRoomStatus('finished')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imInterview.status])

  const [chatParams, setChatParams] = useState<InterviewChatWebSocketParams>()
  const [chatMessages, setChatMessages] = useState<Message[]>([])
  const [unreadMessagesCnt, setUnreadMessagesCnt] = useState(0)

  const onAddMessage = useCallback(
    (message: Message) => {
      !showChat && setUnreadMessagesCnt((prev) => ++prev)
      setChatMessages((prev) => [...prev, message])
    },
    [showChat]
  )

  useEffect(() => {
    ;(async () => {
      if (imInterview.status !== 'started' || !imInterview.myEntryNumber) return
      const res = await bff.listChatMessages(interview.chatRoomGuid)
      const chatMessages: Message[] = res.chatMessages.map((message) => {
        const { userType, entryNumber } = parseUserGuid(message.getUserGuid())
        return {
          userType,
          name: entryNumber,
          text: message.getText(),
        }
      })
      setChatMessages(chatMessages)
      setChatParams({
        chatRoomGuid: interview.chatRoomGuid,
        userType: 'interviewer',
        entryNumber: imInterview.myEntryNumber,
        onAddMessage,
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imInterview.status, imInterview.myEntryNumber])

  useEffect(() => {
    setChatParams((prev) => (prev ? { ...prev, onAddMessage } : prev))
    if (showChat) setUnreadMessagesCnt(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChat])

  const exitModal = useModal()

  const showStartModal =
    !errorMessage &&
    interview.status === GetInterviewerInterviewResponse.Status.NOT_STARTED &&
    imInterview.status === 'notStarted'

  return {
    imInterview,
    errorMessage,
    handleIntervieweeVideoClick,
    handleInterviewerVideoClick,
    videoRef,
    mutedMainVideoAudio,
    mutedStreamIdMap: imInterview.mutedStreamIdMap,
    handleStart,
    handleFinish,
    showUsers,
    toggleShowUsers,
    showChat,
    toggleShowChat,
    finishModal,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharingLimitModal,
    handleScreenShareStart,
    handleScreenShareFinish,
    startTime,
    chatParams,
    chatMessages,
    unreadMessagesCnt,
    exitModal,
    showStartModal,
    isEconomyMode,
  }
}

export const EnteredContainer = createContainer(useEntered)
