import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BaseCard } from './BaseCard'
import { SurveyLinkButton } from './SurveyLinkButton'
import { SurveyCardProps } from './types'

export const SpPortraitSurveyCard: React.VFC<SurveyCardProps> = ({
  openSurveyLink,
}) => {
  return (
    <Card>
      <RequestTxt size="m" bold color={theme.color.white[1]}>
        サービス品質向上のため、ご意見をお聞かせください。
      </RequestTxt>
      <Lower>
        <PresentTxt size="s" color={theme.color.white[1]}>
          抽選で毎月5名様に
          <Bold>
            Amazonギフト券
            <br />
            1,000円分
          </Bold>
          プレゼント中！
        </PresentTxt>
        <SurveyLinkButton width="142px" openSurveyLink={openSurveyLink} />
      </Lower>
    </Card>
  )
}

const Card = styled(BaseCard)`
  flex-direction: column;
`

const Lower = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`

const RequestTxt = styled(Txt)`
  margin: 0;
`

const PresentTxt = styled(Txt)`
  margin: 0;
`

const Bold = styled.span`
  font-weight: bold;
`
