export const injectAlertBox = () => {
  const interviewerUrl = process.env.REACT_APP_INTERVIEWER_ALERT_URI
  const intervieweeUrl = process.env.REACT_APP_INTERVIEWEE_ALERT_URI
  const alertBoxUrl = process.env.REACT_APP_ALERT_BOX_URI
  if (!interviewerUrl || !intervieweeUrl || !alertBoxUrl)
    throw new Error('env not found')

  const jsonUrl = window.location.pathname.match(/^\/interviewer/)
    ? interviewerUrl
    : intervieweeUrl

  const script = document.createElement('script')
  script.src = alertBoxUrl

  document.body.appendChild(script)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).Alertbox = (window as any).Alertbox || []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).Alertbox.push(jsonUrl)
}
