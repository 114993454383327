import React from 'react'
import styled, { css } from 'styled-components'
import {
  theme,
  Icon as _Icon,
  TertiaryButton,
  SecondaryDangerButton,
  ActiveTertiaryButton,
  Txt,
  Button,
  Timer,
  ClickOutsideListener,
} from '@blue-agency/rogue'
import { Dropdown as _Dropdown } from './Dropdown'
import { InterviewControlsProps } from '.'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { RedCircle as _RedCircle } from './RedCircle'
import { AlertboxHeightContainer } from '@/hooks/AlertboxHeightContainer'
import { ReloadModal } from '@/components/ReloadModal'
import { UnstableConnectionModal } from './UnstableConnectionModal'
import { ChangeDeviceModal } from './ChangeDeviceModal'

export const SpLandscape: React.FCX<InterviewControlsProps> = ({
  className,
  userType,
  windowSize,
  timerProps,
  status,
  chatButtonProps,
  muteButtonProps,
  cameraOffButtonProps,
  userButtonProps,
  backButtonProps,
  finishButtonProps,
  menuProps,
  changeDeviceProps,
}) => {
  const {
    showDropdown,
    handleAdditionalMenuIconClick,
    dropdownRef,
    handleOutsideClick,
    items,
    unstableConnectionModal,
    changeDeviceModal,
    reloadModal,
  } = menuProps
  const alertboxHeight = AlertboxHeightContainer.useContainer()

  return (
    <Wrapper className={className} height={windowSize.height - alertboxHeight}>
      {userType === 'interviewer' && timerProps && (
        <Timer {...timerProps} fontSize="xxs" />
      )}
      <ButtonGroup>
        <ReloadButtonWrapper>
          <ReloadButton
            text="再読込"
            onClick={reloadModal.open}
            icon={<Icon name="reload" />}
            direction="column"
            disabled={userButtonProps.disabled}
            comlinkPushParams={{ action: 'click_reload_button_at_interview' }}
          />
        </ReloadButtonWrapper>
        <UserButtonWrapper>
          {userButtonProps.active ? (
            <ActiveUserButton
              text="参加者"
              onClick={userButtonProps.onClick}
              icon={<Icon name="presenter" />}
              direction="column"
              disabled={userButtonProps.disabled}
              comlinkPushParams={{ action: 'hide_interview_users_area' }}
            />
          ) : (
            <UserButton
              text="参加者"
              onClick={userButtonProps.onClick}
              icon={<Icon name="presenter" />}
              direction="column"
              disabled={userButtonProps.disabled}
              comlinkPushParams={{ action: 'show_interview_users_area' }}
            />
          )}
          {!!userButtonProps.count && (
            <UserButtonCount>
              <Txt size="xs" bold color={theme.color.white[1]}>
                {userButtonProps.count}
              </Txt>
            </UserButtonCount>
          )}
        </UserButtonWrapper>
        <ChatButtonWrapper>
          {chatButtonProps.active ? (
            <ActiveChatButton
              text="チャット"
              onClick={chatButtonProps.onClick}
              icon={<Icon name="chat" />}
              direction="column"
              disabled={chatButtonProps.disabled}
              comlinkPushParams={{ action: 'hide_interview_chat_area' }}
            />
          ) : (
            <ChatButton
              text="チャット"
              onClick={chatButtonProps.onClick}
              icon={<Icon name="chat" />}
              direction="column"
              disabled={chatButtonProps.disabled}
              comlinkPushParams={{ action: 'show_interview_chat_area' }}
            />
          )}
          {chatButtonProps.unread && <RedCircle />}
        </ChatButtonWrapper>
        <ButtonWrapper>
          {muteButtonProps.active ? (
            <ActiveMuteButton
              text="解除"
              onClick={muteButtonProps.onClick}
              icon={<Icon name="mute-mike" />}
              direction="column"
              disabled={muteButtonProps.disabled}
              comlinkPushParams={{ action: 'unmute_at_interview' }}
            />
          ) : (
            <MuteButton
              text="ミュート"
              onClick={muteButtonProps.onClick}
              icon={<Icon name="mike" />}
              direction="column"
              disabled={muteButtonProps.disabled}
              comlinkPushParams={{ action: 'mute_at_interview' }}
            />
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          {cameraOffButtonProps.active ? (
            <ActiveMuteButton
              text="解除"
              onClick={cameraOffButtonProps.onClick}
              icon={<Icon name="movie-off" />}
              direction="column"
              disabled={cameraOffButtonProps.disabled}
              comlinkPushParams={{ action: 'camera_on_at_interview' }}
            />
          ) : (
            <MuteButton
              text="オフ"
              onClick={cameraOffButtonProps.onClick}
              icon={<Icon name="movie" />}
              direction="column"
              disabled={cameraOffButtonProps.disabled}
              comlinkPushParams={{ action: 'camera_off_at_interview' }}
            />
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          {userType === 'interviewer' && (
            <>
              {status === 'started' ? (
                <FinishButton
                  text="面接終了"
                  onClick={finishButtonProps?.onClick}
                  icon={<Icon name="close" />}
                  direction="column"
                  disabled={finishButtonProps?.disabled}
                  comlinkPushParams={{ action: 'open_finish_interview_modal' }}
                />
              ) : (
                <BackButton
                  text="退室"
                  onClick={backButtonProps?.onClick}
                  icon={<Icon name="exit" />}
                  direction="column"
                  disabled={backButtonProps?.disabled}
                  comlinkPushParams={{
                    action: 'back_to_interview_preparation_room',
                  }}
                />
              )}
            </>
          )}
        </ButtonWrapper>
      </ButtonGroup>
      <AdditionalMenuIconWrapper>
        <AdditionalMenuIconButton
          onClick={handleAdditionalMenuIconClick}
          comlinkPushParams={{
            action: 'show_dropdown_on_interview_controls',
          }}
        >
          <Icon name="settings" />
        </AdditionalMenuIconButton>
        {showDropdown && (
          <ClickOutsideListener
            targetRef={dropdownRef}
            onClick={handleOutsideClick}
          >
            <Dropdown ref={dropdownRef} items={items} />
          </ClickOutsideListener>
        )}
        <UnstableConnectionModal
          active={unstableConnectionModal.active}
          onClose={unstableConnectionModal.close}
          userType={userType}
          isSp
        />
        <ReloadModal active={reloadModal.active} onCancel={reloadModal.close} />
        {changeDeviceProps && (
          <ChangeDeviceModal
            active={changeDeviceModal.active}
            onClose={changeDeviceModal.close}
            changeDeviceProps={changeDeviceProps}
          />
        )}
      </AdditionalMenuIconWrapper>
    </Wrapper>
  )
}

export const controlsWidth = 80
const Wrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  width: ${controlsWidth}px;
  background: ${theme.color.gray[5]};
  border-left: 1px solid ${theme.color.gray[3]};
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const iconSize = css`
  height: 18px;
  width: 17px;
`
const Icon = styled(_Icon)`
  ${iconSize}
`

const buttonBase = css`
  padding: 4.5px 0 0 !important;
  height: 36px;
  width: 70px;
  font-size: 9px !important;
`
const ButtonGroup = styled.div`
  ${Wrapper} & {
    margin-top: 8px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const ButtonWrapper = styled.div`
  ${ButtonGroup} &:not(:first-child) {
    margin-top: 10px;
  }
`
const UserButtonWrapper = styled(ButtonWrapper)`
  position: relative;
`
const UserButtonCount = styled.div`
  ${UserButtonWrapper} & {
    position: absolute;
    top: -4px;
    right: -5px;
  }
  width: 16px;
  height: 16px;
  background: ${theme.color.navy[1]};
  border: 1px solid ${theme.color.white[1]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ActiveUserButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`
const UserButton = styled(TertiaryButton)`
  ${buttonBase}
`

const ChatButtonWrapper = styled(ButtonWrapper)`
  position: relative;
`
const ChatButton = styled(TertiaryButton)`
  ${buttonBase}
`
const ActiveChatButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`
const RedCircle = styled(_RedCircle)`
  ${ChatButtonWrapper} & {
    position: absolute;
    top: 17px;
    right: -4px;
  }
`
const MuteButton = styled(TertiaryButton)`
  ${buttonBase}
`
const ActiveMuteButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
`
const BackButton = styled(TertiaryButton)`
  ${buttonBase}
`
const FinishButton = styled(SecondaryDangerButton)`
  ${buttonBase}
`
const AdditionalMenuIconWrapper = styled.div`
  ${Wrapper} & {
    margin-top: auto;
  }
  position: relative;
`
const AdditionalMenuIconButton = styled(Button)`
  ${buttonResetStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
`
const Dropdown = styled(_Dropdown)`
  ${AdditionalMenuIconWrapper} & {
    position: absolute;
    bottom: -16px;
    right: 40px;
  }
`
const ReloadButtonWrapper = styled.div`
  position: relative;
`
const ReloadButton = styled(TertiaryButton)`
  ${buttonBase}
`
