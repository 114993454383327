import { useCallback } from 'react'
import {
  GetInterviewerInterviewRequest,
  GetIntervieweeInterviewRequest,
  VerifyOrganizationTokenRequest,
  ListChatMessagesRequest,
  StartInterviewRequest,
  FinishInterviewRequest,
  CreateInterviewSessionRequest,
  CreateDemoInvitationRequest,
  DeleteDemoSessionTokenRequest,
  GetInterviewerInterviewResponse,
  GetIntervieweeInterviewResponse,
  GetIntervieweeInterviewVideoQualityRequest,
  GetIntervieweeInterviewVideoQualityResponse,
  GetInterviewerInterviewVideoQualityRequest,
  GetInterviewerInterviewVideoQualityResponse,
  GetOrganizationRequest,
  GetOrganizationResponse,
  GetInterviewerSignalingPointsRequest,
  GetIntervieweeSignalingPointsRequest,
} from '@blue-agency/proton/web/v2/snoke_bff/snoke_bff_service_pb'
import { SnokeBffServicePromiseClient } from '@blue-agency/proton/web/v2/snoke_bff/snoke_bff_service_grpc_web_pb'
import { retry, retryForGetInterview } from './retry'
import { StatusCode } from 'grpc-web'
import { logRpcErr } from '@/logRpcErr'
import { callBff } from '@/callBff'

const hostname = process.env.REACT_APP_API_HOST
if (!hostname) throw new Error('hostname not found')
const client = new SnokeBffServicePromiseClient(hostname)

if (process.env.REACT_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
  // @ts-ignore
  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
  enableDevTools([client])
}

export const useBff = () => {
  const getInterviewerInterview = useCallback(
    async (interviewerToken: string) => {
      const req = new GetInterviewerInterviewRequest()
      req.setInterviewerToken(interviewerToken)
      let res: GetInterviewerInterviewResponse
      try {
        res = await retryForGetInterview(() =>
          client.getInterviewerInterview(req)
        )
      } catch (err) {
        // FIXME: FAILED_PRECONDITIONの時に面接が終了しているものとみなすのをやめる
        // https://github.com/blue-agency/snoke-front/pull/75
        if (err.code === StatusCode.FAILED_PRECONDITION) {
          return {
            channelId: 'dummy',
            videoInterviewGuid: 'dummy',
            status: GetInterviewerInterviewResponse.Status.FINISHED,
            startedAt: new Date(),
            chatRoomGuid: 'dummy',
            currentTime: new Date(),
            mainSignalingPoint: {
              channelId: 'dummy',
              webrtcHost: 'dummy',
            },
            screenSharingSignalingPoint: {
              channelId: 'dummy',
              webrtcHost: 'dummy',
            },
          }
        }
        logRpcErr('getInterviewerInterview', err)
        // MEMO: errをthrowする箇所が同じだと、sentryに同じissueだと認識されるので
        // endpoint毎に異なる箇所でエラーをthrowしている
        throw err
      }
      return {
        videoInterviewGuid: res.getVideoInterviewGuid(),
        status: res.getStatus(),
        startedAt: res.getStartedAt()?.toDate(),
        chatRoomGuid: res.getChatRoomGuid(),
        currentTime: res.getCurrentTime()!.toDate(),
        mainSignalingPoint: res.getMainSignalingPoint()!.toObject(),
        screenSharingSignalingPoint: res
          .getScreenSharingSignalingPoint()!
          .toObject(),
      }
    },
    []
  )

  const getIntervieweeInterview = useCallback(
    async (intervieweeToken: string) => {
      const req = new GetIntervieweeInterviewRequest()
      req.setIntervieweeToken(intervieweeToken)
      let res: GetIntervieweeInterviewResponse
      try {
        res = await retryForGetInterview(() =>
          client.getIntervieweeInterview(req)
        )
      } catch (err) {
        // FIXME: FAILED_PRECONDITIONの時に面接が終了しているものとみなすのをやめる
        // https://github.com/blue-agency/snoke-front/pull/75
        if (err.code === StatusCode.FAILED_PRECONDITION) {
          return {
            channelId: 'dummy',
            videoInterviewGuid: 'dummy',
            status: GetIntervieweeInterviewResponse.Status.FINISHED,
            startedAt: new Date(),
            chatRoomGuid: 'dummy',
            currentTime: new Date(),
            mainSignalingPoint: {
              channelId: 'dummy',
              webrtcHost: 'dummy',
            },
            screenSharingSignalingPoint: {
              channelId: 'dummy',
              webrtcHost: 'dummy',
            },
          }
        }
        logRpcErr('getIntervieweeInterview', err)
        throw err
      }
      return {
        videoInterviewGuid: res.getVideoInterviewGuid(),
        status: res.getStatus(),
        startedAt: res.getStartedAt()?.toDate(),
        chatRoomGuid: res.getChatRoomGuid(),
        currentTime: res.getCurrentTime()!.toDate(),
        mainSignalingPoint: res.getMainSignalingPoint()!.toObject(),
        screenSharingSignalingPoint: res
          .getScreenSharingSignalingPoint()!
          .toObject(),
      }
    },
    []
  )

  const verifyOrganizationToken = useCallback(
    async (organizationToken: string) => {
      const req = new VerifyOrganizationTokenRequest()
      req.setToken(organizationToken)
      await retry(() => client.verifyOrganizationToken(req)).catch((err) => {
        logRpcErr('verifyOrganizationToken', err)
        throw err
      })
    },
    []
  )

  const getOrganization = useCallback(
    async (metadata: { authorization: string }) => {
      const req = new GetOrganizationRequest()
      const res: GetOrganizationResponse = await callBff('getOrganization', [
        req,
        metadata,
      ])
      return res.toObject()
    },
    []
  )

  const createInterviewSession = useCallback(async () => {
    const req = new CreateInterviewSessionRequest()
    const res = await retry(() => client.createInterviewSession(req)).catch(
      (err) => {
        logRpcErr('createInterviewSession', err)
        throw err
      }
    )
    return { sessionToken: res.getSessionToken() }
  }, [])

  const listChatMessages = useCallback(async (chatRoomGuid: string) => {
    const req = new ListChatMessagesRequest()
    req.setChatRoomGuid(chatRoomGuid)
    const res = await retry(() => client.listChatMessages(req)).catch((err) => {
      logRpcErr('listChatMessages', err)
      throw err
    })
    return {
      chatMessages: res.getMessagesList(),
    }
  }, [])

  const startInterview = useCallback(async (videoInterviewGuid: string) => {
    const req = new StartInterviewRequest()
    req.setVideoInterviewGuid(videoInterviewGuid)
    const res = await retry(() => client.startInterview(req)).catch((err) => {
      logRpcErr('startInterview', err)
      throw err
    })
    return {
      startedAt: res.getStartedAt()!.toDate(),
      currentTime: res.getCurrentTime()!.toDate(),
    }
  }, [])

  const finishInterview = useCallback(async (videoInterviewGuid: string) => {
    const req = new FinishInterviewRequest()
    req.setVideoInterviewGuid(videoInterviewGuid)
    await retry(() => client.finishInterview(req)).catch((err) => {
      logRpcErr('finishInterview', err)
      throw err
    })
  }, [])

  const createDemoInvitation = useCallback(async () => {
    const req = new CreateDemoInvitationRequest()
    const res = await retry(() => client.createDemoInvitation(req)).catch(
      (err) => {
        logRpcErr('createDemoInvitation', err)
        throw err
      }
    )
    return {
      channelId: res.getChannelId(),
      sessionToken: res.getSessionToken(),
      webrtcHost: res.getWebrtcHost(),
    }
  }, [])

  const deleteDemoSessionToken = useCallback(async (sessionToken: string) => {
    const req = new DeleteDemoSessionTokenRequest()
    req.setSessionToken(sessionToken)
    await retry(() => client.deleteDemoSessionToken(req)).catch((err) => {
      logRpcErr('deleteDemoSessionToken', err)
      throw err
    })
  }, [])

  const getIntervieweeInterviewVideoQuality = useCallback(
    async (intervieweeToken: string) => {
      const req = new GetIntervieweeInterviewVideoQualityRequest()
      req.setIntervieweeToken(intervieweeToken)
      let res: GetIntervieweeInterviewVideoQualityResponse
      try {
        res = await retryForGetInterview(() =>
          client.getIntervieweeInterviewVideoQuality(req)
        )
      } catch (err) {
        if (err.code === StatusCode.FAILED_PRECONDITION) {
          return null
        }
        logRpcErr('getIntervieweeInterviewVideoQuality', err)
        throw err
      }
      return {
        mode: res.getMode(),
        videoBitRate: res.getVideoBitRate(),
        videoFrameRate: res.getVideoFrameRate(),
      }
    },
    []
  )

  const getInterviewerInterviewVideoQuality = useCallback(
    async (interviewerToken: string) => {
      const req = new GetInterviewerInterviewVideoQualityRequest()
      req.setInterviewerToken(interviewerToken)
      let res: GetInterviewerInterviewVideoQualityResponse
      try {
        res = await retryForGetInterview(() =>
          client.getInterviewerInterviewVideoQuality(req)
        )
      } catch (err) {
        if (err.code === StatusCode.FAILED_PRECONDITION) {
          return null
        }
        logRpcErr('getInterviewerInterviewVideoQuality', err)
        throw err
      }
      return {
        mode: res.getMode(),
        videoBitRate: res.getVideoBitRate(),
        videoFrameRate: res.getVideoFrameRate(),
      }
    },
    []
  )

  const getInterviewerSignalingPoints = useCallback(
    async (interviewerToken: string) => {
      const req = new GetInterviewerSignalingPointsRequest()
      req.setInterviewerToken(interviewerToken)
      const res = await retry(() =>
        client.getInterviewerSignalingPoints(req)
      ).catch((err) => {
        logRpcErr('getInterviewerSignalingPoints', err)
        throw err
      })
      return {
        main: res.getMain()!.toObject(),
        screenSharing: res.getScreenSharing()!.toObject(),
      }
    },
    []
  )

  const getIntervieweeSignalingPoints = useCallback(
    async (intervieweeToken: string) => {
      const req = new GetIntervieweeSignalingPointsRequest()
      req.setIntervieweeToken(intervieweeToken)
      const res = await retry(() =>
        client.getIntervieweeSignalingPoints(req)
      ).catch((err) => {
        logRpcErr('getIntervieweeSignalingPoints', err)
        throw err
      })
      return {
        main: res.getMain()!.toObject(),
        screenSharing: res.getScreenSharing()!.toObject(),
      }
    },
    []
  )

  const cacheKey = {
    getInterviewerInterview: 'getInterviewerInterview',
    getIntervieweeInterview: 'getIntervieweeInterview',
    listChatMessages: 'listChatMessages',
    createInterviewSession: 'createInterviewSession',
    createDemoInvitation: 'createDemoInvitation',
    getInterviewerInterviewVideoQuality: 'getInterviewerInterviewVideoQuality',
    getIntervieweeInterviewVideoQuality: 'getIntervieweeInterviewVideoQuality',
    getInterviewerSignalingPoints: 'getInterviewerSignalingPoints',
    getIntervieweeSignalingPoints: 'getIntervieweeSignalingPoints',
    getOrganization: 'getOrganization',
  }

  return {
    getInterviewerInterview,
    getIntervieweeInterview,
    createInterviewSession,
    listChatMessages,
    startInterview,
    finishInterview,
    createDemoInvitation,
    deleteDemoSessionToken,
    verifyOrganizationToken,
    getIntervieweeInterviewVideoQuality,
    getInterviewerInterviewVideoQuality,
    getOrganization,
    getInterviewerSignalingPoints,
    getIntervieweeSignalingPoints,
    cacheKey,
  }
}
