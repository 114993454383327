import { useEffect } from 'react'
import * as ImInterview from '@blue-agency/react-im-interview'
import { useCaptureException } from './useCaptureException'

export const useIMInterview: typeof ImInterview.useIMInterview = (...args) => {
  const captureException = useCaptureException()
  const response = ImInterview.useIMInterview(...args)

  useEffect(() => {
    if (response.initializeMediaDevicesError) {
      captureException(response.initializeMediaDevicesError)
    }
  }, [response.initializeMediaDevicesError, captureException])

  return response
}
