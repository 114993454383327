import { SoraError } from '@blue-agency/react-im-interview'
import { captureException } from '@sentry/react'
import { useEffect, useMemo } from 'react'

export const REASON_ALREADY_INVITED_AS_ANOTHER_ROLE =
  'Already invited as another role'
export const REASON_PARTICIPANTS_NUM_LIMIT =
  'The number of participants exceeds the upper limit'

// sentryの例外ハンドリング
export const useSoraCaptureException = (err: SoraError | null) => {
  useEffect(() => {
    if (!err) return
    switch (err.reason) {
      case REASON_ALREADY_INVITED_AS_ANOTHER_ROLE:
        break
      case REASON_PARTICIPANTS_NUM_LIMIT:
        break
      // TODO: スルーしてよいエラーがあれば追加していく
      default:
        captureException(err, { extra: { reason: err.reason } })
    }
  }, [err])
}

/**
 * interviewee(応募者)用
 */
export const useIntervieweeSoraErrorMessage = (err: SoraError | null) => {
  const errorMessage = useMemo(() => {
    if (!err) return null
    switch (err.reason) {
      case REASON_ALREADY_INVITED_AS_ANOTHER_ROLE:
        return 'すでに面接官として入室済です。\n面接官用のURLから入り直してください。'
      case REASON_PARTICIPANTS_NUM_LIMIT:
        return '参加人数が上限を超えているため、参加することができませんでした。\n人事担当者様にお問い合わせください。'
      default:
        return '認証エラーが発生しました。\n画面を再読込（リロード）してください。'
    }
  }, [err])
  return errorMessage
}

/**
 * interviewer(面接官)用
 */
export const useInterviewerSoraErrorMessage = (err: SoraError | null) => {
  const errorMessage = useMemo(() => {
    if (!err) return null
    switch (err.reason) {
      case REASON_ALREADY_INVITED_AS_ANOTHER_ROLE:
        return 'すでに応募者として入室済です。\n応募者用のURLから入り直してください。'
      case REASON_PARTICIPANTS_NUM_LIMIT:
        return '参加人数の上限を超えているため、\n面接に参加できません。\n社内の人事担当者様にお問い合わせください。'
      default:
        return '認証エラーが発生しました。\n画面を再読込（リロード）してください。'
    }
  }, [err])
  return errorMessage
}
