import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { path, fillParams } from '@/path'
import {
  Modal,
  DangerButton as _DangerButton,
  TertiaryButton as _TertiaryButton,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  active: boolean
  onClose: () => void
}

export const CancelModal: React.FCX<Props> = (props) => {
  const { organizationToken } = useParams<{ organizationToken?: string }>()
  if (!organizationToken) throw new Error('Not found organizationToken')

  const history = useHistory()
  const handleFinish = useCallback(() => {
    history.push(
      fillParams({
        path: path.organization.setting.timeGuide.index,
        params: { organizationToken },
      })
    )
  }, [history, organizationToken])
  return (
    <Modal
      active={props.active}
      onClose={props.onClose}
      title="タイムガイドの作成終了"
      size="m"
    >
      <Wrapper>
        <Upper>
          <Txt size="s">
            変更内容は保存されません。作成を終了しますか？
            <br />
            内容を保存する場合は、「キャンセル」ボタンをクリックし、保存してください。
          </Txt>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <CancelButton
            onClick={props.onClose}
            comlinkPushParams={{ action: 'cancel_finish_modal' }}
          >
            キャンセル
          </CancelButton>
          <FinishButton
            onClick={handleFinish}
            comlinkPushParams={{ action: 'finish_create_timeguide' }}
          >
            終了
          </FinishButton>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Upper = styled.div`
  padding: 20px 20px 25px;
  width: 100%;
`

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

const CancelButton = styled(_TertiaryButton).attrs({ size: 'l2' })`
  margin-right: 20px;
`

const FinishButton = styled(_DangerButton).attrs({ size: 'l2' })``
