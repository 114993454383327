import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  PrimaryButton,
  theme,
  Txt,
  NewTabLink,
  HelpLinkIcon,
} from '@blue-agency/rogue'
import { href } from '@/href'
import { RoomPageContainer } from '../RoomPageContainer'
import { BrowserContainer } from '@/hooks/BrowserContainer'
import { RecommendPc as _RecommendPc } from '@/components/interviewPreparing/RecommendPc'
import { Layout } from '@/components/Layout'

export const Initial: React.FCX = ({ className }) => {
  const { setRoomStatus } = RoomPageContainer.useContainer()
  const { isIOS } = BrowserContainer.useContainer()
  const toEnvironmentCheck = useCallback(
    () => setRoomStatus('environmentCheck'),
    [setRoomStatus]
  )

  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={href.interviewee.help.interview}
          action="open_interviewee_interview_help_page"
        />
      }
    >
      <Wrapper>
        <WrapperInner className={className}>
          <MainTxt>
            ご利用される端末やブラウザ、通信環境によっては
            <br />
            正常に動作しない可能性がございます。
            <br />
            開始の当日までに必ず推奨環境をご確認の上
            <br />
            動作環境テストを実施してください。
          </MainTxt>
          <SupportEnvironmentTableWrapper>
            <SupportEnvironmentTableTitle>PC</SupportEnvironmentTableTitle>
            <Table>
              <TableHead>
                <tr>
                  <FirstTh>ご利用端末</FirstTh>
                  <SecondTh>OS</SecondTh>
                  <ThirdTh>ブラウザ</ThirdTh>
                </tr>
              </TableHead>
              <tbody>
                <tr>
                  <Td>Windows</Td>
                  <Td>Windows10以上の最新版</Td>
                  <Td>
                    Google Chrome, Microsoft Edge
                    <br />
                    上記の最新版
                  </Td>
                </tr>
                <tr>
                  <Td>Mac</Td>
                  <Td>macOS Mojave(10.14.5)以上の最新版</Td>
                  <Td>
                    Google Chrome, Safari
                    <br />
                    上記の最新版
                  </Td>
                </tr>
              </tbody>
            </Table>
            <SupportEnvironmentTableTitle>
              スマートフォン
            </SupportEnvironmentTableTitle>
            <Table>
              <TableHead>
                <tr>
                  <FirstTh>ご利用端末</FirstTh>
                  <SecondTh>OS</SecondTh>
                  <ThirdTh>ブラウザ</ThirdTh>
                </tr>
              </TableHead>
              <tbody>
                <tr>
                  <Td>iPhone 8 以降</Td>
                  <Td>iOSの最新版</Td>
                  <Td>Safariの最新版</Td>
                </tr>
                <tr>
                  <Td>Android</Td>
                  <Td>Android 7以降</Td>
                  <Td>
                    Google Chrome
                    <br />
                    の最新版
                  </Td>
                </tr>
              </tbody>
            </Table>
          </SupportEnvironmentTableWrapper>
          {isIOS && <RecommendPc />}
          <HelpGuideTxtWrapper>
            <Txt size="s">
              その他、ご利用される環境や注意事項については、
              <br />
              <NewTabLink
                href={href.interviewee.help.interview}
                action="open_interviewee_interview_help_page"
              >
                ヘルプページ
              </NewTabLink>
              をご確認ください
            </Txt>
          </HelpGuideTxtWrapper>
        </WrapperInner>
      </Wrapper>
      <FixedFooter>
        <SubTxt size="s">
          <NewTabLink
            href={href.participantTerms}
            action="open_interviewee_terms_page"
          >
            利用規約及びプライバシーポリシー
          </NewTabLink>
          に
        </SubTxt>
        <TestButton
          text="同意して動作環境テストをする"
          size="ll"
          onClick={toEnvironmentCheck}
          comlinkPushParams={{ action: 'to_environment_check_page' }}
        />
      </FixedFooter>
    </Layout>
  )
}

const footerHeight = '98px'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: calc(327px + 20px * 2);

  ${Wrapper} & {
    margin-bottom: ${footerHeight};
    width: auto;
  }
`
const MainTxt = styled(Txt)`
  font-size: ${theme.fontSize.s};
  text-align: center;

  @media (min-width: 500px) {
    font-size: ${theme.fontSize.m};
    text-align: left;
  }

  ${WrapperInner} & {
    margin-top: 20px;
    width: 100%;
  }
`
const SupportEnvironmentTableWrapper = styled.div`
  ${WrapperInner} & {
    margin-top: 20px;
    width: 100%;
    max-width: 327px;
  }
`
const SupportEnvironmentTableTitle = styled.p`
  font-size: ${theme.fontSize.m};

  ${SupportEnvironmentTableWrapper} &:not(:first-of-type) {
    margin-top: 20px;
  }
`
const Table = styled.table`
  border-collapse: collapse;

  ${SupportEnvironmentTableWrapper} & {
    margin-top: 10px;
  }
`
const TableHead = styled.thead`
  background-color: ${theme.color.gray[4]};
`
const Th = styled.th`
  background-color: ${theme.color.gray[4]};
  border: 1px solid ${theme.color.gray[3]};
  font-size: ${theme.fontSize.s};
  font-weight: normal;
  padding: 8px 4px;
  text-align: left;
`
const FirstTh = styled(Th)`
  width: 0.2%;
`
const SecondTh = styled(Th)`
  width: 0.294%;
`
const ThirdTh = styled(Th)`
  width: 0.506%;
`
const Td = styled.td`
  border: 1px solid ${theme.color.gray[3]};
  word-break: break-word;
  font-size: ${theme.fontSize.s};
  line-height: 1.5;
  padding: 6px;
  vertical-align: middle;
`
const HelpGuideTxtWrapper = styled.div`
  text-align: center;

  @media (min-width: 500px) {
    text-align: left;
  }

  ${WrapperInner} & {
    margin-top: 18px;
    width: 100%;
  }
`
const SubTxt = styled(Txt)`
  ${WrapperInner} & {
    margin-top: 20px;
    width: 100%;
  }
`
const RecommendPc = styled(_RecommendPc)`
  ${WrapperInner} & {
    margin-top: 20px;
  }
`
const FixedFooter = styled.div`
  display: flex;
  height: ${footerHeight};
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${theme.color.white[1]};
  border-top: 1px solid ${theme.color.gray[4]};
  padding: 12px 20px;
`
const TestButton = styled(PrimaryButton)`
  height: 44px;

  ${FixedFooter} & {
    margin-top: 8px;
    max-width: 328px;
    width: 100%;
  }
`
