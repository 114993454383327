import { useCachedPromise } from '@blue-agency/front-state-management'
import { useCallback } from 'react'
import { BffContainer } from '@/hooks/BffContainer'

export const useSessionToken = () => {
  const bff = BffContainer.useContainer()
  const getSessionToken = useCallback(async (localStorageKey: string) => {
    const sessionTokenInLocalStorage = localStorage.getItem(localStorageKey)
    if (sessionTokenInLocalStorage) return sessionTokenInLocalStorage
    const res = await bff.createInterviewSession()
    localStorage.setItem(localStorageKey, res.sessionToken)
    return res.sessionToken
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sessionToken = useCachedPromise(
    bff.cacheKey.createInterviewSession,
    async () => {
      const [
        userSessionToken,
        screenSharingSessionToken,
        screenSharingRecvSessionToken,
      ] = await Promise.all([
        getSessionToken('interviewUserSessionToken'),
        getSessionToken('interviewScreenSharingSessionToken'),
        getSessionToken('interviewScreenSharingRecvSessionToken'),
      ])
      return {
        user: userSessionToken,
        screenSharing: screenSharingSessionToken,
        screenSharingRecv: screenSharingRecvSessionToken,
      }
    }
  )

  return { sessionToken }
}
