import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { Layout } from '@/components/Layout'
import { Txt, theme, A, HelpLinkIcon, Icon } from '@blue-agency/rogue'
import { href } from '@/href'
import { useTitle } from '@/hooks/useTitle'
import { useParams } from 'react-router-dom'

const comlinkHost = process.env.REACT_APP_COMLINK_HOST
if (!comlinkHost) throw new Error('comlinkHost not found')

export const FinishPage: React.FCX = ({ className }) => {
  const { interviewerToken } = useParams<{ interviewerToken?: string }>()
  if (!interviewerToken) throw new Error('interviewerToken not found')

  const [starRating, setStarRating] = useState(0)
  useTitle('終了ページ')

  const openSurveyLink = useCallback(async () => {
    const res = await fetch(`${comlinkHost}/uuid`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
    const json = await res.json()
    const params = `${interviewerToken}+${json.clid}+imapi_interview`
    window.location.href = href.interviewer.survey + params
  }, [interviewerToken])

  const handleClickStar = useCallback(
    (rating: number) => () => {
      setStarRating(rating)
      openSurveyLink()
    },
    [openSurveyLink]
  )

  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={href.interviewer.help.interview.top}
          action="open_interviewer_interview_help_page"
        />
      }
    >
      <Wrapper>
        <Txt>お疲れ様でした。面接は終了しました。</Txt>
        <Card>
          <Txt size="l" bold color={theme.color.white[1]}>
            Web面接体験はいかがでしたか？
          </Txt>
          <SubTxt size="s" color={theme.color.white[1]}>
            サービス品質向上のため、ご意見をお聞かせください。
          </SubTxt>
          <Estimation>
            <StarGroup>
              {[...Array(5)].map((_, index) => (
                <A
                  key={index}
                  onClick={handleClickStar(index + 1)}
                  comlinkPushParams={{
                    action: 'open_interviewer_survey_page',
                    metadata: {
                      starRating: index + 1 + '',
                    },
                  }}
                >
                  {starRating < index + 1 ? (
                    <EmptyStar name="star" />
                  ) : (
                    <FilledStar name="filled-star" />
                  )}
                </A>
              ))}
            </StarGroup>
            <TxtGroup>
              <Txt size="s" color={theme.color.white[1]}>
                とても悪い
              </Txt>
              <Txt size="s" color={theme.color.white[1]}>
                とても良い
              </Txt>
            </TxtGroup>
          </Estimation>
        </Card>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px 20px;
`
const Card = styled.div`
  ${Wrapper} & {
    margin-top: 54px;
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  padding: 24px;
  opacity: 0.85;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: ${theme.color.white[1]};
  background-color: ${theme.color.green[3]};
`
const SubTxt = styled(Txt)`
  ${Card} & {
    margin-top: 3px;
  }
`
const Estimation = styled.div`
  ${Wrapper} & {
    margin-top: 30px;
  }
`
const StarGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 240px;
`
const iconStyle = css`
  color: ${theme.color.white[1]};
  padding: 3px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  transition: background-color 200ms 0s ease;
  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`
const EmptyStar = styled(Icon)`
  ${iconStyle}
`
const FilledStar = styled(Icon)`
  ${iconStyle}
`
const TxtGroup = styled.div`
  ${Estimation} & {
    margin-top: 6px;
  }
  display: flex;
  justify-content: space-between;
`
