import React from 'react'
import ReactDOM from 'react-dom'
import styled, { css, keyframes } from 'styled-components'
import { theme, Txt as _Txt, Icon } from '@blue-agency/rogue'
import { ToastProps } from '.'

const fadeOut = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
})
const animationDuration = 300
const Wrapper = styled.div<{ duration: number }>`
  z-index: 2;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: ${theme.color.navy[1]}E6;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
  animation: ${fadeOut} ${animationDuration}ms forwards;
  ${({ duration }) => css`
    animation-delay: ${duration - animationDuration}ms;
  `}
`
const CloseIconWrapper = styled.div`
  cursor: pointer;
  color: ${theme.color.white[1]};
  position: absolute;
  top: 12px;
  right: 12px;
`

export const SpToast: React.FC<ToastProps> = ({ text, duration, onClose }) =>
  ReactDOM.createPortal(
    <Wrapper duration={duration}>
      <Txt size="s" color={theme.color.white[1]}>
        {text}
      </Txt>
      <CloseIconWrapper onClick={onClose}>
        <Icon name="close" />
      </CloseIconWrapper>
    </Wrapper>,
    document.getElementById('root') as HTMLElement
  )

const Txt = styled(_Txt)`
  text-align: center;
`
