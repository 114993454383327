import React from 'react'
import { TimerProps } from '@blue-agency/rogue'
import { Pc } from './Pc'
import { SpPortrait } from './SpPortrait'
import { SpLandscape } from './SpLandscape'
import { InterviewMenuContext } from '@/hooks/useInterviewMenu'
import { WindowSize } from '@blue-agency/react-utils/dist/useWindowSize'

export type InterviewControlsProps = {
  userType: 'interviewer' | 'interviewee'
  deviceType: DeviceType
  status: InterviewStatus
  windowSize: WindowSize
  muteButtonProps: ButtonProps
  cameraOffButtonProps: ButtonProps
  chatButtonProps: ButtonProps & { unread?: boolean }
  userButtonProps: ButtonProps & { count?: number }
  backButtonProps?: ButtonProps
  finishButtonProps?: ButtonProps
  screenShareButtonProps: ButtonProps
  timerProps?: TimerProps
  menuProps: InterviewMenuContext
  changeDeviceProps?: ChangeDeviceProps
}
export type InterviewStatus = 'notStarted' | 'started'
export type DeviceType = 'pc' | 'spPortrait' | 'spLandscape'
type ButtonProps = {
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

export type ChangeDeviceProps = {
  cameraDevices: readonly MediaDeviceInfo[]
  selectedCameraDeviceId: string
  onChangeCameraDevice: (deviceId: string) => void
  micDevices: readonly MediaDeviceInfo[]
  selectedMicDeviceId: string
  onChangeMicDevice: (deviceId: string) => void
  audioWaveHeights: number[]
  muted: boolean
  // スピーカー選択に未対応のブラウザのときは、以下2つが undefined になる
  speakerDevices: readonly MediaDeviceInfo[] | undefined
  selectedSpeakerDeviceId: string | undefined
  onChangeSpeakerDevice: (deviceId: string) => void
  stream: MediaStream | undefined
}

export const InterviewControls: React.FCX<InterviewControlsProps> = (props) => {
  switch (props.deviceType) {
    case 'pc':
      return <Pc {...props} />
    case 'spPortrait':
      return <SpPortrait {...props} />
    case 'spLandscape':
      return <SpLandscape {...props} />
  }
}
