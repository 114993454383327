import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'

type Props = {
  title: string
  children: ReactNode
}

export const SettingContent: React.VFC<Props> = ({ title, children }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 960px;
  margin: auto;
  background-color: ${theme.color.white[1]};
`

const Title = styled.h2`
  background: ${theme.color.navy[3]};
  color: ${theme.color.navy[1]};
  padding-left: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: normal;
`
