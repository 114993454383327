import React from 'react'
import styled from 'styled-components'
import { SpStartModal as _SpStartModal } from './SpStartModal'
import {
  Modal,
  PrimaryButton,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import { FinishModal } from './FinishModal'
import { ExitModal } from './ExitModal'
import { StartScreenShareModal } from '@/components/StartScreenShareModal'
import { FinishScreenShareModal } from '@/components/FinishScreenShareModal'
import { EnteredContainer } from '../hooks/EnteredContainer'
import { InterviewMainAreaLayout } from '@/components/InterviewMainAreaLayout'
import { DeviceType } from '@/components/InterviewControls'
import { VideoArea } from './VideoArea'
import { UsersArea } from './UsersArea'
import { ScreenSharingLimitModal } from '@/components/ScreenSharingLimitModal'
import { ChatArea } from './ChatArea'
import { FixedControls } from '@/components/InterviewControls/FixedControls'
import { useInterviewLayout } from '../hooks/useInterviewLayout'
import { EconomyModeTxt } from '@/components/EconomyModeTxt'

type Props = {
  height: number
  width: number
  deviceType: DeviceType
}

export const MainArea: React.FCX<Props> = ({
  className,
  width,
  height,
  deviceType,
}) => {
  const {
    handleStart,
    errorMessage,
    showChat,
    showUsers,
    finishModal,
    startScreenShareModal,
    finishScreenShareModal,
    screenSharingLimitModal,
    chatParams,
    handleScreenShareStart,
    handleScreenShareFinish,
    exitModal,
    showStartModal,
    isEconomyMode,
    imInterview,
  } = EnteredContainer.useContainer()
  const { interviewControlsProps } = useInterviewLayout()
  const {
    userType,
    status,
    muteButtonProps,
    cameraOffButtonProps,
    backButtonProps,
    finishButtonProps,
  } = interviewControlsProps

  const isSp = interviewControlsProps.deviceType !== 'pc'

  return (
    <Wrapper className={className}>
      <InterviewMainAreaLayout
        showUsersArea={showUsers}
        showChatArea={showChat}
        deviceType={deviceType}
        width={width}
        height={height}
        videoArea={
          showStartModal ? (
            // NOTE: 入室解除モーダル。SPの場合はメインエリアに表示する必要がある。
            isSp && <SpStartModal onClick={handleStart} />
          ) : (
            <VideoArea />
          )
        }
        usersArea={<UsersArea deviceType={deviceType} />}
        chatArea={<ChatArea chatParams={chatParams} />}
        controlsArea={
          <FixedControls
            userType={userType}
            status={status}
            muteButtonProps={muteButtonProps}
            cameraOffButtonProps={cameraOffButtonProps}
            backButtonProps={backButtonProps}
            finishButtonProps={finishButtonProps}
          />
        }
      />
      {showStartModal && !isSp && (
        <>
          <PcStartMessage>
            <Txt size="l">現在は面接官のみ入室できます</Txt>
            <Txt>準備ができたら応募者の入室制限を解除してください</Txt>
          </PcStartMessage>
          <PcStartModal>
            <ModalWrapper>
              <SubTxts>
                <SubTxt>
                  面接官全員の準備ができたことを確認し
                  <br />
                  入室制限を解除してください。
                </SubTxt>
                <SubTxt>制限解除の取り消しはできません。</SubTxt>
              </SubTxts>
              <Separator />
              <StartButton
                size="l1"
                text="入室制限を解除"
                onClick={handleStart}
                comlinkPushParams={{ action: 'start_interview' }}
              />
            </ModalWrapper>
          </PcStartModal>
        </>
      )}
      <ExitModal active={exitModal.active} leaveRoom={imInterview.leaveRoom} />
      <FinishModal active={finishModal.active} />
      <StartScreenShareModal
        active={startScreenShareModal.active}
        onClose={startScreenShareModal.close}
        onStart={handleScreenShareStart}
      />
      <FinishScreenShareModal
        active={finishScreenShareModal.active}
        onClose={finishScreenShareModal.close}
        onFinish={handleScreenShareFinish}
      />
      <ScreenSharingLimitModal
        active={screenSharingLimitModal.active}
        onClose={screenSharingLimitModal.close}
      />
      {errorMessage && (
        <ErrorMessageWrapper maxWidth={width - 10}>
          <Txt color={theme.color.red[2]}>{errorMessage}</Txt>
        </ErrorMessageWrapper>
      )}
      {isEconomyMode && <EconomyModeTxtWrapper />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const PcStartModal = styled(Modal).attrs({
  active: true,
  noOverlay: true,
  size: 's',
  title: '応募者の入室制限を解除',
})`
  position: absolute;
  top: 20%;
  left: 5%;
`
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
`
const SubTxts = styled.ul`
  font-size: ${theme.fontSize.m};
  padding: 0 20px;
  margin-left: 20px;
`
const SubTxt = styled.li`
  line-height: 1.5;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 20px;
`
const StartButton = styled(PrimaryButton)`
  margin-top: 20px;
`
const PcStartMessage = styled.div`
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 433px;
  height: 90px;
  background-color: ${theme.color.white[1]};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
const SpStartModal = styled(_SpStartModal)`
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  margin: 0 auto;
`
const ErrorMessageWrapper = styled.div<{ maxWidth: number }>`
  ${Wrapper} & {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 20px;
  }
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 360px;
  padding: 10px;
  background: ${theme.color.white[1]};
  text-align: center;
`

const EconomyModeTxtWrapper = styled(EconomyModeTxt)`
  position: absolute;
  bottom: 0;
`
