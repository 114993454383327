import { useParams } from 'react-router-dom'
import { BffContainer } from '@/hooks/BffContainer'
import { useAsync } from 'react-use'

export const useVerifyOrganization = () => {
  const { organizationToken } = useParams<{ organizationToken?: string }>()
  if (!organizationToken) throw new Error('Not found organizationToken')
  const bff = BffContainer.useContainer()

  const isError = useAsync(async () => {
    try {
      await bff.verifyOrganizationToken(organizationToken)
    } catch (e) {
      return true
    }
    return false
  }, [])
  return { isError }
}
