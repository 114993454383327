import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { theme, Txt, Icon as _Icon, A } from '@blue-agency/rogue'
import { Layout } from '@/components/Layout'
import { path, fillParams } from '@/path'
import { SettingContent } from '@/components/SettingContent'
import { OrganizerHeaderRight } from '@/components/OrganizerHeaderRight'
import { PageHeader } from '@/components/PageHeader'
import { useTitle } from '@/hooks/useTitle'
import { useOrganization } from '../useOrganization'

export const SettingsPage = () => {
  useTitle('設定一覧')
  const { organizationToken } = useParams<{ organizationToken?: string }>()
  if (!organizationToken) throw new Error('Not found organizationToken')
  const { organization } = useOrganization()

  const settingList = [
    {
      title: 'タイムガイド設定',
      description: '面接中に表示するタイムガイドの内容を変更できます。',
      href: fillParams({
        path: path.organization.setting.timeGuide.index,
        params: { organizationToken },
      }),
      action: 'open_timeGuide_page',
    },
    {
      title: 'チェックノート設定',
      description: '？？？？？？？？？？？？？？？？？？？？？？？？？？？？？',
      href: fillParams({
        path: path.organization.setting.checkNote,
        params: { organizationToken },
      }),
      action: 'open_checkNote_page',
    },
  ]
  return (
    <Layout
      rightNode={
        <OrganizerHeaderRight
          orgName={organization.name}
          customerCode={organization.guid}
        />
      }
    >
      <PageHeader title="設定一覧" />
      <SettingContent title="一般設定">
        <SettingColumn>
          <SettingList>
            {settingList.map((list, index) => (
              <SettingItem key={index}>
                <LinkBox
                  href={list.href}
                  comlinkPushParams={{
                    action: list.action,
                  }}
                >
                  <SettingTitle>{list.title}</SettingTitle>
                  <Txt size="s">{list.description}</Txt>
                  <Icon name="arrow" size="m" />
                </LinkBox>
              </SettingItem>
            ))}
          </SettingList>
        </SettingColumn>
      </SettingContent>
    </Layout>
  )
}

const LinkBox = styled(A)`
  background: ${theme.color.white[1]};
  padding: 20px 24px 20px 20px;
  border: 1px solid ${theme.color.gray[4]};
  border-radius: 4px;
  position: relative;
  display: block;
  text-decoration: none;
  color: ${theme.color.navy[1]};
  &:hover {
    background: ${theme.color.gray[4]};
  }
`

const Icon = styled(_Icon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%) rotate(180deg);
`

const SettingTitle = styled.p`
  margin-bottom: 12px;
`

const SettingList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 20px 0;
`

const SettingColumn = styled.div`
  height: calc(100vh - 160px);
`

const SettingItem = styled.div`
  width: 450px;
  margin-bottom: 20px;
  &:nth-of-type(even) {
    margin-left: 20px;
  }
`
