import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { EnteredContainer } from './hooks/EnteredContainer'
import { MainArea } from './MainArea'
import { InterviewLayout } from '@/components/InterviewLayout'
import { useInterviewLayout } from './hooks/useInterviewLayout'
import { usePreventPinchZoom } from '@/hooks/usePreventPinchZoom'

export const Entered: React.FCX = provide(EnteredContainer)(({ className }) => {
  const { mainAreaSize, interviewControlsProps } = useInterviewLayout()
  usePreventPinchZoom()

  return (
    <InterviewLayout
      className={className}
      interviewControlsProps={interviewControlsProps}
      mainArea={
        <MainArea
          {...mainAreaSize}
          deviceType={interviewControlsProps.deviceType}
        />
      }
    />
  )
})
