import React from 'react'
import { Layout } from '@/components/Layout'
import { EnvironmentCheck as EnvironmentCheckComponent } from '@blue-agency/react-environment-check'
import { useEnvironmentCheck } from './useEnvironmentCheck'
import { href } from '@/href'
import { HelpLinkIcon } from '@blue-agency/rogue'
import { checkBrowser } from '@blue-agency/react-im-interview'

export const EnvironmentCheck: React.FCX = ({ className }) => {
  const { handleFinish, cameraTestConnectionProps } = useEnvironmentCheck()
  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={href.interviewee.help.interview}
          action="open_interviewee_interview_help_page"
        />
      }
    >
      <EnvironmentCheckComponent
        userType="interviewee"
        steps={[
          { name: 'recommendForParticipant' },
          { name: 'recommendForAll' },
          {
            name: 'browser',
            helpUrl: href.interviewee.recommendedEnvironment,
            showRecommendPc: true,
            checkBrowserFunction: checkBrowser,
          },
          {
            name: 'camera',
            helpUrl: href.interviewee.help.environmentCheck.camera,
            ...cameraTestConnectionProps,
          },
          {
            name: 'mic',
            helpUrl: href.interviewee.help.environmentCheck.mike,
          },
          {
            name: 'speaker',
            helpUrl: href.interviewee.help.environmentCheck.speaker,
          },
          {
            name: 'speed',
            helpUrl: href.interviewee.help.environmentCheck.speed,
          },
        ]}
        onFinish={handleFinish}
      />
    </Layout>
  )
}
