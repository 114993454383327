import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { AdviceRegistrationLinkButton } from './AdviceRegistrationLinkButton'
import { MAIN_COLOR } from './constants'
import { AdviceRegistrationCardProps } from './types'
export * from './types'

export const AdviceRegistrationCard: React.VFC<AdviceRegistrationCardProps> = ({
  agentRegistrationUrl,
}) => {
  return (
    <Card>
      <RequestTxt size="l" bold color={theme.color.white[1]}>
        就活生のみなさまへ
        <br />
        面接アドバイス・就活/キャリア相談
      </RequestTxt>
      <Margin mt="20px" />
      <AdviceRegistrationLinkButton
        width="80%"
        agentRegistrationUrl={agentRegistrationUrl}
      />
    </Card>
  )
}

const Card = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  opacity: 0.85;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: ${theme.color.white[1]};
  background-color: ${MAIN_COLOR};
`
const RequestTxt = styled(Txt)`
  text-align: center;
`
const Margin = styled.div<{ mt: string }>`
  margin-top: ${({ mt }) => mt};
`
