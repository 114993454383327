import asyncRetry from 'async-retry'
import { GrpcError, CustomGrpcError } from '@/CustomGrpcError'
import { StatusCode } from 'grpc-web'

const RETRY_COUNT = 4

export const retry = <T>(request: () => Promise<T>) => {
  const retrier = async (bail: (e: Error) => void) => {
    return await request().catch((err) => {
      const { shouldRetry } = checkRetryError(err)
      const customErr = new CustomGrpcError(err)
      if (!shouldRetry) {
        return bail(customErr) as never
      }
      throw customErr
    })
  }

  return asyncRetry(retrier, { retries: RETRY_COUNT })
}

const checkRetryError: (err: GrpcError) => { shouldRetry: boolean } = (err) => {
  switch (err.code) {
    case StatusCode.INVALID_ARGUMENT:
    case StatusCode.NOT_FOUND:
    case StatusCode.PERMISSION_DENIED:
    case StatusCode.UNAUTHENTICATED:
    case StatusCode.RESOURCE_EXHAUSTED:
    case StatusCode.FAILED_PRECONDITION:
      return { shouldRetry: false }
    default:
      return { shouldRetry: true }
  }
}

// FIXME: DEADLINE_EXCEEDEDの時にretryせずに面接が終了しているものとみなすのをやめる
// https://github.com/blue-agency/snoke-front/pull/75
export const retryForGetInterview = <T>(request: () => Promise<T>) => {
  const retrier = async (bail: (e: Error) => void) => {
    return await request().catch((err) => {
      const { shouldRetry } = checkRetryErrorForGetInterview(err)
      const customErr = new CustomGrpcError(err)
      if (!shouldRetry) {
        return bail(customErr) as never
      }
      throw customErr
    })
  }

  return asyncRetry(retrier, { retries: RETRY_COUNT })
}

const checkRetryErrorForGetInterview: (err: GrpcError) => {
  shouldRetry: boolean
} = (err) => {
  switch (err.code) {
    case StatusCode.INVALID_ARGUMENT:
    case StatusCode.NOT_FOUND:
    case StatusCode.PERMISSION_DENIED:
    case StatusCode.UNAUTHENTICATED:
    case StatusCode.RESOURCE_EXHAUSTED:
    case StatusCode.FAILED_PRECONDITION:
    case StatusCode.DEADLINE_EXCEEDED:
      return { shouldRetry: false }
    default:
      return { shouldRetry: true }
  }
}
