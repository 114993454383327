import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { path } from '@/path'
import { SettingRoutes } from './SettingRoutes'
import { NotFound } from '@/components/NotFound'

export const OrganizationRoutes = () => {
  return (
    <Switch>
      <Route path={path.organization.setting.index} component={SettingRoutes} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
