import * as grpcWeb from 'grpc-web'

export type GrpcError = {
  message: string
  code: number
  metadata: grpcWeb.Metadata
}

export class CustomGrpcError extends Error {
  code: number
  metadata: grpcWeb.Metadata
  constructor(err: GrpcError) {
    super(err.message)
    this.name = 'CustomGrpcError'
    this.code = err.code
    this.metadata = err.metadata
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}
