import React from 'react'
import styled, { css } from 'styled-components'
import { Modal as _Modal } from '@blue-agency/rogue'
import { StepContainer } from './hooks/useStep'
import { provide } from '@blue-agency/front-state-management'
import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'
import { ManualModalContainer } from '../hooks/ManualModalContainer'

type Props = {
  active: boolean
}

const modalHeaderHeight = 40
const modalMaxHeight = 518
const modalMaxWidth = 700

export const ManualModal: React.FCX<Props> = provide(StepContainer)(
  ({ className, active }) => {
    const manualModal = ManualModalContainer.useContainer()
    const { manualModalBody, step, totalStep } = StepContainer.useContainer()
    const windowSize = WindowSizeContainer.useContainer()
    const modalHeight = Math.min(modalMaxHeight, windowSize.height - 60)
    const modalWidth = Math.min(modalMaxWidth, windowSize.width - 20)
    const bodyHeight = modalHeight - modalHeaderHeight
    return (
      <Modal
        className={className}
        active={active}
        onClose={manualModal.close}
        titleSize="l"
        title={`操作方法の確認(${step}/${totalStep})`}
        height={modalHeight}
        width={modalWidth}
        stopScroll
      >
        <Body height={bodyHeight}>{manualModalBody}</Body>
      </Modal>
    )
  }
)

const Modal = styled(_Modal)<{ width: number; height: number }>`
  ${({ width, height }) =>
    css`
      width: ${width}px;
      height: ${height}px;
    `}
`
const Body = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
`
