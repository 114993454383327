import uriTemplates from 'uri-templates'

export const path = {
  interviewer: {
    index: '/interviewer',
    interview: {
      index: '/interviewer/:interviewerToken',
      room: '/interviewer/:interviewerToken/room',
      finish: '/interviewer/:interviewerToken/finish',
    },
  },
  interviewee: {
    index: '/interviewee',
    terms: '/interviewee/terms',
    interview: {
      index: '/interviewee/:intervieweeToken',
      room: '/interviewee/:intervieweeToken/room',
      finish: '/interviewee/:intervieweeToken/finish',
    },
  },
  organization: {
    index: '/organization',
    setting: {
      index: '/organization/:organizationToken/settings',
      timeGuide: {
        index: '/organization/:organizationToken/settings/timeguide',
        form: '/organization/:organizationToken/settings/timeguide/form',
      },
      checkNote: '/organization/:organizationToken/settings/checknote',
    },
  },
}

export const fillParams = ({
  path,
  params,
}: {
  path: string
  params: { [key: string]: string }
}) => {
  const template = uriTemplates(
    path.replace(
      /:(.*?)\??\/|:(.*?)\??$/g,
      (_, n1, n2) => `{${n1 || n2}}${n1 ? '/' : ''}`
    )
  )
  return template.fillFromObject(params)
}
