import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'

const sideNavWidth = 248
export const SideNav = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.s};
  box-sizing: border-box;
  height: 100%;
  width: ${sideNavWidth}px;
  padding: 12px 16px 16px;
  background: ${theme.color.gray[5]};
`
