import React, { useCallback } from 'react'
import { A, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { MAIN_COLOR } from './constants'
import { AdviceRegistrationCardProps } from './types'

type Props = AdviceRegistrationCardProps & {
  width: string
}

export const AdviceRegistrationLinkButton: React.VFC<Props> = ({
  agentRegistrationUrl,
  width,
}) => {
  const onClick = useCallback(async () => {
    window.open(agentRegistrationUrl, '_blank')?.focus()
  }, [agentRegistrationUrl])

  return (
    <Link
      onClick={onClick}
      comlinkPushParams={{ action: 'open_advice_registration_page' }}
    >
      <AnswerButton width={width}>申し込む</AnswerButton>
    </Link>
  )
}

const Link = styled(A)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const AnswerButton = styled.button<{ width: string }>`
  ${buttonResetStyle}
  width: ${({ width }) => width};
  height: 40px;
  border-radius: 24px;
  font-weight: bold;
  font-size: ${theme.fontSize.m};
  background-color: ${theme.color.white[1]};
  color: ${MAIN_COLOR};
`
