import React from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { theme, Icon } from '@blue-agency/rogue'
import { ToastProps } from '.'

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
})
const fadeOut = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
})
const animationDuration = 300
const Wrapper = styled.div<{ duration: number }>`
  display: inline-flex;
  position: absolute;
  padding: 0 40px 0 20px;
  line-height: 1.5;
  top: 68px;
  right: 0;
  left: 0;
  margin: auto;
  justify-content: center;
  align-items: center;
  min-width: 360px;
  max-width: 720px;
  height: 88px;
  background: ${theme.color.green[3]}E6;
  color: ${theme.color.white[1]};
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} ${animationDuration}ms 0ms forwards,
    ${fadeOut} ${animationDuration}ms ${({ duration }) => duration}ms forwards;
  width: 360px;
  text-align: center;
  white-space: pre-wrap;
  z-index: 1;
`

const CloseIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`

export const PcToast: React.FC<ToastProps> = ({ text, duration, onClose }) =>
  ReactDOM.createPortal(
    <Wrapper duration={duration}>
      {text}
      <CloseIconWrapper onClick={onClose}>
        <Icon name="close" />
      </CloseIconWrapper>
    </Wrapper>,
    document.getElementById('root') as HTMLElement
  )
