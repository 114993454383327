import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  WeakColorSeparator,
  PrimaryButton,
  theme,
  NewTabLink,
} from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import image1 from './image1.png'
import image2 from './image2.png'
import image3 from './image3.png'
import image4 from './image4.png'
import image5 from './image5.png'
import { href } from '@/href'

const text = {
  title: '画面共有',
  main: '画面共有を開始します',
  card: {
    first: {
      main: {
        text: `資料は事前に開いておき
アプリケーションウィンドウ
で共有してください`,
        boldKeyword: 'アプリケーションウィンドウ',
      },
      sub: {
        text: `PowerPointは閲覧表示モードを
ご利用ください。
スライドショーモードでは正常に動作しない場合があります。
`,
        linkKeyword: /(閲覧表示モード|スライドショーモード)/,
      },
    },
    second: {
      main: {
        text: `資料の画面を
最小化 or 最大化していると
共有できません`,
        boldKeyword: '最小化 or 最大化',
      },
      sub: {
        text: `「最大化時に共有できない事象」は
Macのみ発生します。`,
      },
    },
    third: {
      main: {
        text: `共有中の画面が停止したら
画面共有を終了し再度開始してください`,
        boldKeyword: '画面共有を終了し再度開始してください',
      },
      sub: {
        text: `共有中にPowerPointの表示モードを
変更すると、停止する場合があります`,
      },
    },
  },
}

type Props = {
  active: boolean
  onClose: () => void
  onStart: () => void
}

export const StartScreenShareModal: React.FCX<Props> = ({
  className,
  active,
  onClose,
  onStart,
}) => {
  return (
    <Modal
      className={className}
      size="l"
      active={active}
      title={text.title}
      onClose={onClose}
    >
      <Wrapper>
        <Upper>
          <Txt bold size="l">
            {text.main}
          </Txt>
          <CardGroup>
            <Card>
              <CardUpper>
                <Image1 src={image1} />
                <Image2 src={image2} />
                <Image3 src={image3} />
              </CardUpper>
              <CardBottom>
                <Txt>
                  {reactStringReplace(
                    text.card.first.main.text,
                    text.card.first.main.boldKeyword,
                    (match, index) => (
                      <BoldSpan key={index}>{match}</BoldSpan>
                    )
                  )}
                </Txt>
                <SubTxt size="xs">
                  {reactStringReplace(
                    text.card.first.sub.text,
                    text.card.first.sub.linkKeyword,
                    (match, index) => (
                      <NewTabLink
                        key={index}
                        href={href.interviewer.help.interview.screenSharing}
                        action="open_interviewer_interview_help_page"
                      >
                        {match}
                      </NewTabLink>
                    )
                  )}
                </SubTxt>
              </CardBottom>
            </Card>
            <Card>
              <CardUpper>
                <Image4 src={image4} />
              </CardUpper>
              <CardBottom>
                <Txt>
                  {reactStringReplace(
                    text.card.second.main.text,
                    text.card.second.main.boldKeyword,
                    (match, index) => (
                      <BoldSpan key={index}>{match}</BoldSpan>
                    )
                  )}
                </Txt>
                <SubTxt size="xs">{text.card.second.sub.text}</SubTxt>
              </CardBottom>
            </Card>
            <Card>
              <CardUpper>
                <Image5 src={image5} />
              </CardUpper>
              <CardBottom>
                <Txt>
                  {reactStringReplace(
                    text.card.third.main.text,
                    text.card.third.main.boldKeyword,
                    (match, index) => (
                      <BoldSpan key={index}>{match}</BoldSpan>
                    )
                  )}
                </Txt>
                <SubTxt size="xs">{text.card.third.sub.text}</SubTxt>
              </CardBottom>
            </Card>
          </CardGroup>
        </Upper>
        <Separator />
        <Bottom>
          <ButtonWrapper>
            <StartButton
              size="l1"
              text="画面共有を開始"
              onClick={onStart}
              comlinkPushParams={{ action: 'start_interview_screen_share' }}
            />
          </ButtonWrapper>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 16px;
  background: ${theme.color.gray[5]};
  text-align: center;
`
const CardGroup = styled.div`
  ${Upper} & {
    margin-top: 20px;
  }
  display: flex;
  justify-content: space-between;
`
const Card = styled.div`
  width: 200px;
  border: 1px solid ${theme.color.navy[3]};
  border-radius: 4px;
  background-color: ${theme.color.white[1]};
`
const CardUpper = styled.div`
  position: relative;
  height: 145px;
`
const Image1 = styled.img`
  ${CardUpper} & {
    position: absolute;
    top: 8px;
    left: 11px;
  }
  width: 70px;
  height: 70px;
`
const Image2 = styled.img`
  ${CardUpper} & {
    position: absolute;
    top: 20px;
    left: 109px;
  }
  width: 70px;
  height: 70px;
`
const Image3 = styled.img`
  ${CardUpper} & {
    position: absolute;
    top: 68px;
    left: 51px;
  }
  width: 70px;
  height: 70px;
`
const Image4 = styled.img`
  ${CardUpper} & {
    position: absolute;
    top: 14px;
    left: 8px;
  }
  width: 182px;
`
const Image5 = styled.img`
  ${CardUpper} & {
    position: absolute;
    top: 14px;
    left: 16px;
  }
  width: 164px;
`
const CardBottom = styled.div`
  padding: 0 8px 8px;
  text-align: left;
`
const SubTxt = styled(Txt)`
  ${CardBottom} & {
    margin-top: 5px;
  }
`
const BoldSpan = styled.span`
  font-weight: bold;
`
const Separator = styled(WeakColorSeparator)``
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StartButton = styled(PrimaryButton)``
