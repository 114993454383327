import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  Txt,
  PrimaryButton,
  theme,
  NewTabLink,
  DangerButton,
  TertiaryButton,
} from '@blue-agency/rogue'
import { RoomPageContainer } from '../RoomPageContainer'
import { Layout } from '@/components/Layout'
import { href } from '@/href'
import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'
import { EconomyModeBox } from './EconomyModeBox'
import { BrowserContainer } from '@/hooks/BrowserContainer'
import { provide } from '@blue-agency/front-state-management'
import { ManualModal } from './ManualModal'
import { ManualModalContainer } from './hooks/ManualModalContainer'

export const Waiting: React.VFC = provide(ManualModalContainer)(() => {
  const manualModal = ManualModalContainer.useContainer()
  const { setRoomStatus, isEconomyMode, isStarted } =
    RoomPageContainer.useContainer()
  const toEntered = useCallback(() => setRoomStatus('entered'), [setRoomStatus])
  const { isPortrait, ...windowSize } = WindowSizeContainer.useContainer()
  const { getDeviceTypeByWindowSize } = BrowserContainer.useContainer()
  const deviceType = getDeviceTypeByWindowSize(windowSize)

  const content = (
    <Box>
      <MainTxt>
        {isStarted ? '面接が開始されています' : '面接官のみ入室可能な状態です'}
        <br />
        参加準備ができたら入室してください
      </MainTxt>
      <SubTxt>
        <NewTabLink href={href.terms} action="open_terms_page">
          利用規約
        </NewTabLink>
        及び
        <NewTabLink href={href.privacy} action="open_privacy_policy_page">
          プライバシーポリシー
        </NewTabLink>
        に
      </SubTxt>
      <ButtonWrapper>
        {isStarted ? (
          <RedStartButton
            size="ll"
            text="同意して面接に参加"
            onClick={toEntered}
            comlinkPushParams={{ action: 'enter_started_interview_room' }}
          />
        ) : (
          <StartButton
            size="ll"
            text="同意して入室"
            onClick={toEntered}
            comlinkPushParams={{ action: 'enter_interview_room' }}
          />
        )}
      </ButtonWrapper>
      <ButtonWrapper>
        <ConfirmButton
          size="ll"
          text="操作方法の確認"
          onClick={manualModal.open}
          comlinkPushParams={{ action: 'open_interview_manual_modal' }}
        />
        <ManualModal active={manualModal.active} />
      </ButtonWrapper>
      {isEconomyMode && <EconomyModeBoxWrapper />}
    </Box>
  )

  return deviceType === 'spLandscape' ? (
    content
  ) : (
    <Layout backgroundColor={theme.color.white[1]}>{content}</Layout>
  )
})

const Box = styled.div`
  position: absolute;
  top: 10%;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MainTxt = styled(Txt).attrs({ size: 'xl' })`
  text-align: center;
`
const SubTxt = styled(Txt)`
  margin-top: 25px;
`
const ButtonWrapper = styled.div`
  margin-top: 10px;
`
const StartButton = styled(PrimaryButton)``
const RedStartButton = styled(DangerButton)``
const ConfirmButton = styled(TertiaryButton)``

const EconomyModeBoxWrapper = styled(EconomyModeBox)`
  margin-top: 20px;
`
