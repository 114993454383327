import { useEffect, useState, useCallback, useMemo } from 'react'
import {
  ListMessagesResponse,
  CreateMessageRequest,
} from '@blue-agency/proton/web/c3po/chat_service_pb'
import { Message } from '@/components/Chat/Messages'
import { UserType } from '@blue-agency/react-im-interview'
import { throttle } from 'throttle-debounce'
import { beepSrc } from './beepSrc'
import { BeepContainer } from '../BeepContainer'

const baseUri = process.env.REACT_APP_CHAT_BASE_URI
if (!baseUri) throw new Error('baseUri not found')
const MAX_RETRY_COUNT = 5

export type InterviewChatWebSocketParams = {
  chatRoomGuid: string
  userType: UserType
  entryNumber: string
  onAddMessage: (message: Message) => void
}

export const useInterviewChatWebSocket = (
  params: InterviewChatWebSocketParams
) => {
  const userGuid = makeUserGuid(params.userType, params.entryNumber)

  const { beep } = BeepContainer.useContainer()

  // NOTE: 通知音を鳴らしてから30秒は次の通知音を鳴らさない
  const throttledBeep = useMemo(
    () =>
      throttle(30000, true, () => {
        beep(beepSrc)
      }),
    [beep]
  )

  const [connected, setConnected] = useState(false)
  const [ws, setWs] = useState<WebSocket | null>(null)
  const [retryCount, setRetryCount] = useState(0)

  const handleMessageEvent = useCallback(
    (event: MessageEvent) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(event.data)
      reader.onload = async () => {
        const bytes = new Uint8Array(reader.result as ArrayBuffer)
        const msg = ListMessagesResponse.ChatMessage.deserializeBinary(bytes)
        const { userType, entryNumber } = parseUserGuid(msg.getUserGuid())
        const message: Message = {
          userType,
          name: entryNumber,
          text: msg.getText(),
        }
        params.onAddMessage(message)
        if (
          // 自分以外の投稿の場合に音を出す
          !(userType === params.userType && entryNumber === params.entryNumber)
        ) {
          throttledBeep()
        }
      }
    },
    [params, throttledBeep]
  )

  useEffect(() => {
    let ws: WebSocket
    const connect = () => {
      ws = new WebSocket(`${baseUri}/${params.chatRoomGuid}`, [userGuid])
      ws.onopen = () => setConnected(true)
      ws.onmessage = handleMessageEvent
      ws.onclose = (e) => {
        setConnected(false)
        // MEMO: 正常終了の時はretryしない
        if (e.code === 1005 && e.reason === '') return
        if (retryCount < MAX_RETRY_COUNT) {
          setTimeout(function () {
            setRetryCount((prev) => prev++)
            connect()
          }, 1000)
        }
      }
      setWs(ws)
    }
    connect()
    return () => {
      ws.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!ws) return
    ws.onmessage = handleMessageEvent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleMessageEvent])

  const handleSend = useCallback(
    (text: string) => {
      if (!ws) return
      const message = new CreateMessageRequest()
      message.setRoomGuid(params.chatRoomGuid)
      message.setText(text)
      message.setUserGuid(userGuid)
      message.setUserType(ListMessagesResponse.UserType.UNKNOWN)
      ws.send(message.serializeBinary())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ws]
  )

  return { connected, handleSend }
}

export const makeUserGuid = (userType: UserType, entryNumber: string) =>
  `${userType}-${entryNumber}`

export const parseUserGuid = (
  userGuid: string
): { userType: UserType; entryNumber: string } => {
  const [userType, entryNumber] = userGuid.split('-')
  if (!userType) throw new Error('Not found userType')
  if (!entryNumber) throw new Error('Not found entryNumber')
  return { userType: userType as UserType, entryNumber }
}
