import React from 'react'
import { PcToast } from './PcToast'
import { SpToast } from './SpToast'
import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'
import { BrowserContainer } from '@/hooks/BrowserContainer'

export type ToastProps = {
  text: string
  duration: number
  onClose: () => void
}

export const Toast: React.FCX<ToastProps> = ({ ...toastProps }) => {
  const { width } = WindowSizeContainer.useContainer()
  const { isPcByWindowWidth } = BrowserContainer.useContainer()
  return isPcByWindowWidth(width) ? (
    <PcToast {...toastProps} />
  ) : (
    <SpToast {...toastProps} />
  )
}
