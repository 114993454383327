import { useCallback, useState } from 'react'
import { SetTimeguideModal } from './SetTimeguideModal'

export const useSetTimeguideModal = ({
  onClose,
  onOpen,
}: React.ComponentProps<typeof SetTimeguideModal>) => {
  const [isSubmitError, setIsSubmitError] = useState(false)

  const onSubmit = useCallback(async () => {
    try {
      // TODO:リクエスト処理を入れる
    } catch (e) {
      setIsSubmitError(true)
      throw e
    }
  }, [])

  return {
    isSubmitError,
    onSubmit,
  }
}
