import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { First } from '../First'
import { Second } from '../Second'
import { ManualModalContainer } from '../../hooks/ManualModalContainer'

const totalStep = 2

const useStep = () => {
  const manualModal = ManualModalContainer.useContainer()
  const [step, setStep] = useState(1)

  useEffect(() => {
    setStep(1)
  }, [manualModal.active])

  const manualModalBody = useMemo(() => {
    switch (step) {
      case 1:
        return <First />
      case 2:
        return <Second />
      default:
        throw new Error('Invalid step')
    }
  }, [step])

  const handleNext = useCallback(() => {
    setStep((prev) => ++prev)
  }, [])
  const handleBack = useCallback(() => {
    setStep((prev) => --prev)
  }, [])

  return {
    step,
    totalStep,
    handleNext,
    handleBack,
    manualModalBody,
  }
}

export const StepContainer = createContainer(useStep)
