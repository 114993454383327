import { createContainer } from '@blue-agency/front-state-management'
import { useRef } from 'react'
import { useMount } from 'react-use'

class MyAudio {
  audio: HTMLAudioElement
  available: boolean

  constructor() {
    this.audio = new Audio()
    this.available = true
  }

  setup() {
    this.audio.src = ''
    this.audio.muted = false
    // TODO: SafariでNotSupportedErrorが出るので一旦エラーを握りつぶしておく、エラーが出ないように対応する
    this.audio.play().catch(() => {})
  }

  async play(src: string, volume: number) {
    this.available = false
    this.audio.src = src
    this.audio.volume = volume
    await this.audio.play()
    this.available = true
  }
}

class AudioManager {
  audios: MyAudio[]

  constructor(parallelism: number) {
    this.audios = []
    for (let i = 0; i < parallelism; i++) {
      this.audios.push(new MyAudio())
    }
  }

  /**
   * NOTE: iOSはautoplayのポリシーがあるため、事前にユーザーインラタクションでplay()しておく
   * このように事前に用意しておく必要があるため、都度Audioインスタンスを作成して再生することはできない
   */
  setup() {
    const handler = () => {
      this.audios.forEach((audio) => {
        audio.setup()
      })
      window.removeEventListener('touchstart', handler)
    }
    window.addEventListener('touchstart', handler)
  }

  getAudio() {
    for (const audio of this.audios) {
      if (audio.available) return audio
    }
  }
}

const useBeep = () => {
  // NOTE: 同時に最大10個の音を鳴らせるようにする
  const audioManager = useRef(new AudioManager(10))

  useMount(() => {
    audioManager.current.setup()
  })

  /**
   *
   * @param src : 再生したい音声のsrc;
   * @param volume : 再生したい音声のvolume (0.0 ~ 1.0, default: 0.1);
   * @returns MyAudio
   *
   * 再生を途中で停止したい場合は、返り値のMyAudioをstop()する
   */
  const beep = async (src: string, volume?: number) => {
    const audio = audioManager.current.getAudio()
    audio?.play(src, volume ?? 0.1)
    return audio
  }

  return { beep }
}

export const BeepContainer = createContainer(useBeep)
