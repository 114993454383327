import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'
import {
  ChangeDeviceProps,
  InterviewControlsProps,
} from '@/components/InterviewControls'
import { EnteredContainer } from './EnteredContainer'
import { AlertboxHeightContainer } from '@/hooks/AlertboxHeightContainer'
import { getInterviewMainAreaSize } from '@/components/InterviewMainAreaLayout/getInterviewMainAreaSize'
import { BrowserContainer } from '@/hooks/BrowserContainer'
import { RoomPageContainer } from '../../RoomPageContainer'
import { useInterviewMenu } from '@/hooks/useInterviewMenu'
import { useMemo } from 'react'

export const useInterviewLayout = () => {
  const { isPortrait, ...windowSize } = WindowSizeContainer.useContainer()
  const { getDeviceTypeByWindowSize } = BrowserContainer.useContainer()
  const { interviewMaxMinutes } = RoomPageContainer.useContainer()
  const alertboxHeight = AlertboxHeightContainer.useContainer()
  const deviceType = getDeviceTypeByWindowSize(windowSize)
  const mainAreaSize = getInterviewMainAreaSize({
    deviceType,
    ...windowSize,
    alertboxHeight,
  })
  const {
    finishModal,
    toggleShowUsers,
    showUsers,
    toggleShowChat,
    showChat,
    startScreenShareModal,
    finishScreenShareModal,
    startTime,
    imInterview,
    errorMessage,
    unreadMessagesCnt,
    exitModal,
    handleFinish,
  } = EnteredContainer.useContainer()

  const changeDeviceProps = useMemo<ChangeDeviceProps | undefined>(() => {
    if (
      deviceType === 'pc' &&
      imInterview.selectedCameraDeviceId &&
      imInterview.selectedMicDeviceId
    ) {
      return {
        cameraDevices: imInterview.cameraDevices ?? [],
        selectedCameraDeviceId: imInterview.selectedCameraDeviceId,
        onChangeCameraDevice: imInterview.changeCameraDevice,
        micDevices: imInterview.micDevices ?? [],
        selectedMicDeviceId: imInterview.selectedMicDeviceId,
        onChangeMicDevice: imInterview.changeMicDevice,
        audioWaveHeights: imInterview.audioWaveHeights,
        muted: imInterview.muted,
        speakerDevices: imInterview.speakerDevices,
        selectedSpeakerDeviceId: imInterview.selectedSpeakerDeviceId,
        onChangeSpeakerDevice: imInterview.changeSpeakerDevice,
        stream: imInterview.stream,
      }
    }
    return undefined
  }, [
    deviceType,
    imInterview.cameraDevices,
    imInterview.changeCameraDevice,
    imInterview.selectedCameraDeviceId,
    imInterview.micDevices,
    imInterview.selectedMicDeviceId,
    imInterview.changeMicDevice,
    imInterview.audioWaveHeights,
    imInterview.muted,
    imInterview.speakerDevices,
    imInterview.selectedSpeakerDeviceId,
    imInterview.changeSpeakerDevice,
    imInterview.stream,
  ])

  const userType = 'interviewer'
  const menuProps = useInterviewMenu({
    userType,
    enableCameraDeviceChange: changeDeviceProps !== undefined,
  })

  const isControlDisabled = !!errorMessage || !imInterview.isConnected
  const interviewControlsProps: InterviewControlsProps = {
    windowSize,
    userType,
    deviceType,
    status: imInterview.status === 'started' ? 'started' : 'notStarted',
    muteButtonProps: {
      disabled: isControlDisabled,
      active: imInterview.muted,
      onClick: imInterview.muted ? imInterview.unmute : imInterview.mute,
    },
    cameraOffButtonProps: {
      disabled: isControlDisabled,
      active: imInterview.isCameraOff,
      onClick: imInterview.isCameraOff
        ? imInterview.cameraOn
        : imInterview.cameraOff,
    },
    chatButtonProps: {
      disabled: !!errorMessage || imInterview.status !== 'started',
      active: showChat,
      onClick: toggleShowChat,
      unread: 0 < unreadMessagesCnt,
    },
    userButtonProps: {
      disabled: !!errorMessage,
      active: showUsers,
      onClick: toggleShowUsers,
      count: imInterview.usersCount,
    },
    screenShareButtonProps: {
      disabled:
        !!errorMessage ||
        imInterview.status !== 'started' ||
        imInterview.isOtherScreenShared,
      active: imInterview.isMyScreenShared,
      onClick: imInterview.isMyScreenShared
        ? finishScreenShareModal.open
        : startScreenShareModal.open,
    },
    backButtonProps: {
      disabled: !imInterview.isConnected,
      onClick: exitModal.open,
    },
    finishButtonProps: {
      disabled: isControlDisabled,
      onClick: finishModal.open,
    },
    timerProps: {
      startTime,
      maxSeconds: 60 * interviewMaxMinutes,
      currentTime: new Date(),
      onFinish: handleFinish,
    },
    menuProps,
    changeDeviceProps,
  }

  return {
    interviewControlsProps,
    mainAreaSize,
  }
}
