import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { provide } from '@blue-agency/front-state-management'
import { theme, ErrorBox as _ErrorBox } from '@blue-agency/rogue'
import { useModal } from '@/hooks/useModal'
import { Layout } from '@/components/Layout'
import { useTitle } from '@/hooks/useTitle'
import { useOrganization } from '../../useOrganization'
import { OrganizerHeaderRight } from '@/components/OrganizerHeaderRight'
import { TemplateModal } from './TemplateModal'
import { Body } from './Body'
import { RegisterContainer } from './RegisterContainer'

export const Form = provide(RegisterContainer)(() => {
  const { organizationToken } = useParams<{ organizationToken?: string }>()
  if (!organizationToken) throw new Error('Not found organizationToken')
  const templateModal = useModal(true)
  const { organization } = useOrganization()
  const { interviewTimeErrorTxt, onSelectTemplate } =
    RegisterContainer.useContainer()
  useTitle('タイムガイド設定')
  return (
    <Layout
      rightNode={
        <OrganizerHeaderRight
          orgName={organization.name}
          customerCode={organization.guid}
        />
      }
      fixedHeader
    >
      <FormHeader>タイムガイド設定</FormHeader>
      <Wrapper>
        <FixedBar>
          {interviewTimeErrorTxt && (
            <ErrorTimeBox>{interviewTimeErrorTxt}</ErrorTimeBox>
          )}
          <Title>タイムガイド</Title>
        </FixedBar>
        <Body />
      </Wrapper>
      <TemplateModal
        active={templateModal.active}
        onClose={templateModal.close}
        onSelectTemplate={onSelectTemplate}
      />
    </Layout>
  )
})

const FormHeader = styled.header`
  display: flex;
  align-items: center;
  padding-left: 100px;
  font-size: 20px;
  background-color: ${theme.color.white[1]};
  height: 60px;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 10;
`

const Wrapper = styled.div`
  width: 960px;
  margin: auto;
  background-color: ${theme.color.white[1]};
  margin-top: 120px;
`

const Title = styled.h2`
  background: ${theme.color.navy[3]};
  color: ${theme.color.navy[1]};
  padding-left: 20px;
  height: 44px;
  display: flex;
  align-items: center;
  font-weight: normal;
  width: 960px;
`

const ErrorTimeBox = styled(_ErrorBox)`
  width: 960px;
  margin: 12px auto 13px;
`

const FixedBar = styled.div`
  position: sticky;
  top: 120px;
  z-index: 10;
  width: 960px;
  margin: auto;
  background: ${theme.color.white[1]};
`
