import React from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { InterviewVideo } from './InterviewVideo'
import {
  User as InterviewUser,
  UnstableLevel,
  MutedStreamIdMap,
} from '@blue-agency/react-im-interview'
import { DeviceType } from '../InterviewControls'

type Props = {
  width: number
  userType: InterviewUserType
  mutedStreamIdMap: MutedStreamIdMap
  deviceType: DeviceType
  users: InterviewUser[]
  unstableLevel?: UnstableLevel
  isCameraOff: boolean
  onVideoClick: (index: number) => void
  speakerDeviceId?: string
}

export type InterviewUserType = 'interviewer' | 'interviewee'

export const InterviewVideos: React.FCX<Props> = ({
  className,
  width,
  userType,
  mutedStreamIdMap,
  deviceType,
  users,
  unstableLevel,
  isCameraOff,
  onVideoClick,
  speakerDeviceId,
}) => {
  return (
    <Wrapper className={className} width={width}>
      {0 < users.length && (
        <TopBar
          isInterviewer={userType === 'interviewer'}
          deviceType={deviceType}
        />
      )}
      <VideoGroup>
        {users.map((user, index) => {
          const showMute = mutedStreamIdMap[user.id]
          return (
            <InterviewVideo
              key={user.id}
              index={index}
              width={width / 2}
              userType={userType}
              user={user}
              showMute={showMute}
              unstableLevel={unstableLevel}
              onVideoClick={onVideoClick}
              showCameraOffImg={user.isMine && isCameraOff}
              speakerDeviceId={speakerDeviceId}
            />
          )
        })}
      </VideoGroup>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ width: number }>`
  position: relative;
  z-index: 0;
  width: ${({ width }) => width}px;
`
const TopBar = styled.div<{ isInterviewer?: boolean; deviceType: DeviceType }>`
  ${Wrapper} & {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
  }
  height: ${({ deviceType }) => (deviceType === 'pc' ? 4 : 2)}px;
  background: ${({ isInterviewer }) =>
    isInterviewer ? theme.color.green[4] : theme.color.red.iM};
`
const VideoGroup = styled.div`
  ${Wrapper} & {
    width: 100%;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
