import React from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { H1 } from '@/components/Heading'

type Props = {
  title: string
}

export const PageHeader: React.FCX<Props> = ({
  title,
  className,
  children,
}) => {
  return (
    <Header className={className}>
      <H1>{title}</H1>
      {children}
    </Header>
  )
}

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: ${theme.color.white[1]};
  height: 60px;
`
