import { useState, useCallback } from 'react'

export type Modal = {
  active: boolean
  open: () => void
  close: () => void
}
export const useModal = (initialActive: boolean = false): Modal => {
  const [active, setActive] = useState(initialActive)
  const open = useCallback(() => setActive(true), [])
  const close = useCallback(() => setActive(false), [])

  return { active, open, close }
}
