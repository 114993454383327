import { RequireTxt, theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'

const Section = styled.section`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${theme.color.gray[4]};
`
const verticalCentering = css`
  display: flex;
  align-items: center;
`
const Row = styled.div`
  display: grid;
  grid-template-columns: 115px 1fr;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

const Title = styled.div`
  font-size: ${theme.fontSize.s};
  ${verticalCentering}
`

const Item = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr;
`

const Required = styled(RequireTxt).attrs({ size: 'xs', children: '必須' })`
  margin-right: 8px;
  ${verticalCentering}
`

const Field = styled.div`
  display: flex;
  align-self: start;
  align-items: start;
`

export const Form = {
  Section,
  Row,
  Title,
  Item,
  Required,
  Field,
}
