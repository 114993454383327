import React, { Suspense, useEffect, useMemo } from 'react'
import '@/assets/reset.css'
import '@/assets/theme.css'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { provide, CacheContainer } from '@blue-agency/front-state-management'
import { WindowSizeContainer } from '@/hooks/WindowSizeContainer'
import { ActivityIndicatorOverlay, useResponsive } from '@blue-agency/rogue'
import { path } from '@/path'
import { UnsupportedBrowser } from './components/UnsupportedBrowser'
import { NotFound } from './components/NotFound'
import { useTracking } from './hooks/useTracking'
import { BrowserContainer } from './hooks/BrowserContainer'
import { BeepContainer } from './hooks/BeepContainer'
import { AudioContextContainer } from './hooks/AudioContextContainer'
import { AlertboxHeightContainer } from './hooks/AlertboxHeightContainer'
import { InterviewerRoutes } from './pages/InterviewerRoutes'
import { IntervieweeRoutes } from './pages/IntervieweeRoutes'
import { OrganizationRoutes } from './pages/OrganizationRoutes'
import * as Sentry from '@sentry/react'
import { BffContainer } from './hooks/BffContainer'
import { CommunicationErrorModalContainer } from './hooks/CommunicationErrorModalContainer'
import { href } from './href'
import { injectAlertBox } from './injectAlertBox'
import { ToastContainer } from '@/hooks/ToastContainer'
import { Toast } from '@/components/Toast'
import { ThemeProvider } from 'styled-components'
const history = createBrowserHistory()

const App = () => {
  useTracking(history)
  return (
    <Router history={history}>
      <AppRoutes />
    </Router>
  )
}

export const AppRoutes: React.FC = provide(
  BeepContainer,
  CommunicationErrorModalContainer,
  AlertboxHeightContainer,
  AudioContextContainer,
  BffContainer,
  ToastContainer,
  BrowserContainer,
  WindowSizeContainer,
  CacheContainer
)(() => {
  const windowSize = WindowSizeContainer.useContainer()
  const { isInternetExplorer } = BrowserContainer.useContainer()
  const { showToast, toastText, toastDuration, handleToastClose } =
    ToastContainer.useContainer()
  const { responsive } = useResponsive()
  const theme = useMemo(() => {
    return { responsive }
  }, [responsive])

  useEffect(() => {
    if (isInternetExplorer) return
    injectAlertBox()
  }, [isInternetExplorer])

  if (isInternetExplorer) {
    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route
            path={path.interviewer.index}
            render={() => (
              <UnsupportedBrowser
                windowSize={windowSize}
                href={href.interviewer.recommendedEnvironment}
                action="open_interviewer_recommended_environment_page"
              />
            )}
          />
          <Route
            path="*"
            render={() => (
              <UnsupportedBrowser
                windowSize={windowSize}
                href={href.interviewee.recommendedEnvironment}
                action="open_interviewee_recommended_environment_page"
              />
            )}
          />
        </Switch>
      </ThemeProvider>
    )
  }
  return (
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={() => <NotFound />}>
        <Suspense fallback={<ActivityIndicatorOverlay />}>
          <Switch>
            <Route
              path={path.interviewer.index}
              component={InterviewerRoutes}
            />
            <Route
              path={path.interviewee.index}
              component={IntervieweeRoutes}
            />
            <Route
              path={path.organization.index}
              component={OrganizationRoutes}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
        {showToast && (
          <Toast
            text={toastText}
            duration={toastDuration}
            onClose={handleToastClose}
          />
        )}
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  )
})

export default App
