import {
  createContainer,
  useCachedPromise,
} from '@blue-agency/front-state-management'
import { useParams } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { AudioContextContainer } from '@/hooks/AudioContextContainer'
import { BrowserContainer } from '@/hooks/BrowserContainer'
import { BffContainer } from '@/hooks/BffContainer'
import {
  GetIntervieweeInterviewResponse,
  GetIntervieweeInterviewVideoQualityResponse,
} from '@blue-agency/proton/web/v2/snoke_bff/snoke_bff_service_pb'

const { Status } = GetIntervieweeInterviewResponse

type RoomStatus =
  | 'initial'
  | 'environmentCheck'
  | 'tutorial'
  | 'waiting'
  | 'entered'
  | 'finished'

const useRoomPage = () => {
  const { intervieweeToken } = useParams<{ intervieweeToken?: string }>()
  if (!intervieweeToken) throw new Error('Not found intervieweeToken')

  const { getAudioContext } = AudioContextContainer.useContainer()
  const { isSafari } = BrowserContainer.useContainer()
  useEffect(() => {
    // MEMO: audioContextインスタンス作成のタイミングはgetUserMediaを呼ぶ前が望ましい
    // という情報をみた気がするので、Roomに入ったタイミングでインスタンスを作成している

    // TODO: getUserMediaを呼んだ後でインスタンスを作成しても問題ないかを確認して、
    // 問題なければここでgetAudioContextを呼ぶのをやめる
    if (isSafari) getAudioContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bff = BffContainer.useContainer()
  const interview = useCachedPromise(bff.cacheKey.getIntervieweeInterview, () =>
    bff.getIntervieweeInterview(intervieweeToken)
  )
  const quality = useCachedPromise(
    bff.cacheKey.getIntervieweeInterviewVideoQuality,
    () => bff.getIntervieweeInterviewVideoQuality(intervieweeToken)
  )

  const sessionStorageKey = {
    environmentCheck: `environmentCheck-${intervieweeToken}`,
  }

  const initialRoomStatus: RoomStatus = useMemo(() => {
    if (interview.status === Status.FINISHED) return 'finished'
    const isEnvironmentCheckFinished = !!sessionStorage.getItem(
      sessionStorageKey.environmentCheck
    )
    return isEnvironmentCheckFinished ? 'waiting' : 'initial'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [roomStatus, setRoomStatus] = useState<RoomStatus>(initialRoomStatus)

  const isEconomyMode =
    quality &&
    quality.mode === GetIntervieweeInterviewVideoQualityResponse.Mode.LOW

  return {
    interview,
    intervieweeToken,
    roomStatus,
    setRoomStatus,
    sessionStorageKey,
    quality,
    isEconomyMode,
  }
}

export const RoomPageContainer = createContainer(useRoomPage)
