import React, { useCallback } from 'react'
import { theme, InputText, Icon as _Icon } from '@blue-agency/rogue'
import styled from 'styled-components'
import { Form } from '@/components/Form'
import { RegisterContainer } from '../RegisterContainer'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

export const PartSection: React.VFC = () => {
  const { register, errors, onRemovePart, onMovePart, partsFieldArray } =
    RegisterContainer.useContainer()
  const errorMessageById = useCallback(
    (label: 'title' | 'guide_time_minutes', index?: number) => {
      const err = errors.parts
      if (err === undefined || index === undefined) return
      return err[index]?.[label]?.message
    },
    [errors]
  )
  return (
    <DragDropContext
      onDragEnd={(result) =>
        onMovePart(result.source.index, result.destination?.index ?? 0)
      }
    >
      <Droppable droppableId="parts">
        {(provided) => (
          <FormWrapper ref={provided.innerRef} {...provided.droppableProps}>
            {partsFieldArray.map((field, index) => (
              <Draggable
                index={index}
                key={field.id}
                draggableId={`${field.id}`}
              >
                {(provided, snapshot) => (
                  <Form.Section
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      ...(snapshot.isDragging
                        ? rowStyleInDragging
                        : rowStyleDefault),
                    }}
                  >
                    <PartWrapper>
                      <Column>
                        <Form.Title>所要時間(分)</Form.Title>
                        <Form.Item>
                          <Form.Required />
                          <InputText
                            name={`parts.${index}.guide_time_minutes`}
                            size="ss"
                            ref={register()}
                            defaultValue={'' + field.guide_time_minutes}
                            onBlurError={errorMessageById(
                              'guide_time_minutes',
                              index
                            )}
                          />
                        </Form.Item>
                        <Form.Title>タイトル(30文字)</Form.Title>
                        <Form.Item>
                          <Form.Required />
                          <InputText
                            name={`parts.${index}.title`}
                            placeholder="タイトルを入力"
                            size="ll"
                            ref={register()}
                            defaultValue={field.title}
                            onBlurError={errorMessageById('title', index)}
                          />
                        </Form.Item>
                      </Column>
                      <Bottom>
                        <DetailTitle>詳細コメント</DetailTitle>
                        <InputText
                          name={`parts.${index}.description`}
                          type="textarea"
                          placeholder="詳細コメントを入力"
                          size="ll"
                          ref={register()}
                          defaultValue={field.description}
                        />
                      </Bottom>
                      {partsFieldArray.length > 1 && (
                        <Icons>
                          <MoveButton
                            isDraggable={partsFieldArray.length > 1}
                            {...provided.dragHandleProps}
                          />
                          <DeleteButton onClick={() => onRemovePart(index)} />
                        </Icons>
                      )}
                    </PartWrapper>
                  </Form.Section>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </FormWrapper>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const FormWrapper = styled.div``

const PartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const Column = styled.div`
  display: grid;
  grid-template-columns: 115px 152px 115px 1fr;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`
const Bottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: 50px;
`
const DetailTitle = styled(Form.Title)`
  padding-top: 8px;
  margin-right: 70px;
`
const Icons = styled.div`
  margin-left: 26px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
`
const MoveIcon = styled(_Icon).attrs({ size: 'm', name: 'move-all' })`
  color: ${theme.color.navy[1]};
  margin-bottom: 16px;
`
const MoveButton: React.VFC<{ isDraggable: boolean }> = ({
  isDraggable,
  ...props
}) => <div {...props}>{isDraggable && <MoveIcon />}</div>

const DeleteButton = styled(_Icon).attrs({ size: 'm', name: 'delete' })`
  color: ${theme.color.red[2]};
  cursor: pointer;
`
const rowStyleDefault: React.CSSProperties = {
  backgroundColor: `${theme.color.white[1]}`,
}
const rowStyleInDragging: React.CSSProperties = {
  backgroundColor: `${theme.color.white[1]}`,
  opacity: '0.6',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)',
}
