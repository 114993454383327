import React from 'react'
import { A, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { buttonResetStyle } from '@/styles/buttonResetStyle'

type Props = {
  width: string
  openSurveyLink: () => Promise<void>
}

export const SurveyLinkButton: React.VFC<Props> = ({
  openSurveyLink,
  width,
}) => {
  return (
    <A
      onClick={openSurveyLink}
      comlinkPushParams={{ action: 'open_interviewee_survey_page' }}
    >
      <AnswerButton width={width}>アンケートに回答</AnswerButton>
    </A>
  )
}

const AnswerButton = styled.button<{ width: string }>`
  ${buttonResetStyle}
  width: ${({ width }) => width};
  height: 40px;
  border-radius: 24px;
  font-weight: bold;
  font-size: ${theme.fontSize.m};
  background-color: ${theme.color.white[1]};
  color: ${theme.color.navy[1]};
`
