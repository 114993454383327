import React, { useCallback } from 'react'
import {
  theme,
  Modal,
  TertiaryButton as _TertiaryButton,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { templateForm } from './template'

type Props = {
  active: boolean
  onClose: () => void
  onSelectTemplate: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
}
const templateList = Object.entries(templateForm).map(([id, value]) => ({
  id,
  name: value.name,
  interviewTime: value.interviewTime,
}))
export const TemplateModal: React.VFC<Props> = ({
  active,
  onClose,
  onSelectTemplate,
}) => {
  const handleSelectTemplate = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onSelectTemplate(event)
      onClose()
    },
    [onSelectTemplate, onClose]
  )
  return (
    <Modal active={active} title="テンプレートから作成" size="m">
      <Upper>
        <DirectionTxt>
          タイムガイドをテンプレートから作成します。
          <br />
          使用するテンプレートを選択してください。
        </DirectionTxt>
        <TemplateListTitle>
          <TimeguideName>タイムガイド名</TimeguideName>
          <InterviewTime>面接時間</InterviewTime>
        </TemplateListTitle>
        <TemplateItemWrapper>
          {templateList.map((template) => {
            return (
              <TemplateItem
                id={template.id}
                key={template.id}
                onClick={(event) => handleSelectTemplate(event)}
              >
                <Column>
                  <TimeguideName bold>{template.name}</TimeguideName>
                  <InterviewTime bold>{template.interviewTime}分</InterviewTime>
                </Column>
              </TemplateItem>
            )
          })}
        </TemplateItemWrapper>
      </Upper>
      <WeakColorSeparator />
      <Bottom>
        <CreateWithoutTemplateButton
          onClick={onClose}
          comlinkPushParams={{ action: 'create_without_template' }}
        >
          テンプレートを使わずに作成
        </CreateWithoutTemplateButton>
      </Bottom>
    </Modal>
  )
}

const verticalCentering = css`
  display: flex;
  align-items: center;
`

const Upper = styled.div`
  padding: 20px 20px 0;
  width: 100%;
`

const DirectionTxt = styled(Txt).attrs({ size: 's' })`
  margin-bottom: 20px;
`

const TemplateListTitle = styled.div`
  ${verticalCentering}
  background: ${theme.color.navy[3]};
  padding-left: 12px;
  height: 45px;
  margin-bottom: 8px;
`

const TemplateItemWrapper = styled.div`
  height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const TimeguideName = styled(Txt).attrs({ size: 's' })`
  width: 112px;
`

const InterviewTime = styled(Txt).attrs({ size: 's' })``

const Column = styled.div`
  ${verticalCentering}
`

const TemplateItem = styled.div<{ isSet?: boolean }>`
  padding: 8px 12px;
  border: 1px solid ${theme.color.gray[4]};
  border-radius: 4px;
  width: 460px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    background: ${theme.color.gray[4]};
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`

const CreateWithoutTemplateButton = styled(_TertiaryButton).attrs({
  size: 'll',
})``
