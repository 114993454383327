import React from 'react'
import { Footer as _Footer } from '@/components/Footer'
import { Pagination } from '@blue-agency/rogue'
import styled from 'styled-components'

export const Footer: React.FCX = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Pagination count={10} onChange={() => null} />
    </Wrapper>
  )
}

const Wrapper = styled(_Footer)`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  height: 60px;
`
