import React from 'react'
import { InputText } from '@blue-agency/rogue'
import { Form } from '@/components/Form'
import { RegisterContainer } from '../RegisterContainer'

export const InterviewTimeSection: React.VFC = () => {
  const { errors, register } = RegisterContainer.useContainer()
  return (
    <Form.Section>
      <Form.Row>
        <Form.Title>タイムガイド名</Form.Title>
        <Form.Item>
          <Form.Required />
          <Form.Field>
            <InputText
              name="name"
              placeholder="タイムガイド名を入力"
              size="ll"
              ref={register()}
              onBlurError={errors.name?.message}
            />
          </Form.Field>
        </Form.Item>
      </Form.Row>
      <Form.Row>
        <Form.Title>面接時間(分)</Form.Title>
        <Form.Item>
          <Form.Required />
          <InputText
            name="interviewTime"
            size="ss"
            ref={register()}
            onBlurError={errors.interviewTime?.message}
          />
        </Form.Item>
      </Form.Row>
    </Form.Section>
  )
}
