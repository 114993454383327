import React from 'react'
import { InterviewChatWebSocketParams } from '@/hooks/useInterviewChatWebSocket'
import { NotReady } from './NotReady'
import { Ready } from './Ready'

type Props = {
  chatParams?: InterviewChatWebSocketParams
}

export const ChatArea: React.FCX<Props> = ({ className, chatParams }) => {
  if (!chatParams) return <NotReady className={className} />
  return <Ready className={className} {...chatParams} />
}
