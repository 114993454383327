import { useMount } from 'react-use'
import { startInterviewBeepSrc } from './startInterviewBeepSrc'
import { Status } from '@blue-agency/react-im-interview'
import { BeepContainer } from '@/hooks/BeepContainer'
import { useEffect, useRef } from 'react'

/**
 * 入室解除音を鳴らすhook
 */
export const useStartInterviewBeep = (status: Status) => {
  const { beep } = BeepContainer.useContainer()
  const shouldBeepRef = useRef(false)

  /**
   * useIMInterviewから渡ってくるstatusは、必ず初期値がnotStartedになっている。
   * 「notStarted → started」のステータス変化を入室解除音のトリガーにすると、開始後に入室した場合やリロード再入室した場合も入室解除音が鳴ってしまう。
   * そのため、1秒間以上startedにならなかった場合にのみ待機画面にいると判断し、その後startedになった時に入室解除音を鳴らす。
   */
  useMount(() => {
    setTimeout(() => {
      shouldBeepRef.current = true
    }, 1000)
  })

  useEffect(() => {
    if (status === 'started' && shouldBeepRef.current) {
      beep(startInterviewBeepSrc)
    }
  }, [status, beep])
}
