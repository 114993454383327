import React from 'react'
import styled from 'styled-components'
import { EnteredContainer } from '../hooks/EnteredContainer'
import { theme, Icon } from '@blue-agency/rogue'
import CameraOffImg from '@/assets/camera_off.png'

export const VideoArea: React.FCX = ({ className }) => {
  const { videoRef, mutedMainVideoAudio, imInterview } =
    EnteredContainer.useContainer()
  if (!imInterview.mainUser) return null
  return (
    <Wrapper className={className}>
      {imInterview.isCameraOff && imInterview.mainUser.isMine ? (
        <CameraOff src={CameraOffImg} />
      ) : (
        <MainVideo
          className={className}
          muted
          autoPlay
          playsInline
          ref={videoRef}
          mirrorEffect={imInterview.mainUser.isMine}
        />
      )}
      {mutedMainVideoAudio && <MuteMikeIcon name="mute-mike" />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MainVideo = styled.video<{ mirrorEffect?: boolean }>`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  background: ${theme.color.black[1]};
  ${({ mirrorEffect }) => mirrorEffect && 'transform: scaleX(-1);'}
`
const CameraOff = styled.img`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  object-fit: cover;
`

const MuteMikeIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  padding: 4px;
  border-radius: 50%;
  color: ${theme.color.white[1]};
  background-color: ${theme.color.red[2]};
  opacity: 0.9;

  ${Wrapper} & {
    position: absolute;
    bottom: 12px;
    left: 12px;
  }
`
