import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Layout } from '@/components/Layout'
import { href } from '@/href'
import { Txt, HelpLinkIcon } from '@blue-agency/rogue'
import {
  AdviceRegistrationCard,
  AdviceRegistrationCardProps,
} from './AdviceRegistrationCard'
import { PcSurveyCard } from './surveyCard/PcSurveyCard'
import { SpPortraitSurveyCard } from './surveyCard/SpPortraitSurveyCard'
import { SpLandscapeSurveyCard } from './surveyCard/SpLandscapeSurveyCard'
import { SurveyCardProps } from './surveyCard/types'

type Props = SurveyCardProps & AdviceRegistrationCardProps

export const Page: React.VFC<Props> = ({
  openSurveyLink,
  agentRegistrationUrl,
}) => {
  const { responsive } = useContext(ThemeContext)

  return (
    <Layout
      notFixHeight
      rightNode={
        <HelpLinkIcon
          href={href.interviewee.help.interview}
          action="open_interviewee_interview_help_page"
        />
      }
    >
      <Wrapper paddingBottom={responsive.pc ? '0px' : '90px'}>
        <Txt>終了しました。お疲れ様でした。</Txt>
        <AdviceRegistrationCardWrapper>
          <AdviceRegistrationCard agentRegistrationUrl={agentRegistrationUrl} />
        </AdviceRegistrationCardWrapper>
        <BottomFixed>
          {responsive.pc ? (
            <PcSurveyCard openSurveyLink={openSurveyLink} />
          ) : responsive.spPortrait ? (
            <SpPortraitSurveyCard openSurveyLink={openSurveyLink} />
          ) : (
            <SpLandscapeSurveyCard openSurveyLink={openSurveyLink} />
          )}
        </BottomFixed>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div<{ paddingBottom: string }>`
  display: flex;
  width: 100%;
  align-self: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`
const BottomFixed = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`
const AdviceRegistrationCardWrapper = styled.div`
  width: ${({ theme }) => (theme.responsive.pc ? '360px' : '80%')};
  max-width: 360px;
  margin-top: 10px;
`
