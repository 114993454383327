import { useState, useCallback, useRef, useMemo, RefObject } from 'react'
import { href } from '@/href'
import { UserType } from '@blue-agency/react-im-interview'
import { useModal, Modal } from './useModal'

export type Item = {
  text: string
  onClick?: () => void
  href?: string
  action: string
}

export type InterviewMenuContext = {
  showDropdown: boolean
  handleAdditionalMenuIconClick: () => void
  dropdownRef: RefObject<HTMLDivElement>
  handleOutsideClick: () => void
  items: Item[]
  unstableConnectionModal: Modal
  changeDeviceModal: Modal
  reloadModal: Modal
}

type Args = {
  userType: UserType
  enableCameraDeviceChange: boolean
}

export const useInterviewMenu = ({
  userType,
  enableCameraDeviceChange,
}: Args): InterviewMenuContext => {
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const reloadModal = useModal()
  const unstableConnectionModal = useModal(false)
  const changeDeviceModal = useModal(false)

  const handleAdditionalMenuIconClick = useCallback(
    () => setShowDropdown((prev) => !prev),
    []
  )

  const handleOutsideClick = useCallback(() => {
    setShowDropdown(false)
  }, [])

  const items = useMemo<Item[]>(() => {
    const value = [
      {
        text: '接続が不安定なときはこちら',
        onClick: unstableConnectionModal.open,
        action: 'open_unstable_connection_modal',
      },
      {
        text: 'ヘルプ',
        href:
          userType === 'interviewer'
            ? href.interviewer.help.interview.top
            : href.interviewee.help.interview,
        action: 'open_interview_help_page',
      },
    ]
    if (enableCameraDeviceChange) {
      value.push({
        text: 'デバイス選択',
        onClick: changeDeviceModal.open,
        action: 'open_change_device_modal',
      })
    }
    return value
  }, [
    userType,
    unstableConnectionModal.open,
    changeDeviceModal.open,
    enableCameraDeviceChange,
  ])

  return {
    showDropdown,
    handleAdditionalMenuIconClick,
    dropdownRef,
    handleOutsideClick,
    items,
    unstableConnectionModal,
    changeDeviceModal,
    reloadModal,
  }
}
