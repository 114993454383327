import { useCameraTest } from '@/hooks/useCameraTest'
import { useCallback } from 'react'
import { RoomPageContainer } from '../../RoomPageContainer'

export const useEnvironmentCheck = () => {
  const { setRoomStatus, sessionStorageKey, intervieweeToken } =
    RoomPageContainer.useContainer()

  const cameraTestConnectionProps = useCameraTest(
    'interviewee',
    intervieweeToken
  )

  const handleFinish = useCallback(() => {
    sessionStorage.setItem(sessionStorageKey.environmentCheck, 'done')
    setRoomStatus('tutorial')
  }, [sessionStorageKey.environmentCheck, setRoomStatus])

  return { handleFinish, cameraTestConnectionProps }
}
