import { useCallback, useMemo, ComponentProps, useEffect, useRef } from 'react'
import { InterviewVideo } from '../InterviewVideo'
import { BrowserContainer } from '@/hooks/BrowserContainer'
import { AudioContextContainer } from '@/hooks/AudioContextContainer'
import { Comlink } from '@blue-agency/comlink'

export const useInterviewVideo = ({
  onVideoClick,
  index,
  user,
  speakerDeviceId,
}: ComponentProps<typeof InterviewVideo>) => {
  const { isSafari } = BrowserContainer.useContainer()
  const { getAudioContext } = AudioContextContainer.useContainer()

  const videoRef = useRef<HTMLVideoElement>(null)

  const shouldBeMuted = useMemo(() => {
    const isMine = user.isMine ?? false
    return isSafari || isMine
  }, [user.isMine, isSafari])

  // MediaStream を video のソースとしてセット
  // 自分のストリームの場合は音声をミュートにする
  useEffect(() => {
    if (videoRef.current === null) return

    // Safari 14以前ではonloadedmetadataがユーザーインタラクションが行われるまで発火せずtrack.enabled = falseのままになってしまうのでスキップ
    // ref: https://stadium-co-jp.slack.com/archives/C012QTXQ398/p1617850467116000
    if (!isSafari) {
      // ref: https://github.com/shiguredo/sora-demo/blob/64da5a6f2915d957df77d8378db1921bb3af055e/src/components/Video/Video.tsx#L44-L54
      user.stream.getVideoTracks().forEach((track) => (track.enabled = false))
      videoRef.current.onloadedmetadata = (_) => {
        user.stream.getVideoTracks().forEach((track) => (track.enabled = true))
      }
    }

    videoRef.current.srcObject = user.stream

    // srcObject を切り替えるたびに muted もセットし直す必要がある
    // muted をセットしても実際の DOM に反映されないという React のバグのため
    // see https://stackoverflow.com/questions/61510160/why-muted-attribute-on-video-tag-is-ignored-in-react
    videoRef.current.muted = shouldBeMuted
  }, [user.stream, shouldBeMuted, isSafari])

  // スピーカーが切り替わったときに setSinkId を呼ぶ
  useEffect(() => {
    if (speakerDeviceId === undefined) return
    if (videoRef.current === null) return
    if (videoRef.current.setSinkId === undefined) return
    if (user.stream.getAudioTracks().length === 0) return

    videoRef.current.setSinkId(speakerDeviceId).catch((e) => {
      Comlink.push({
        type: 'system_activity',
        group: 'snoke',
        action: 'failed_to_set_sink_for_other_participants_audio',
        metadata: {
          errorMessage: e.message ?? '',
        },
      })

      // DOMException が発生することがある（原因は不明）が、この例外が発生してもスピーカーの切り替えには成功している可能性が高い
      // そのため「失敗した可能性があります」という曖昧な表現にしている
      alert(
        '音声の出力デバイス設定に失敗した可能性があります。相手からの音声が聞こえない場合、ブラウザを再読込してください。'
      )
    })
  }, [user.stream, speakerDeviceId])

  // Safari のときは音声についての特殊な処理を行う
  useEffect(() => {
    if (!isSafari || user.isMine) return
    const audioContext = getAudioContext()
    const source = audioContext.createMediaStreamSource(user.stream)
    source.connect(audioContext.destination)
    return () => {
      source.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.stream])

  const handleClick = useCallback(() => {
    onVideoClick(index)
  }, [onVideoClick, index])

  return { videoRef, handleClick }
}
