import React from 'react'
import styled from 'styled-components'
import {
  theme,
  PrimaryButton as _PrimaryButton,
  TertiaryButton as _TertiaryButton,
} from '@blue-agency/rogue'
import { useModal } from '@/hooks/useModal'
import { CancelModal } from './CancelModal'
import { PartSection } from './PartSection'
import { InterviewTimeSection } from './InterviewTimeSection'
import { AddButton } from './AddButton'
import { RegisterContainer } from '../RegisterContainer'

export const Body = () => {
  const cancelModal = useModal()
  const { onSubmit, handleSubmit, onAddPart } = RegisterContainer.useContainer()
  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <InterviewTimeSection />
          <PartSection />
          <AddButton onClick={onAddPart} />
        </InputWrapper>
        <Bottom>
          <CancelButton
            onClick={cancelModal.open}
            comlinkPushParams={{ action: 'open_cancelModal_timeguide' }}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            comlinkPushParams={{ action: 'save_timeguide' }}
          >
            保存
          </SubmitButton>
        </Bottom>
      </FormContainer>
      <CancelModal active={cancelModal.active} onClose={cancelModal.close} />
    </>
  )
}

const FormContainer = styled.form``

const InputWrapper = styled.div`
  padding: 34px 20px 124px;
  min-height: 100vh;
`

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 960px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid ${theme.color.gray[4]};
  background: ${theme.color.white[1]};
`

const CancelButton = styled(_TertiaryButton).attrs({ size: 'l2' })`
  margin-right: 20px;
`

const SubmitButton = styled(_PrimaryButton).attrs({ size: 'l2' })``
