import React from 'react'
import { Entered } from './Entered'
import { provide } from '@blue-agency/front-state-management'
import { RoomPageContainer } from './RoomPageContainer'
import { Redirect } from 'react-router-dom'
import { path, fillParams } from '@/path'
import { EnvironmentCheck } from './EnvironmentCheck'
import { Waiting } from './Waiting'
import { useTitle } from '@/hooks/useTitle'

export const RoomPage = provide(RoomPageContainer)(() => {
  const { roomStatus, interviewerToken } = RoomPageContainer.useContainer()

  useTitle('Web面接')

  switch (roomStatus) {
    case 'finished':
      return (
        <Redirect
          to={fillParams({
            path: path.interviewer.interview.finish,
            params: { interviewerToken },
          })}
        />
      )
    case 'environmentCheck':
      return <EnvironmentCheck />
    case 'waiting':
      return <Waiting />
    case 'entered':
      return <Entered />
  }
})
