import { DeviceType } from '@/components/InterviewControls'
import { WindowSize } from '@blue-agency/react-utils/dist/useWindowSize'
import { headerHeight } from '@/components/Header'
import { controlsHeight } from '@/components/InterviewControls/SpPortrait'
import { controlsWidth } from '@/components/InterviewControls/SpLandscape'

export const getInterviewMainAreaSize = ({
  deviceType,
  width,
  height,
  alertboxHeight,
}: WindowSize & { deviceType: DeviceType; alertboxHeight: number }) => {
  switch (deviceType) {
    case 'pc':
      return {
        width,
        height: height - headerHeight - alertboxHeight,
      }
    case 'spPortrait':
      return {
        width,
        height: height - controlsHeight - alertboxHeight,
      }
    case 'spLandscape':
      return {
        width: width - controlsWidth,
        height: height - alertboxHeight,
      }
  }
}
