import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import {
  Modal as _Modal,
  Txt,
  Icon as _Icon,
  TertiaryButton,
  WeakColorSeparator,
  theme,
  NewTabLink,
} from '@blue-agency/rogue'
import { href } from '@/href'
import { UserType } from '@blue-agency/react-im-interview'

type Props = {
  active: boolean
  userType: UserType
  isSp?: boolean
  onClose: () => void
}

export const UnstableConnectionModal: React.FCX<Props> = ({
  className,
  active,
  userType,
  isSp = false,
  onClose,
}) => {
  const helpUrl = useMemo(() => {
    if (userType === 'interviewer') {
      return href.interviewer.help.environmentCheck.speed
    }
    return href.interviewee.help.environmentCheck.speed
  }, [userType])

  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <Modal
      className={className}
      active={active}
      title="接続が不安定なときは"
      onClose={onClose}
      size="l"
    >
      <Wrapper>
        <Upper isSpLayout={isSp}>
          <Row>
            <IconWrapper isSpLayout={isSp}>
              <ReloadIcon name="reload" />
            </IconWrapper>
            <DescriptionWrapper>
              <DescriptionTitle size="l">再読込 / リロード</DescriptionTitle>
              <Txt>
                音声/映像が不安定な場合にお試しください。
                <br />
                接続状況が改善する場合があります。
              </Txt>
              <ReloadButton
                comlinkPushParams={{
                  action: 'click_reload_button_on_UnstableConnectionModal',
                }}
                size="m1"
                onClick={handleReload}
              >
                再読込
              </ReloadButton>
            </DescriptionWrapper>
          </Row>
          <Row>
            <IconWrapper isSpLayout={isSp}>
              <EarphoneIcon name="earphone" />
            </IconWrapper>
            <DescriptionWrapper>
              <DescriptionTitle size="l">イヤホンの再接続</DescriptionTitle>
              <Txt>
                「音声が聞こえない」ときは、イヤホンを再接続してみましょう。
                <br />
                接続状況が改善され、聞こえるようになる場合があります。
              </Txt>
            </DescriptionWrapper>
          </Row>
          <NoticeTxt>
            上記でも接続状況が解消されない場合は、
            <NewTabLink
              href={helpUrl}
              action="open_speed_help_from_UnstableConnectionModal"
            >
              ヘルプページ
            </NewTabLink>
            をご覧ください。
          </NoticeTxt>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <ButtonGroup>
            <CloseButton
              comlinkPushParams={{ action: 'close_UnstableConnectionModal' }}
              size="l1"
              onClick={onClose}
            >
              閉じる
            </CloseButton>
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Modal = styled(_Modal)`
  width: 700px;
  max-width: 90%;
  overflow-y: scroll;
  max-height: 90%;
`

const Wrapper = styled.div``
const Upper = styled.div<{ isSpLayout: boolean }>`
  padding: ${({ isSpLayout }) => (isSpLayout ? '20px 16px' : '48px 70px 20px')};
`

const Row = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 33px;
  }
`

const IconWrapper = styled.div<{ isSpLayout: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  margin-right: ${({ isSpLayout }) => (isSpLayout ? '12px' : '40px')};
`
const Icon = styled(_Icon)`
  color: ${theme.color.white[1]};
`
const ReloadIcon = styled(Icon)`
  width: 40px;
  height: 40px;
`
const EarphoneIcon = styled(Icon)`
  width: 50px;
  height: 50px;
`
const DescriptionWrapper = styled.div`
  flex: 1;
`

const DescriptionTitle = styled(Txt)`
  margin-bottom: 12px;
`

const ReloadButton = styled(TertiaryButton)`
  margin-top: 12px;
`
const NoticeTxt = styled(Txt)`
  margin-top: 30px;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CloseButton = styled(TertiaryButton)`
  width: 166px;
  height: 44px;
`
