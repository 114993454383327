import React from 'react'
import styled, { css } from 'styled-components'
import {
  theme,
  Icon as _Icon,
  TertiaryButton,
  ActiveTertiaryButton,
  Txt,
  Button,
  Timer,
  ClickOutsideListener,
} from '@blue-agency/rogue'
import { Dropdown as _Dropdown } from './Dropdown'
import { InterviewControlsProps } from '.'
import { buttonResetStyle } from '@/styles/buttonResetStyle'
import { RedCircle as _RedCircle } from './RedCircle'
import { ReloadModal } from '@/components/ReloadModal'
import { UnstableConnectionModal } from './UnstableConnectionModal'
import { ChangeDeviceModal } from './ChangeDeviceModal'

export const Pc: React.FCX<InterviewControlsProps> = ({
  className,
  userType,
  timerProps,
  userButtonProps,
  chatButtonProps,
  screenShareButtonProps,
  menuProps,
  changeDeviceProps,
}) => {
  const {
    showDropdown,
    handleAdditionalMenuIconClick,
    dropdownRef,
    handleOutsideClick,
    items,
    unstableConnectionModal,
    changeDeviceModal,
    reloadModal,
  } = menuProps

  const showScreenShareButton =
    typeof navigator.mediaDevices.getDisplayMedia !== 'undefined'

  return (
    <Wrapper className={className}>
      {userType === 'interviewer' && timerProps && <Timer {...timerProps} />}
      <AdditionalMenuIconWrapper>
        <AdditionalMenuIconButton
          onClick={handleAdditionalMenuIconClick}
          comlinkPushParams={{ action: 'show_dropdown_on_interview_controls' }}
        >
          <Icon name="settings" />
        </AdditionalMenuIconButton>
        {showDropdown && (
          <ClickOutsideListener
            targetRef={dropdownRef}
            onClick={handleOutsideClick}
          >
            <Dropdown ref={dropdownRef} items={items} />
          </ClickOutsideListener>
        )}
        <UnstableConnectionModal
          active={unstableConnectionModal.active}
          onClose={unstableConnectionModal.close}
          userType={userType}
        />
        <ReloadModal active={reloadModal.active} onCancel={reloadModal.close} />
        {changeDeviceProps && (
          <ChangeDeviceModal
            active={changeDeviceModal.active}
            onClose={changeDeviceModal.close}
            changeDeviceProps={changeDeviceProps}
          />
        )}
      </AdditionalMenuIconWrapper>
      <ReloadButtonWrapper>
        <ReloadButton
          text="再読込"
          onClick={reloadModal.open}
          icon={<Icon name="reload" />}
          direction="column"
          disabled={userButtonProps.disabled}
          comlinkPushParams={{ action: 'click_reload_button_at_interview' }}
        />
      </ReloadButtonWrapper>
      <Separator />
      <UserButtonWrapper>
        {userButtonProps.active ? (
          <ActiveUserButton
            text="参加者"
            onClick={userButtonProps.onClick}
            icon={<Icon name="presenter" />}
            direction="column"
            disabled={userButtonProps.disabled}
            comlinkPushParams={{ action: 'hide_interview_users_area' }}
          />
        ) : (
          <UserButton
            text="参加者"
            onClick={userButtonProps.onClick}
            icon={<Icon name="presenter" />}
            direction="column"
            disabled={userButtonProps.disabled}
            comlinkPushParams={{ action: 'show_interview_users_area' }}
          />
        )}
        {!!userButtonProps.count && (
          <UserButtonCount>
            <Txt size="xs" bold color={theme.color.white[1]}>
              {userButtonProps.count}
            </Txt>
          </UserButtonCount>
        )}
      </UserButtonWrapper>
      <ChatButtonWrapper>
        {chatButtonProps.active ? (
          <ActiveChatButton
            text="チャット"
            onClick={chatButtonProps.onClick}
            icon={<Icon name="chat" />}
            direction="column"
            disabled={chatButtonProps.disabled}
            comlinkPushParams={{ action: 'hide_interview_chat_area' }}
          />
        ) : (
          <ChatButton
            text="チャット"
            onClick={chatButtonProps.onClick}
            icon={<Icon name="chat" />}
            direction="column"
            disabled={chatButtonProps.disabled}
            comlinkPushParams={{ action: 'show_interview_chat_area' }}
          />
        )}
        {chatButtonProps.unread && <RedCircle />}
      </ChatButtonWrapper>
      {showScreenShareButton && screenShareButtonProps && (
        <>
          {screenShareButtonProps.active ? (
            <ActiveScreenShareButton
              text="画面共有を終了"
              onClick={screenShareButtonProps.onClick}
              icon={<Icon name="screen-share" />}
              direction="column"
              disabled={screenShareButtonProps.disabled}
              comlinkPushParams={{
                action: 'finish_interview_screen_sharing',
              }}
            />
          ) : (
            <ScreenShareButton
              text="画面共有"
              onClick={screenShareButtonProps.onClick}
              icon={<Icon name="screen-share" />}
              direction="column"
              disabled={screenShareButtonProps.disabled}
              comlinkPushParams={{
                action: 'start_interview_screen_sharing',
              }}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const AdditionalMenuIconWrapper = styled.div`
  ${Wrapper} & {
    margin-left: 20px;
  }
  position: relative;
`
const AdditionalMenuIconButton = styled(Button)`
  ${buttonResetStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
`
const Dropdown = styled(_Dropdown)`
  ${AdditionalMenuIconWrapper} & {
    position: absolute;
    top: 38px;
    right: -10px;
  }
`
const iconSize = css`
  height: 22px;
  width: 25px;
`
const Icon = styled(_Icon)`
  ${iconSize}
`

const buttonBase = css`
  ${Wrapper} & {
    margin-left: 20px;
  }
  padding: 4.5px 0 0 !important;
  height: 44px;
  font-size: ${theme.fontSize.xs} !important;
`
const ReloadButtonWrapper = styled.div`
  position: relative;
`
const UserButtonWrapper = styled.div`
  position: relative;
`
const UserButtonCount = styled.div`
  ${UserButtonWrapper} & {
    position: absolute;
    top: -3px;
    right: -8px;
  }
  width: 20px;
  height: 20px;
  background: ${theme.color.navy[1]};
  border: 1px solid ${theme.color.white[1]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ActiveUserButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
  width: 52px;
`
const UserButton = styled(TertiaryButton)`
  ${buttonBase}
  width: 52px;
`
const ChatButtonWrapper = styled.div`
  position: relative;
`
const ChatButton = styled(TertiaryButton)`
  ${buttonBase}
  width: 52px;
`
const ActiveChatButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
  width: 52px;
`
const RedCircle = styled(_RedCircle)`
  ${ChatButtonWrapper} & {
    position: absolute;
    top: -3px;
    right: -4px;
  }
`
const ActiveScreenShareButton = styled(ActiveTertiaryButton)`
  ${buttonBase}
  width: 84px;
`
const ScreenShareButton = styled(TertiaryButton)`
  ${buttonBase}
  width: 84px;
`
const ReloadButton = styled(TertiaryButton)`
  ${buttonBase}
  width: 52px;
`
const Separator = styled.div`
  margin-left: 10px;
  margin-right: -10px;
  width: 24px;
  height: 1px;
  background: ${theme.color.navy[2]};
  transform: rotate(90deg);
`
