import React from 'react'
import {
  ErrorMessage as _ErrorMessage,
  Modal,
  DangerButton as _DangerButton,
  TertiaryButton as _TertiaryButton,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'
import styled from 'styled-components'
import { useStopTimeguideModal } from './useStopTimeguideModal'

type Props = {
  active: boolean
  onClose: () => void
  onOpen: () => void
}

export const StopTimeguideModal: React.FCX<Props> = (props) => {
  const { isSubmitError, onSubmit } = useStopTimeguideModal(props)

  return (
    <Modal
      active={props.active}
      onClose={props.onClose}
      title="タイムガイドの利用を停止"
      size="m"
    >
      <Form onSubmit={onSubmit}>
        <Upper>
          {isSubmitError && (
            <ErrorMessage text="停止できませんでした。もう一度お試しください。" />
          )}
          <Specification>
            ・作成済みの面接に設定されたタイムガイドは解除されません
            <br />
            ・今後作成する面接には、タイムガイドが設定されない状態になります。
          </Specification>
          <Notice>
            ※作成済みの面接に設定されたタイムガイドを解除する場合は、
            <br />
            該当のタイムガイドを削除する必要があります。
          </Notice>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <CancelButton
            onClick={props.onClose}
            comlinkPushParams={{ action: 'cancel_stop_timeguide' }}
          >
            キャンセル
          </CancelButton>
          <SubmitButton
            type="submit"
            comlinkPushParams={{ action: 'stop_timeguide' }}
          >
            停止する
          </SubmitButton>
        </Bottom>
      </Form>
    </Modal>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Upper = styled.div`
  padding: 20px 20px 25px;
  width: 100%;
`

const ErrorMessage = styled(_ErrorMessage).attrs({ size: 's' })`
  margin-top: -20px;
  margin-bottom: 20px;
`

const Specification = styled(Txt).attrs({ size: 's', bold: true })`
  margin-bottom: 16px;
`

const Notice = styled(Txt).attrs({ size: 's' })`
  padding-left: 1em;
  text-indent: -1em;
`

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

const CancelButton = styled(_TertiaryButton).attrs({ size: 'l2' })`
  margin-right: 20px;
`

const SubmitButton = styled(_DangerButton).attrs({ size: 'l2' })``
