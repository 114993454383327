import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Txt, PrimaryButton, HelpLinkIcon } from '@blue-agency/rogue'
import { EconomyModeBox } from './EconomyModeBox'
import { RoomPageContainer } from './RoomPageContainer'
import { Layout } from '@/components/Layout'
import { href } from '@/href'

export const Waiting: React.FCX = ({ className }) => {
  const { setRoomStatus, isEconomyMode } = RoomPageContainer.useContainer()
  const toEntered = useCallback(() => {
    setRoomStatus('entered')
  }, [setRoomStatus])
  return (
    <Layout
      className={className}
      rightNode={
        <HelpLinkIcon
          href={href.interviewee.help.interview}
          action="open_interviewee_interview_help_page"
        />
      }
    >
      <Box>
        <MainTxt>参加準備ができたら入室してください</MainTxt>
        <StartButton
          size="ll"
          text="入室する"
          onClick={toEntered}
          comlinkPushParams={{ action: 'enter_interview_room' }}
        />
        {isEconomyMode && <EconomyModeBoxWrapper />}
      </Box>
    </Layout>
  )
}

const Box = styled.div`
  position: absolute;
  top: 15%;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MainTxt = styled(Txt).attrs({ size: 'xl' })`
  text-align: center;
  margin-top: 20px;
`
const StartButton = styled(PrimaryButton)`
  margin-top: 30px;
`
const EconomyModeBoxWrapper = styled(EconomyModeBox)`
  margin-top: 20px;
`
