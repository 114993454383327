import React from 'react'
import { Icon } from '@blue-agency/rogue'
import { Chat } from '@/components/Chat'

export const NotReady: React.FCX = ({ className }) => {
  return (
    <Chat
      className={className}
      messages={[]}
      title="チャット"
      titleIcon={<Icon name="chat" />}
      connected={false}
      placeholder="準備中です、しばらくお待ちください"
      onSend={() => {}}
    />
  )
}
