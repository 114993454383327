import React, { useCallback } from 'react'
import { Page } from './Page'
import { href } from '@/href'
import { useTitle } from '@/hooks/useTitle'
import { useParams } from 'react-router-dom'

const comlinkHost = process.env.REACT_APP_COMLINK_HOST
if (!comlinkHost) throw new Error('comlinkHost not found')

export const FinishPage: React.VFC = () => {
  const { intervieweeToken } = useParams<{ intervieweeToken?: string }>()
  if (!intervieweeToken) throw new Error('intervieweeToken not found')

  useTitle('終了ページ')

  const openSurveyLink = useCallback(async () => {
    const res = await fetch(`${comlinkHost}/uuid`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    })
    const json = await res.json()
    const params = `${intervieweeToken}+${json.clid}+imapi_interview`
    window.location.href = href.interviewee.survey + params
  }, [intervieweeToken])

  return (
    <Page
      openSurveyLink={openSurveyLink}
      agentRegistrationUrl={
        href.interviewee.agentRegistration + intervieweeToken
      }
    />
  )
}
