import { Comlink } from '@blue-agency/comlink'
import {
  SoraConnectionConfig,
  SoraError,
  Status as StatusType,
  Metadata,
  SignalingNotifyConnectionCreated,
} from '@blue-agency/react-im-interview'
import { useIMInterview as useInterview } from '@/hooks/useIMInterview'
import { useSessionToken } from './useSessionToken'
import { RoomPageContainer } from '../../../RoomPageContainer'
import { enteringUserBeepSrc } from './enteringUserBeepSrc'
import { BeepContainer } from '@/hooks/BeepContainer'
import { useCallback, useMemo } from 'react'
import { useCachedPromise } from '@blue-agency/front-state-management'
import { BffContainer } from '@/hooks/BffContainer'

const vaderWebHost = process.env.REACT_APP_VADER_WEB_HOST
if (!vaderWebHost) throw new Error('vaderWebHost not found')

const logRTCStatsEndpoint = process.env.REACT_APP_LOG_RTC_STATS_ENDPOINT
if (!logRTCStatsEndpoint) throw new Error('logRTCStatsEndpoint not found')

const SCREEN_SHARING_BITRATE = 256
const USE_DATACHANNEL_SIGNALING = true
const IGNORE_DISCONNECT_WEBSOCKET = true

type SignalingResultHandlerArgs = {
  action: string
  interviewerToken: string
  channelId: string
  webrtcHost: string
}
const handleSignalingSuccess =
  ({
    action,
    interviewerToken,
    channelId,
    webrtcHost,
  }: SignalingResultHandlerArgs) =>
  (connectionId: string | null) => {
    Comlink.push({
      type: 'system_activity',
      group: 'snoke',
      action: action,
      targetName: 'interviewerToken',
      targetIdStr: interviewerToken,
      metadata: {
        channelId: channelId,
        connectionId: connectionId ?? '',
        soraHost: webrtcHost,
      },
    })
  }
const handleSignalingError =
  ({
    action,
    interviewerToken,
    channelId,
    webrtcHost,
  }: SignalingResultHandlerArgs) =>
  (e: SoraError) => {
    Comlink.push({
      type: 'system_activity',
      group: 'snoke',
      action: action,
      targetName: 'interviewerToken',
      targetIdStr: interviewerToken,
      metadata: {
        channelId: channelId,
        soraHost: webrtcHost,
        reason: e.reason,
      },
    })
  }

const logScreenShare = (stream: MediaStream) => {
  const capabilities = stream.getVideoTracks()[0].getCapabilities()
  Comlink.push({
    type: 'system_activity',
    group: 'snoke',
    action: 'succeeded_to_share_screen_on_interview',
    metadata: {
      capabilities: JSON.stringify(capabilities) ?? '',
    },
  })
}

export const useIMInterview = () => {
  const { interview, interviewerToken, quality } =
    RoomPageContainer.useContainer()
  const { beep } = BeepContainer.useContainer()
  const bff = BffContainer.useContainer()
  const { sessionToken } = useSessionToken()

  // NOTE: 終了済の場合にquality == nullとなるがここまで来ないはずなので型合わせ
  if (!quality) {
    throw new Error('unexpected quality is null')
  }

  const interviewerSignalingPoints = useCachedPromise(
    bff.cacheKey.getInterviewerSignalingPoints,
    () => bff.getInterviewerSignalingPoints(interviewerToken)
  )
  const mainSignalingPoint = interviewerSignalingPoints.main
  const mainConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      channelId: mainSignalingPoint.channelId,
      webrtcHost: mainSignalingPoint.webrtcHost,
      metadata: {
        session_token: sessionToken.user,
        invitation_token: interviewerToken,
      },
      onSignalingSuccess: handleSignalingSuccess({
        action: 'succeeded_to_connect_to_sora_in_interview',
        interviewerToken,
        channelId: mainSignalingPoint.channelId,
        webrtcHost: mainSignalingPoint.webrtcHost,
      }),
      onSignalingError: handleSignalingError({
        action: 'failed_to_connect_to_sora_in_interview',
        interviewerToken,
        channelId: mainSignalingPoint.channelId,
        webrtcHost: mainSignalingPoint.webrtcHost,
      }),
      videoBitRate: quality.videoBitRate,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    }),
    // NOTE: https://github.com/blue-agency/yashiori-front/pull/1205#discussion_r454015996
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const screenSharingSignalingPoint = interviewerSignalingPoints.screenSharing
  const screenSharingConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      channelId: screenSharingSignalingPoint.channelId,
      webrtcHost: screenSharingSignalingPoint.webrtcHost,
      metadata: {
        session_token: sessionToken.screenSharing,
        invitation_token: interviewerToken,
      },
      videoBitRate: SCREEN_SHARING_BITRATE,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
      onSignalingSuccess: handleSignalingSuccess({
        action: 'succeeded_to_connect_screen_sharing_to_sora_in_interview',
        interviewerToken,
        channelId: screenSharingSignalingPoint.channelId,
        webrtcHost: screenSharingSignalingPoint.webrtcHost,
      }),
      onSignalingError: handleSignalingError({
        action: 'failed_to_connect_screen_sharing_to_sora_in_interview',
        interviewerToken,
        channelId: screenSharingSignalingPoint.channelId,
        webrtcHost: screenSharingSignalingPoint.webrtcHost,
      }),
    }),
    // NOTE: https://github.com/blue-agency/yashiori-front/pull/1205#discussion_r454015996
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const screenSharingRecvConnectionConfig: SoraConnectionConfig = useMemo(
    () => ({
      channelId: screenSharingSignalingPoint.channelId,
      webrtcHost: screenSharingSignalingPoint.webrtcHost,
      metadata: {
        session_token: sessionToken.screenSharingRecv,
        invitation_token: interviewerToken,
      },
      videoBitRate: SCREEN_SHARING_BITRATE,
      dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
      ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
      onSignalingSuccess: handleSignalingSuccess({
        action: 'succeeded_to_connect_screen_sharing_recv_to_sora_in_interview',
        interviewerToken,
        channelId: screenSharingSignalingPoint.channelId,
        webrtcHost: screenSharingSignalingPoint.webrtcHost,
      }),
      onSignalingError: handleSignalingError({
        action: 'failed_to_connect_screen_sharing_recv_to_sora_in_interview',
        interviewerToken,
        channelId: screenSharingSignalingPoint.channelId,
        webrtcHost: screenSharingSignalingPoint.webrtcHost,
      }),
    }),
    // NOTE: https://github.com/blue-agency/yashiori-front/pull/1205#discussion_r454015996
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onConnectionCreated = useCallback(
    (data: SignalingNotifyConnectionCreated, status: StatusType) => {
      const metadata = data.metadata as Metadata
      // 入室解除前は、面接官が入室した時のみ入室音を鳴らす
      if (status !== 'started') {
        if (metadata.interview_role === 'recruiter') {
          beep(enteringUserBeepSrc)
        }
        return
      }
      if (
        metadata.interview_role === 'applicant' ||
        metadata.interview_role === 'recruiter'
      ) {
        beep(enteringUserBeepSrc, 0.8)
      }
    },
    [beep]
  )

  const imInterview = useInterview({
    webSocketUrl: `wss://${vaderWebHost}/channel_status/${interview.videoInterviewGuid}`,
    soraConnectionConfigs: {
      main: mainConnectionConfig,
      screenSharing: screenSharingConnectionConfig,
      screenSharingRecv: screenSharingRecvConnectionConfig,
    },
    userType: 'interviewer',
    videoFrameRate: quality.videoFrameRate,
    logRTCStatsEndpoint,
    onSuccessPublishScreenSharing: logScreenShare,
    onConnectionCreated,
  })

  return imInterview
}
