import React from 'react'
import { Icon } from '@blue-agency/rogue'
import { Chat } from '@/components/Chat'
import {
  useInterviewChatWebSocket,
  InterviewChatWebSocketParams,
} from '@/hooks/useInterviewChatWebSocket'
import { EnteredContainer } from '../../hooks/EnteredContainer'

type Props = InterviewChatWebSocketParams

export const Ready: React.FCX<Props> = ({ className, ...chatParams }) => {
  const interviewChatWs = useInterviewChatWebSocket(chatParams)
  const { showChat, chatMessages } = EnteredContainer.useContainer()
  return (
    <Chat
      className={className}
      messages={chatMessages}
      title="チャット"
      titleIcon={<Icon name="chat" />}
      hidden={!showChat}
      connected={interviewChatWs.connected}
      placeholder={
        interviewChatWs.connected
          ? '入力してください'
          : '準備中です、しばらくお待ちください'
      }
      onSend={interviewChatWs.handleSend}
    />
  )
}
