import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BaseCard } from './BaseCard'
import { SurveyLinkButton } from './SurveyLinkButton'
import { SurveyCardProps } from './types'

export const PcSurveyCard: React.VFC<SurveyCardProps> = ({
  openSurveyLink,
}) => {
  return (
    <Card>
      <Left>
        <RequestTxt size="m" bold color={theme.color.white[1]}>
          サービス品質向上のため、ご意見をお聞かせください。
        </RequestTxt>
        <PresentTxt size="s" color={theme.color.white[1]}>
          抽選で毎月5名様に<Bold>Amazonギフト券1,000円分</Bold>プレゼント中！
        </PresentTxt>
      </Left>
      <Right>
        <SurveyLinkButton width="178px" openSurveyLink={openSurveyLink} />
      </Right>
    </Card>
  )
}

const Card = styled(BaseCard)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
`
const Right = styled.div`
  margin-left: 80px;
`

const RequestTxt = styled(Txt)`
  margin: 0;
`

const PresentTxt = styled(Txt)`
  margin: 0;
`

const Bold = styled.span`
  font-weight: bold;
`
