import { useCameraTest } from '@/hooks/useCameraTest'
import { useCallback } from 'react'
import { RoomPageContainer } from '../../RoomPageContainer'

export const useEnvironmentCheck = () => {
  const { setRoomStatus, sessionStorageKey, interviewerToken } =
    RoomPageContainer.useContainer()

  const cameraTestConnectionProps = useCameraTest(
    'interviewer',
    interviewerToken
  )

  const handleFinish = useCallback(() => {
    sessionStorage.setItem(sessionStorageKey.environmentCheck, 'done')
    setRoomStatus('waiting')
  }, [sessionStorageKey.environmentCheck, setRoomStatus])

  return { handleFinish, cameraTestConnectionProps }
}
